import React, {
  FC,
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { QuizQuestionDto, QuizQuestionTopic } from "../../types";
import { ExplanationModal } from "./components/ExplanationModal";
import { Buttons, ModalButton } from "../ModalComponents";
import { AppContext } from "../AppContext";
import {
  createQuizQuestions,
  getExplanation,
  getQuizQuestions,
  updateQuizQuestion,
} from "../../util/api";
import { useUpdateQuizQuestion } from "../../util/queries";
import { Exercise } from "./components/Exercise";
import { favouriteColor, FAVOURITES_CONTEXT } from "../Controls/consts";
import { QuizAlgorithm, sortQuizQuestions } from "./utils/algorithm";
import {
  getStatusIcon,
  hasAnyGaps,
  isVerified,
  useTopicColor,
} from "./utils/quiz";
import { usePersistedState } from "../../util/react";
import { RainbowLoader } from "../RainbowLoader";
import { QuestionModal } from "./QuestionModal";

type FetchFn = () => Promise<QuizQuestionDto[]>;

interface QuizProps {
  type: QuizQuestionTopic;
  live?: boolean;
  extraIcon?: React.ReactNode;
  showTotalRound?: boolean;
  externalQuestions?: QuizQuestionDto[];
  externalRound?: number;
  onNextQuestion?: () => void;
}

const QuizWrapper = styled.div<{ topicColor: string }>`
  margin: 0 auto;
  max-width: 600px;

  * {
    font-size: 20px;
  }

  .header {
    margin: 0;
    display: flex;
    gap: 20px;
    align-items: center;
  }

  padding: 0px 10px;

  .separate {
    margin: 20px 0;
  }

  .separate-x2 {
    margin: 40px 0;
  }

  .separate-right {
    margin-right: 20px;
  }

  .quiz-table {
    margin: 40px 0;
  }

  .quiz-table td {
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .possible-answer {
    font-size: 0.8em;
    margin-right: 10px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .possible-answers {
    max-width: 90%;
    overflow: auto;
    display: block;
  }

  .possible-answer--selected {
    color: blue;
  }

  .exercise {
    padding-bottom: 5px;
    margin: 10px 5px;
    line-height: 1.8em;
    white-space: pre-wrap;
  }

  .gap {
    width: 100px;
    height: 22px;
    padding: 3px;
  }

  button {
    padding: 10px;
  }

  em {
    font-style: normal;
    color: ${(props) => props.topicColor};
  }
`;

function useQuestions(
  fetchFn: FetchFn,
  topic: QuizQuestionTopic,
  externalQuestions?: QuizQuestionDto[]
) {
  const [questions, setQuestions] = useState<QuizQuestionDto[]>([]);
  const [quizAlgorithm, setQuizAlgorithm] = usePersistedState<QuizAlgorithm>(
    `quiz-algorithm-${topic}`,
    QuizAlgorithm.RANDOM
  );

  useEffect(() => {
    if (externalQuestions) {
      return;
    }

    fetchFn().then((questions) => {
      setQuestions(
        sortQuizQuestions(
          questions.filter(isVerified).filter(hasAnyGaps),
          quizAlgorithm
        )
      );
    });
  }, []);

  if (externalQuestions) {
    return externalQuestions;
  }

  return questions;
}

function useRound(topic: QuizQuestionTopic, externalRound?: number) {
  const { getQuizometer, bumpQuizometer } = useContext(AppContext);

  const round = externalRound || getQuizometer(topic) + 1;
  const nextRound = () => bumpQuizometer(topic);

  const totalRound = getQuizometer("all") + 1;

  return { round, nextRound, totalRound };
}

function useOnAnswer(topic: QuizQuestionTopic) {
  const [update] = useUpdateQuizQuestion(topic);

  const onAnswer = (question: QuizQuestionDto, isCorrect: boolean) => {
    const q = {
      ...question,
    };

    q.lastAsked = Date.now();
    q.askedCount = (q.askedCount || 0) + 1;

    if (isCorrect) {
      q.lastAskedResult = "correct";
      q.mistakesCount = q.mistakesCount || 0;
    } else {
      q.lastAskedResult = "incorrect";
      q.mistakesCount = (q.mistakesCount || 0) + 1;
    }

    update(q);
  };

  return onAnswer;
}

function useMarkAsUnverified(currentQuestion: QuizQuestionDto) {
  const [markedToVerify, setMarkedToVerify] = useState(false);

  const markAsUnverified = async () => {
    await updateQuizQuestion({
      ...currentQuestion,
      text: currentQuestion.text + "*",
    });
    setMarkedToVerify(true);
  };

  useEffect(() => {
    setMarkedToVerify(currentQuestion?.text?.endsWith("*"));
  }, [currentQuestion?.text]);

  return { markedToVerify, markAsUnverified };
}

function useMarkAsFav(question?: QuizQuestionDto) {
  const [isFav, setIsFav] = useState(false);

  useEffect(() => {
    if (question) {
      setIsFav(question.isFavourite);
    }
  }, [question?._id]);

  const toggleFav = async () => {
    if (!question) {
      return;
    }
    await updateQuizQuestion({
      ...question,
      isFavourite: !isFav,
    });
    setIsFav(!isFav);
  };

  return { isFav, toggleFav };
}

function getRoundQuestion(questions: QuizQuestionDto[], round: number) {
  const index = (round - 1) % questions.length;
  return questions[index];
}

export const Quiz: FC<QuizProps> = ({
  type,
  live,
  showTotalRound,
  extraIcon,
  externalQuestions,
  externalRound,
  onNextQuestion,
}) => {
  const [autogenerateOn] = usePersistedState(
    `quiz-autogenerate-${type}`,
    false
  );
  const [includeAutogen] = usePersistedState(
    `quiz-include-autogen-${type}`,
    false
  );

  const fetchFn = useCallback(() => {
    return getQuizQuestions(type).then((questions) => {
      if (!includeAutogen) {
        return questions.filter((q) => !q.setId);
      }
      return questions;
    });
  }, [type, includeAutogen]);

  const generateMoreFn = useCallback(() => {
    if (!autogenerateOn) {
      return;
    }
    return createQuizQuestions(type);
  }, [type, autogenerateOn]);

  const explainFn = useCallback(
    (id: string, regenerate?: boolean) => {
      return getExplanation(type, id, regenerate);
    },
    [type]
  );

  const { round, nextRound, totalRound } = useRound(type, externalRound);
  const questions = useQuestions(fetchFn, type, externalQuestions);
  const currentQuestion = getRoundQuestion(questions, round);
  const onAnswer = useOnAnswer(type);

  const topicColor = useTopicColor(live ? "live" : type);

  const formRef = React.useRef<HTMLFormElement>(null);

  const [submitted, setSubmitted] = useState(false);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!submitted) {
      setSubmitted(true);
    } else {
      onNext();
    }
  };

  const onNext = () => {
    formRef.current?.reset();
    if (round % 5 === 4) {
      generateMoreFn?.();
    }
    setSubmitted(false);
    nextRound();
    onNextQuestion?.();
  };

  const { markedToVerify, markAsUnverified } =
    useMarkAsUnverified(currentQuestion);
  const { isFav, toggleFav } = useMarkAsFav(currentQuestion);

  const [showExplanation, setShowExplanation] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const className = `quiz quiz-${type}`;

  if (!currentQuestion) {
    return (
      <QuizWrapper className={className} topicColor={topicColor}>
        <RainbowLoader />
      </QuizWrapper>
    );
  }

  const statusIcon = getStatusIcon(currentQuestion);

  return (
    <QuizWrapper className={className} topicColor={topicColor}>
      <p className="header">
        {extraIcon}
        {statusIcon && <span>{statusIcon}</span>}
        <span>
          {showTotalRound && <>{totalRound} | </>}
          <em>
            {round} {externalRound && "🪄"}
          </em>
        </span>
      </p>
      <form onSubmit={onSubmit} ref={formRef}>
        <Exercise
          question={currentQuestion}
          submitted={submitted}
          onAnswer={onAnswer}
        />

        <Buttons>
          <ModalButton
            onClick={() => setShowEdit(true)}
            type="button"
            magicColor={topicColor}
            tabIndex={-1}
          >
            🖊
          </ModalButton>
          <ModalButton
            onClick={() => setShowExplanation(true)}
            type="button"
            magicColor={topicColor}
            tabIndex={-1}
          >
            🌡️
          </ModalButton>
          <ModalButton
            onClick={() => markAsUnverified()}
            type="button"
            magicColor={topicColor}
            tabIndex={-1}
          >
            {markedToVerify ? "❓" : "❔"}
          </ModalButton>
          <ModalButton
            onClick={() => toggleFav()}
            type="button"
            magicColor={topicColor}
            tabIndex={-1}
          >
            <span style={{ color: isFav ? favouriteColor : undefined }}>
              {FAVOURITES_CONTEXT}
            </span>
          </ModalButton>
          <ModalButton
            flex={2}
            type="submit"
            className="navig-button"
            magicColor={topicColor}
          >
            {submitted ? `→` : "Check!"}
          </ModalButton>
        </Buttons>
      </form>

      {showExplanation && (
        <ExplanationModal
          closeModal={() => setShowExplanation(false)}
          explainFn={explainFn}
          question={currentQuestion}
        />
      )}
      {showEdit && (
        <QuestionModal
          type={type}
          question={currentQuestion}
          onClose={() => setShowEdit(false)}
        />
      )}
    </QuizWrapper>
  );
};
