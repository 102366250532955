import { FlashcardDto, Side } from "../../types";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { asUntranslated } from "../../util/translate";
import { ProficiencyControl } from "../ProficiencyControl";
import {
  cutDescriptions,
  isComplete,
  needsExtraVerification,
} from "../../util/flashcards";
import { DictLink } from "../DictLink";
import {
  BottomToolbar,
  Description,
  DescriptionCounter,
  EditButton,
  InsightWrapper,
  Mark,
  MoreMenu,
  StyledFlashcard,
  ToolbarElem,
  TopToolbar,
} from "./style";
import {
  usePlayFlashcard,
  useSide,
  useUpdateFavourite,
  useUpdateProficiency,
  useUpdateRing,
} from "./hooks";
import { AppContext } from "../AppContext";
import { CollectionToggleButton } from "../CollectionToggleButton";
import { Algorithm } from "../../util/algorithm";
import { InsightButton } from "../Insight";
import { ReadOut } from "../ReadOut";
import { ColourfulLabel } from "./ColourfulLabel";
import { GrammarSide } from "./GrammarSide";
import { Metadata, metadataVisible } from "./Metadata";
import { getContextName } from "../../util/context";

interface FlashcardProps {
  flashcard: FlashcardDto;
  startSide: Side;
  isHighlighted: boolean;
  isMarked?: boolean;
  menuStatus: "none" | "active" | "inactive";
  onMenu: (flashcard: FlashcardDto) => void;
}

export const Flashcard: FC<FlashcardProps> = (prop) => {
  const { flashcard, startSide, isHighlighted, onMenu, menuStatus, isMarked } =
    prop;
  const { bumpHeat, algorithm } = useContext(AppContext);
  const ref = useRef<HTMLDivElement>(null);

  const [shownProficiency, setShownProficiency] = useState(
    flashcard.proficiency
  );
  const [shownFavourite, setShownFavourite] = useState(
    Boolean(flashcard.isFavourite)
  );
  const [shownRing, setShownRing] = useState(flashcard.ring || 0);

  const [side, flip] = useSide(startSide, flashcard);

  const updateProficiency = useUpdateProficiency(
    flashcard,
    setShownProficiency,
    setShownRing,
    bumpHeat
  );

  const updateFavourite = useUpdateFavourite(flashcard, setShownFavourite);
  const updateRing = useUpdateRing(flashcard, setShownRing);

  const { isPlaying } = usePlayFlashcard(flashcard._id, ref);

  const complete = isComplete(flashcard);

  const contexts = flashcard.context
    .split(",")
    .filter(Boolean)
    .map(getContextName);

  return (
    <StyledFlashcard
      side={side}
      onClick={flip}
      ref={ref}
      isPlaying={isPlaying}
      isHighlighted={isHighlighted}
      menuStatus={menuStatus}
      id={`flashcard-${flashcard._id}`}
      data-side={side}
      toVerify={needsExtraVerification(flashcard) && !complete}
      blurry={isMarked === false}
    >
      <ColourfulLabel flashcard={flashcard} />
      <TopToolbar>
        <MoreMenu
          onClick={(e) => {
            e.stopPropagation();
            onMenu(flashcard);
          }}
        >
          🪄
        </MoreMenu>

        <CollectionToggleButton flashcardId={flashcard._id} absolute />
        {contexts.map((context) => (
          <ToolbarElem key={context} text>
            {context}
          </ToolbarElem>
        ))}
        <EditButton
          to={`/edit/${flashcard._id}`}
          onClick={(e) => e.stopPropagation()}
        >
          ✐
        </EditButton>
      </TopToolbar>

      {flashcard.cache && <Mark>🔒</Mark>}

      <Content flashcard={flashcard} side={side} />

      {metadataVisible() && <Metadata flashcard={flashcard} />}

      <BottomToolbar>
        <DictLink engText={flashcard.engText}>
          <ToolbarElem top={-2}>📖</ToolbarElem>
        </DictLink>
        <InsightWrapper>
          <InsightButton flashcard={flashcard} />
        </InsightWrapper>
        <ProficiencyControl
          proficiency={shownProficiency}
          isFavourite={shownFavourite}
          ring={shownRing}
          onChangeProficiency={updateProficiency}
          onChangeFavourite={updateFavourite}
          onRing={updateRing}
          complete={complete}
          flaws={algorithm === Algorithm.HARDEST ? flashcard.flaws : null}
        />
      </BottomToolbar>
    </StyledFlashcard>
  );
};

const Content = (props: { flashcard: FlashcardDto; side: Side }) => {
  const { flashcard, side } = props;
  const [activeDesc, setActiveDesc] = useState(0);
  const descriptions = cutDescriptions(flashcard);
  const changeDesc = () => {
    setActiveDesc((active) => {
      const prev = active - 1;
      if (prev < 0) {
        return descriptions.length - 1;
      }
      return prev;
    });
  };
  useEffect(() => {
    setActiveDesc(descriptions.length - 1);
  }, [descriptions.length]);

  if (side === "eng") {
    return (
      <>
        <p className="engText">
          {flashcard.engText}{" "}
          <ReadOut text={flashcard.engText} em={0.6} gap={5} />
        </p>
        <Description size="small">
          {descriptions[activeDesc]}
          {descriptions.length > 1 && (
            <DescriptionCounter
              onClick={(e) => {
                e.stopPropagation();
                changeDesc();
              }}
            >
              {activeDesc + 1}/{descriptions.length}
            </DescriptionCounter>
          )}
        </Description>
      </>
    );
  }

  if (side === "pol") {
    return <p>{asUntranslated(flashcard.polText)}</p>;
  }

  if (side === "dual") {
    return (
      <>
        <p style={{ fontSize: "0.85em" }}>
          {flashcard.engText}
          <ReadOut text={flashcard.engText} em={0.6} gap={5} />
        </p>
        <p>{asUntranslated(flashcard.polText)}</p>
      </>
    );
  }

  if (side === "description") {
    return <Description size="big">{flashcard.description}</Description>;
  }

  if (side === "grammar") {
    return <GrammarSide flashcard={flashcard} />;
  }

  return null;
};
