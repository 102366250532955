import { FC } from "react";
import { storeTransientFlashcardData } from "../util/flashcards";
import { Link } from "react-router-dom";

interface AddNewLinkProps {
  engText: string;
  context: string;
  className?: string;
  children?: React.ReactNode;
}

export const AddNewLink: FC<AddNewLinkProps> = (props) => {
  return (
    <Link
      className={props.className}
      to={`/add`}
      onClick={() => {
        storeTransientFlashcardData({
          engText: props.engText,
          context: props.context,
        });
      }}
    >
      {props.children || props.engText}
    </Link>
  );
};
