import { Completion, FlashcardDto, Side } from "../types";
import { isTranslated } from "./translate";
import { useContext, useEffect, useState } from "react";
import { usePersistedState } from "./react";
import { AppContext } from "../components/AppContext";
import { Algorithm, calcScore } from "./algorithm";
import {
  COLLECTION_CONTEXT,
  FAVOURITES_CONTEXT,
  GRAMMAR_CONTEXT,
} from "../components/Controls/consts";
import { Source } from "./source";
import { priorityContexts } from "./context";

export const isComplete = (flashcard: FlashcardDto) =>
  Boolean(
    flashcard.polText &&
      !isTranslated(flashcard.polText) &&
      flashcard.description
  );

export function filterFlashcards(
  flashcards: FlashcardDto[] = [],
  completion: Completion,
  searchEverywhere: boolean,
  ringFavourite: boolean,
  contexts: string[],
  search: string | null,
  // algorithm: Algorithm,
  currentCollectionItems: string[]
) {
  let fls = [...flashcards];

  if (searchEverywhere && search) {
    return searchFlashcards(fls, search);
  }

  // fls = algorithmFilter(fls, algorithm);

  contexts.forEach((context) => {
    switch (context) {
      case FAVOURITES_CONTEXT:
        if (!ringFavourite) {
          fls = fls.filter((flashcard) => {
            return flashcard.isFavourite;
          });
        } else {
          fls = fls.filter((flashcard) => {
            return flashcard.ring;
          });
        }
        break;
      case COLLECTION_CONTEXT:
        fls = fls.filter((flashcard) => {
          return currentCollectionItems.includes(flashcard._id);
        });
        fls = currentCollectionItems
          .map((id) => {
            return fls.find((fl) => fl._id === id)!;
          })
          .filter(Boolean);
        break;
      default:
        fls = fls.filter((flashcard) => {
          return flashcard.context
            .split(",")
            .some((ctxt) => contexts.includes(ctxt));
        });
        break;
    }
  });

  fls = fls.filter((flashcard) => {
    return isComplete(flashcard) === (completion !== "incomplete");
  });

  if (!searchEverywhere && search) {
    fls = searchFlashcards(fls, search);
  }

  fls.reverse();

  return fls;
}

export const searchFlashcards = (fls: FlashcardDto[], search: string) => {
  return fls.filter((fl) => {
    return (
      fl.engText.toLowerCase().includes(search.toLowerCase()) ||
      fl.polText.toLowerCase().includes(search.toLowerCase())
    );
  });
};

export const findFlashcardDuplicates = (
  fls: FlashcardDto[],
  engText: string
) => {
  return fls.filter((fl) => {
    return fl.engText.toLowerCase() === engText.toLowerCase();
  });
};

type ShuffleMap = Record<string, number> | null;

export function useShuffle(flashcards: FlashcardDto[], algorithm: Algorithm) {
  const [shuffleMap, setShuffleMap] = useState<ShuffleMap>(null);

  const shuffle = () => {
    setShuffleMap(createShuffleMap(flashcards, algorithm));
  };

  const resetShuffle = () => {
    setShuffleMap(null);
  };

  sortWithShuffleMap(flashcards, shuffleMap);

  const isShuffled = Boolean(shuffleMap);

  const getRating = (id: string) => (shuffleMap || {})[id];

  const isMarked = (id: string) => {
    if (!isShuffled) {
      return undefined;
    }
    switch (algorithm) {
      case Algorithm.PRISTINE:
      case Algorithm.LAST_FLAW:
      case Algorithm.RING:
      case Algorithm.RING_RANDOM:
      case Algorithm.RING_SPEAK:
      case Algorithm.RING_SINGLEDESC:
        return getRating(id) !== Infinity;
      default:
        return undefined;
    }
  };

  const markedNumber = flashcards.filter((fl) => isMarked(fl._id)).length;

  return {
    isShuffled,
    shuffle,
    resetShuffle,
    isMarked,
    markedNumber,
  };
}

function createShuffleMap(flashcards: FlashcardDto[], algorithm: Algorithm) {
  const map: ShuffleMap = {};
  flashcards.forEach((fl) => {
    map[fl._id] = calcScore(fl, algorithm);
  });
  return map;
}

function sortWithShuffleMap(
  flashcards: FlashcardDto[],
  shuffleMap: ShuffleMap
) {
  if (!shuffleMap) {
    return;
  }
  flashcards.sort((a, b) => {
    return shuffleMap[a._id] - shuffleMap[b._id];
  });
}

export function sortForPolishing(
  flashcards: FlashcardDto[],
  isMobile: boolean
) {
  // sort flashcards: first all mobile, then by createTime
  flashcards
    .sort((a, b) => {
      return a.createTime - b.createTime;
    })
    .sort((a, b) => {
      const desktopOrder =
        +needsExtraVerification(b) - +needsExtraVerification(a);
      return isMobile ? -desktopOrder : desktopOrder;
    })
    .sort((a, b) => {
      // sort created in last 30 minutes
      const now = Date.now();
      const aTime = now - a.createTime;
      const bTime = now - b.createTime;
      const MINUTES_TO_REMAIN = 5;
      if (
        aTime < MINUTES_TO_REMAIN * 60 * 1000 &&
        bTime < MINUTES_TO_REMAIN * 60 * 1000
      ) {
        return 0;
      } else if (aTime < MINUTES_TO_REMAIN * 60 * 1000) {
        return -1;
      }
      return 0;
    });
}

export const useLastCreatedFlashcards = (lastCreated?: FlashcardDto) => {
  const { flashcards } = useContext(AppContext);
  const [lastIds, setLastIds] = usePersistedState<string[]>(
    "last-created-ids",
    [],
    sessionStorage
  );

  const lastId = lastCreated?._id;

  useEffect(() => {
    if (!lastId) {
      return;
    }

    const alreadyIncluded = lastIds.includes(lastId);
    if (lastCreated && !alreadyIncluded) {
      setLastIds([lastId, ...lastIds]);
    }
  }, [lastId]);

  return flashcards.filter((fl) => lastIds.includes(fl._id)).reverse();
};

export const getContexts = (flashcards: FlashcardDto[]) => {
  const contexts = new Set<string>();
  flashcards
    .flatMap((fl) => fl.context.split(","))
    .filter(Boolean)
    .forEach((context) => {
      contexts.add(context);
    });
  return Array.from(contexts)
    .sort()
    .sort((a, b) => {
      const aIndex = priorityContexts.indexOf(a);
      const bIndex = priorityContexts.indexOf(b);
      if (aIndex === -1 && bIndex === -1) {
        return 0;
      } else if (aIndex === -1) {
        return 1;
      } else if (bIndex === -1) {
        return -1;
      }
      return aIndex - bIndex;
    });
};

interface TransientFlashcardData {
  engText: string;
  polText?: string;
  context: string;
}

const TRANSIENT_KEY = "transient-flashcard-data";

export const storeTransientFlashcardData = (data: TransientFlashcardData) => {
  sessionStorage.setItem(TRANSIENT_KEY, JSON.stringify(data));
};

export const pullTransientFlashcardData = () => {
  const data = JSON.parse(sessionStorage.getItem(TRANSIENT_KEY) || "null");
  sessionStorage.removeItem(TRANSIENT_KEY);
  return data;
};

export const needsExtraVerification = (flashcard: FlashcardDto) => {
  return (
    flashcard.source === Source.Mobile ||
    flashcard.context.includes("DISCO") ||
    flashcard.context.includes(GRAMMAR_CONTEXT)
  );
};

export const getFlashcardElement = (flashcardId: string | null) => {
  if (!flashcardId) {
    return null;
  }
  return document.getElementById(`flashcard-${flashcardId}`);
};

export const getFlashcardSide = (flashcardId: string | null) => {
  return getFlashcardElement(flashcardId)?.getAttribute(
    "data-side"
  ) as Side | null;
};

export const cutDescriptions = (flashcard: FlashcardDto) => {
  return flashcard.description.split("||").map((d) => d.trim());
};
