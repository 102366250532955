export const wait = (ms: number) =>
  new Promise<void>((resolve) => setTimeout(resolve, ms));

export const waitSeconds = (seconds: number) => {
  console.info("Waiting", seconds, "seconds");
  return wait(seconds * 1000);
};

export const waitUntil = (condition: () => boolean, timeout: number) => {
  return new Promise<boolean>((resolve, reject) => {
    const interval = setInterval(() => {
      if (condition()) {
        clearInterval(interval);
        resolve(true);
      }
    }, 100);
    setTimeout(() => {
      clearInterval(interval);
      reject(false);
    }, timeout);
  });
};
export const waitUntilValue = <T>(getValue: () => T, timeout: number) => {
  const hasValue = () => Boolean(getValue());
  return waitUntil(hasValue, timeout).then(() => getValue());
};
