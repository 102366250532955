export function createLastSeen<T extends string>(name: string) {
  const storageKey = `last-seen-${name}`;
  const save = (view: T) => {
    localStorage.setItem(storageKey, view);
  };
  const get = () => {
    return localStorage.getItem(storageKey) as T | null;
  };
  return {
    save,
    get,
  };
}
