import { useEffect } from "react";

const setTitle = (title: string) => {
  document.title = title || "Fiszke";
};

const setFlashcardTitle = (numberOfFlashcards: number) => {
  const title =
    numberOfFlashcards > 0 ? `(${numberOfFlashcards}) Fiszke` : "Fiszke";
  setTitle(title);
};

export const useFlashcardsTitle = (numberOfFlashcards: number) => {
  useEffect(() => {
    setFlashcardTitle(numberOfFlashcards);
    return () => setFlashcardTitle(0);
  }, [numberOfFlashcards]);
};

export const useTitle = (title: string) => {
  useEffect(() => {
    setTitle(title);
    return () => setTitle("");
  }, [title]);
};
