import { FC, useEffect, useState } from "react";

interface CounterProps {
  topicIds: string[];
}

const formatTime = (seconds: number): string => {
  if (seconds < 60) {
    return `${seconds}s`;
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  if (minutes < 60) {
    return `${minutes}:${
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    } m`;
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return `${hours * 60 + remainingMinutes}:${
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
  } m`;
};

export const Counter: FC<CounterProps> = ({ topicIds }) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    // Reset the counter when topicIds change
    setCounter(0);
  }, [topicIds]);

  useEffect(() => {
    // Start counting
    const counterInterval = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 1000);

    return () => {
      clearInterval(counterInterval);
    };
  }, []);

  return <div>{formatTime(counter)}</div>;
};
