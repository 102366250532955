import { Lang, speak, SpeakConfig, stopSpeech } from "../util/speech";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { scaleOnHover } from "./Style";

export const ReadOutIcon = styled.span<{
  isPlaying?: boolean;
  inline?: boolean;
  gap?: number;
}>`
  ${scaleOnHover()};

  cursor: pointer;
  opacity: ${(props) => (props.isPlaying ? 1 : 0.6)};

  ${(props) =>
    props.inline &&
    css`
      display: inline-block;
      margin-left: ${props.gap || 10}px;
      vertical-align: middle;
      padding: 3px 10px;
    `}
`;

export const READ_ICON = "🔈";

interface ReadOutProps {
  text: string | undefined;
  lang?: Lang;
  em?: number;
  gap?: number;
  speakConfig?: SpeakConfig;
}

export const ReadOut = ({
  text,
  lang = "en-GB",
  em = 0.8,
  gap = 5,
  speakConfig,
}: ReadOutProps) => {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (isPlaying) {
      stopSpeech();
    }
  }, [text]);

  if (!text) {
    return null;
  }

  const onClick = async () => {
    if (isPlaying) {
      stopSpeech();
      setIsPlaying(false);
      return;
    }
    setIsPlaying(true);
    try {
      await speak(text, lang, speakConfig);
    } finally {
      setIsPlaying(false);
    }
  };

  return (
    <ReadOutIcon
      className="read-out-button"
      isPlaying={isPlaying}
      inline
      gap={gap}
      onClick={async (e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      {READ_ICON}
    </ReadOutIcon>
  );
};
