import { FlashcardDto, Side, StoryDto } from "../types";
import { useContext } from "react";
import { AppContext } from "../components/AppContext";
import { wait, waitSeconds } from "./wait";
import { speak, stopSpeech } from "./speech";
import { getPersistedValue } from "./react";
import { isGrammar } from "./grm";
import { getFlashcardSide } from "./flashcards";
import { FlashcardSimpleDto } from "../components/Flashcard/MoreMenu";

export type AudioPlayerMode = "discover" | "repeat";

const audioPlayer = {
  canPlay: false,
};

export const useAudioPlayer = () => {
  const { setCurrentlyPlayingId, currentlyPlayingId } = useContext(AppContext);

  const stop = () => {
    setCurrentlyPlayingId(null);
    audioPlayer.canPlay = false;
    stopSpeech();
  };

  const playOne = async (flashcard: FlashcardSimpleDto, waitAfter?: number) => {
    if (isIdPlaying(flashcard._id)) {
      stop();
      return;
    }

    if (isSomethingPlaying()) {
      stop();
    }

    const side = getFlashcardSide(flashcard._id);
    if (side) {
      setCurrentlyPlayingId(flashcard._id);
      audioPlayer.canPlay = true;
      await playFlashcard(flashcard, side, waitAfter);
    }
    setCurrentlyPlayingId(null);
  };

  const playMany = async (flashcards: FlashcardDto[]) => {
    if (isSomethingPlaying()) {
      stop();
      return;
    }

    const fls = [...flashcards];
    audioPlayer.canPlay = true;
    while (fls.length > 0 && audioPlayer.canPlay) {
      const fl = fls.shift();
      if (fl) {
        await playOne(fl, 1500);
      }
    }
  };

  const isSomethingPlaying = () => {
    return currentlyPlayingId !== null;
  };

  const isIdPlaying = (id: string) => {
    return currentlyPlayingId === id;
  };

  return {
    playOne,
    playMany,
    isSomethingPlaying,
    isIdPlaying,
    stop,
  };
};

const getAudioPlayerMode = () => {
  return (JSON.parse(getPersistedValue("audio-player-mode") || "null") ||
    "discover") as AudioPlayerMode;
};

const calcWaitSeconds = (
  readText: string,
  nextText: string,
  lang: "eng" | "pol"
) => {
  const audioPlayerMode = getAudioPlayerMode();

  if (audioPlayerMode === "discover") {
    return 1.5;
  } else {
    const langFactor = lang === "eng" ? 1 : 1.2;
    return 2 + Math.min(estimateReadingTime(nextText.length, 3)) * langFactor;
  }
};

function estimateReadingTime(
  charCount: number,
  avgWordLength: number = 5,
  readingSpeed: number = 200
): number {
  let words = charCount / avgWordLength;
  let timeMinutes = words / readingSpeed;
  // Convert minutes to seconds for a more precise estimate for short texts
  let timeSeconds = timeMinutes * 60;
  return timeSeconds;
}

async function playFlashcard(
  flashcard: FlashcardSimpleDto,
  startSide: Side,
  waitBeforeNext?: number
) {
  const { engText, polText, description } = flashcard;

  if (isGrammar(flashcard)) {
    return;
  }

  const process = (
    startSide === "eng"
      ? async function* () {
          // English Side
          yield speak(engText, "en-GB");

          yield waitSeconds(calcWaitSeconds(engText, polText, "eng"));

          if (polText) {
            yield speak(polText, "pl-PL");
          }

          yield wait(1200);

          yield speak(description, "en-GB");

          if (waitBeforeNext) {
            yield wait(waitBeforeNext);
          }
        }
      : async function* () {
          // Polish Side
          if (polText) {
            yield speak(polText, "pl-PL");
            yield waitSeconds(calcWaitSeconds(polText, engText, "pol"));
          }

          yield speak(engText, "en-GB");
          yield wait(1200);
          yield speak(description, "en-GB");

          if (waitBeforeNext) {
            yield wait(waitBeforeNext);
          }
        }
  )();

  while (audioPlayer.canPlay) {
    const action = await process.next();
    if (action.done) {
      break;
    }
    await action.value;
  }
}

export const playStory = async (story: StoryDto) => {
  const readableText = story.text.replace(/\*/g, "");
  await speak(readableText, "en-GB");
};
