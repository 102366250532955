import React, { FC, useContext } from "react";
import { Input, InputProps } from "./Input";
import styled, { css } from "styled-components";
import { AppContext } from "./AppContext";
import { getContextName, hiddenContexts } from "../util/context";

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  margin-bottom: 10px;
`;

const ContextButton = styled.button<{ selected: boolean }>`
  color: gray;
  ${(props) =>
    props.selected &&
    css`
      font-weight: bold;
      color: blue;
    `}
  min-height: 35px;
  cursor: pointer;
  background: none;
  border: 1px solid rgba(128, 128, 128, 0.19);
`;

interface ContextInputProps extends InputProps {
  value: string;

  setContext(ctx: string): void;
}

function toggleContext(value: string, ctxt: string) {
  const contexts = value.split(",");

  if (contexts.includes(ctxt)) {
    return contexts.filter((c) => c !== ctxt).join(",");
  }

  return [...contexts, ctxt].join(",");
}

export const ContextInput: FC<ContextInputProps> = (props) => {
  const { value, setContext, children, ...inputProps } = props;
  const { allContexts } = useContext(AppContext);

  const contexts = value.split(",");

  return (
    <Input {...inputProps} className="context-input">
      <>
        <Grid>
          {allContexts
            .filter((c) => !hiddenContexts.includes(c))
            .map((ctxt) => {
              const isSelected = contexts.includes(ctxt);

              return (
                <ContextButton
                  type="button"
                  onContextMenu={(e) => {
                    e.preventDefault();
                    setContext(toggleContext(value, ctxt));
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setContext(isSelected ? "" : ctxt);
                  }}
                  selected={isSelected}
                  key={ctxt}
                >
                  {getContextName(ctxt)}
                </ContextButton>
              );
            })}
        </Grid>
        {children}
      </>
    </Input>
  );
};
