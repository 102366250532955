import styled from "styled-components";
import { FlashcardDto } from "../../types";
import { isGrammar } from "../../util/grm";

const StyledLabel = styled.div<{ colour?: string }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 7px;
  ${(props) => props.colour && `background-color: ${props.colour};`}
`;

function hashIdToColor(id: string): string {
  // Compute a simple hash of the ID.
  let hash = 0;
  for (let i = 0; i < id.length; i++) {
    hash = (hash << 5) - hash + id.charCodeAt(i);
    hash = hash & hash; // Convert to 32bit integer
  }

  // Convert the hash to a 6-character hexadecimal string.
  // This ensures we get a valid color regardless of the ID's content.
  const color = `#${(hash & 0xffffff).toString(16).padStart(6, "0")}`;

  return color;
}

export const ColourfulLabel = ({ flashcard }: { flashcard: FlashcardDto }) => {
  const colour = isGrammar(flashcard)
    ? hashIdToColor(flashcard._id)
    : undefined;

  return <StyledLabel colour={colour} />;
};
