import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "./Modal";
import { useInquireAboutInsight, useInsightQuery } from "../util/queries";
import { RainbowLoader } from "./RainbowLoader";
import { parseText } from "../util/text";
import { Buttons, ModalButton, ModalFlashcardHeading } from "./ModalComponents";
import { FlashcardDto, InsightDto } from "../types";
import { InquiryForm } from "./InquiryForm";
import { useInsightEvent } from "../util/react";

export const StyledInsightButton = styled.button<{ ready: boolean }>`
  all: unset;
  cursor: pointer;
  ${(props) => (props.ready ? "" : "opacity: 0.3;")}
`;

export const Loader = styled.div`
  text-align: center;
  margin: 20px 0;
  font-size: 1.2em;
`;

export const InsightContent = styled.div`
  margin: 20px 0;
  padding: 10px 20px;
  font-size: 1.2em;
`;

const InsightMeta = styled.div`
  font-size: 0.6em;
  margin-bottom: 5px;
  color: #666;
  text-align: right;
`;

interface InsightProps {
  flashcard: FlashcardDto;
}

const inquirySuggestions = [
  "-How would you translate the given phrase into Polish?",
  "-Is it more British or American English?",
];

export const InsightButton: FC<InsightProps> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  const hasInsight = useInsightEvent(props.flashcard);

  return (
    <>
      <StyledInsightButton
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
        }}
        ready={hasInsight}
      >
        💡
      </StyledInsightButton>
      {showModal && <InsightModal {...props} closeModal={closeModal} />}
    </>
  );
};

interface InsightModalProps extends InsightProps {
  closeModal: () => void;
}

const InsightModal: FC<InsightModalProps> = (props) => {
  const [regenerate, setRegenerate] = useState(false);
  const [insight, insightLoading, load] = useInsightQuery(
    props.flashcard._id,
    regenerate
  );
  const [inquire, inquireLoading] = useInquireAboutInsight(props.flashcard._id);

  const anythingLoading = insightLoading || inquireLoading;

  useEffect(() => {
    (window as any).insightOpen = true;
    return () => {
      (window as any).insightOpen = false;
    };
  }, []);

  useEffect(() => {
    load();

    if (regenerate) {
      setRegenerate(false);
    }
  }, [regenerate]);

  const loader = (label: string) => (
    <Loader>
      <p>{label}...</p>
      {props.flashcard.description && (
        <>
          <p>In the meantime, grab flashcard's description:</p>
          <p>
            <b>{props.flashcard.description}</b>
          </p>
        </>
      )}
      <RainbowLoader />
    </Loader>
  );

  return (
    <Modal level="4" width={800} onClickOutside={props.closeModal}>
      <ModalFlashcardHeading flashcard={props.flashcard} />
      {insightLoading && loader("Generating the insight")}
      {insight && (
        <InsightContent>
          <Meta insight={insight} />

          <div dangerouslySetInnerHTML={{ __html: parseText(insight.text) }} />
        </InsightContent>
      )}
      {!anythingLoading && (
        <InquiryForm
          onInquire={inquire}
          inquirySuggestions={inquirySuggestions.filter(
            (sug) => !insight?.text.includes(sug)
          )}
        />
      )}
      {inquireLoading && loader("Inquiring")}
      <Buttons>
        {insight && (
          <ModalButton type="button" onClick={() => setRegenerate(true)}>
            Regenerate
          </ModalButton>
        )}
        <ModalButton type="button" onClick={props.closeModal}>
          Close
        </ModalButton>
      </Buttons>
    </Modal>
  );
};

const formatTime = (time?: number) => {
  if (!time) return "";
  const date = new Date(time);
  return date.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

const Meta: FC<{ insight: InsightDto }> = ({ insight }) => {
  if (!insight.promptVersion) return null;

  const createTime = formatTime(insight.createTime);
  const updateTime = formatTime(insight.updateTime);

  return (
    <InsightMeta>
      {createTime && `${createTime}`}
      {updateTime && ` (updated ${updateTime})`}, v{insight.promptVersion}
    </InsightMeta>
  );
};
