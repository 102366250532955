import { GRAMMAR_CONTEXT } from "../components/Controls/consts";
import { FlashcardSimpleDto } from "../components/Flashcard/MoreMenu";

export const isGrammar = (fl: FlashcardSimpleDto) => {
  return fl.context.includes(GRAMMAR_CONTEXT);
};

export function emphasizeWithStrong(input: string): string {
  // Use regular expression to match the pattern and replace it
  return input.replace(/_([^_]+)_/g, "<strong>$1</strong>");
}
