import React, { FC, useContext, useEffect, useState } from "react";
import { useQuizPage, useTopicColors } from "../utils/quiz";
import { Quiz } from "../Quiz";
import styled, { css } from "styled-components";
import { QuizQuestionTopic, quizTopics } from "../../../types";
import { AppContext } from "../../AppContext";
import { usePersistedState } from "../../../util/react";
import { Modal } from "../../Modal";
import { useForm } from "react-hook-form";
import { Buttons, ModalButton } from "../../ModalComponents";

const QuizMixWrapper = styled.div<{ currentType: QuizQuestionTopic }>`
  .quiz {
    display: none;
  }

  .quiz.quiz-${(props) => props.currentType} {
    display: block;
  }
`;

const QuizBall = styled.div<{ current: boolean; color: string }>`
  display: inline-block;
  width: 10px;
  height: 10px;
  ${(props) =>
    props.current &&
    css`
      width: 15px;
      height: 15px;
    `}
  border-radius: 50%;
  background: ${(props) => props.color};

  margin: 0 1px;
`;

const Gear = styled.button`
  all: unset;
  cursor: pointer;
  font-size: 1.2em;
`;

const ModalContent = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    display: flex;
    gap: 10px;

    span {
      width: 100px;
    }
  }
`;

const getNextQuiz = () => {};

const getRoundQuiz = (quizometer: number, quizMixProportions: number[]) => {
  const [prepositions, gerunds, articles, varia] = quizMixProportions;
  const value = (quizometer % (prepositions + gerunds + articles + varia)) + 1;

  if (value <= prepositions) {
    return "prepositions";
  }
  if (value <= prepositions + gerunds) {
    return "gerunds";
  }
  if (value <= prepositions + gerunds + articles) {
    return "articles";
  }

  return "varia";
};

export default function QuizMix() {
  useQuizPage("mix");

  const { getQuizometer } = useContext(AppContext);

  const quizometer = getQuizometer("all");
  const [quizMixProportions, setQuizMixProportions] = usePersistedState(
    "quiz-mix-proportions",
    [2, 2, 1, 1]
  );

  const [configShown, setConfigShown] = useState(false);
  const showConfig = () => setConfigShown(true);
  const closeConfig = () => setConfigShown(false);

  const quizes = [0, 1, 2, 3, 4].map((i) =>
    getRoundQuiz(quizometer + i, quizMixProportions)
  );
  const currentQuiz = quizes[0];

  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      document.querySelector(`.quiz-${currentQuiz} input`)?.focus();
    }, 1);
  }, [currentQuiz]);

  const getTopicColor = useTopicColors();

  return (
    <>
      <QuizMixWrapper currentType={currentQuiz}>
        {quizTopics.map((topic) => (
          <Quiz
            type={topic}
            key={topic}
            showTotalRound
            extraIcon={
              <>
                {quizes.map((quiz, i) => (
                  <QuizBall
                    current={i === 0}
                    color={getTopicColor(quiz)}
                    key={i}
                  />
                ))}
                <Gear onClick={showConfig}>⚙</Gear>
              </>
            }
          />
        ))}
      </QuizMixWrapper>
      {configShown && (
        <QuizMixConfig
          onClose={closeConfig}
          proportions={quizMixProportions}
          onChangeProportions={(newProportions) => {
            setQuizMixProportions(newProportions);
            closeConfig();
          }}
        />
      )}
    </>
  );
}

interface QuizMixConfigProps {
  onClose: () => void;
  proportions: number[];
  onChangeProportions: (newProportions: number[]) => void;
}

export const QuizMixConfig: FC<QuizMixConfigProps> = ({
  onClose,
  proportions,
  onChangeProportions,
}) => {
  const [prepositions, gerunds, articles, varia] = proportions;

  const { handleSubmit, register } = useForm({
    defaultValues: {
      prepositions,
      gerunds,
      articles,
      varia,
    },
  });

  const onSubmit = handleSubmit((data, e) => {
    e?.preventDefault();
    onChangeProportions(
      [data.prepositions, data.gerunds, data.articles, data.varia].map(Number)
    );
  });

  return (
    <Modal onClickOutside={onClose} width={400}>
      <form>
        <ModalContent>
          <label>
            <span>prepositions</span>
            <input type="number" {...register("prepositions")} />
          </label>
          <label>
            <span>gerunds</span>
            <input type="number" {...register("gerunds")} />
          </label>
          <label>
            <span>articles</span>
            <input type="number" {...register("articles")} />
          </label>
          <label>
            <span>varia</span>
            <input type="number" {...register("varia")} />
          </label>
          <Buttons>
            <ModalButton type="button" onClick={onSubmit}>
              Save
            </ModalButton>
          </Buttons>
        </ModalContent>
      </form>
    </Modal>
  );
};
