import styled from "styled-components";
import { FC } from "react";
import { Toggle } from "./Toggle";
import { usePersistedState } from "../util/react";
import { ControlsState } from "./Controls/hooks";

const ExtraControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const Btn = styled.button`
  height: 40px;
`;

const polThresholdOptions = ["-5", "1", "2", "4", "6", "10", "100"].map(
  (a) => ({
    value: a,
    label: a,
  })
);

interface ExtraControlsProps {
  controlsState: ControlsState;
  onClose: () => void;
}

export const ExtraControls: FC<ExtraControlsProps> = (props) => {
  const {
    onClose,
    controlsState: {
      searchEverywhereState,
      ringFavouriteState,
      asyncLoaderState,
      speakThresholdState,
      voiceControlState,
      writingAssistantState,
      autoDictState,
    },
  } = props;
  const [searchEverywhere, setSearchEverywhere] = searchEverywhereState;
  const toggleSearchEverywhere = () => setSearchEverywhere(!searchEverywhere);

  const [ringFavourite, setRingFavourite] = ringFavouriteState;
  const toggleRingFavourite = () => setRingFavourite(!ringFavourite);

  const [asyncLoader, setAsyncLoader] = asyncLoaderState;
  const toggleAsyncLoader = () => setAsyncLoader(!asyncLoader);

  const [speakThreshold, setSpeakThreshold] = speakThresholdState;
  const toggleSpeakThreshold = () => setSpeakThreshold(!speakThreshold);

  const [voiceControl, setVoiceControl] = voiceControlState;
  const toggleVoiceControl = () => setVoiceControl(!voiceControl);

  const [writingAssistant, setWritingAssistant] = writingAssistantState;
  const toggleWritingAssistant = () => setWritingAssistant(!writingAssistant);

  const [autoDict, setAutoDict] = autoDictState;
  const toggleAutoDict = () => setAutoDict(!autoDict);

  const [algorithmPicker, setAlgorithmPicker] = usePersistedState(
    "algorithm-picker",
    true,
    sessionStorage
  );

  return (
    <ExtraControlsContainer>
      <Toggle
        value={searchEverywhere}
        toggle={toggleSearchEverywhere}
        label="Szukaj, ignorując inne filtry"
      />

      <Toggle
        value={ringFavourite}
        toggle={toggleRingFavourite}
        label="Nowy system ulubionych"
      />

      <Toggle
        value={asyncLoader}
        toggle={toggleAsyncLoader}
        label="Ładuj fiszki miarowo"
      />

      <Toggle
        value={speakThreshold}
        toggle={toggleSpeakThreshold}
        label="Speak threshold"
      />

      <Toggle
        value={voiceControl}
        toggle={toggleVoiceControl}
        label="Voice control"
      />

      <Toggle
        value={writingAssistant}
        toggle={toggleWritingAssistant}
        label="Writing assistant"
      />

      <Toggle value={autoDict} toggle={toggleAutoDict} label="Auto dict" />

      <hr />

      {!algorithmPicker && (
        <Btn
          type="button"
          onClick={() => {
            setAlgorithmPicker(true);
          }}
          style={{
            margin: "30px 0",
            display: "block",
          }}
        >
          Pokaż z powrotem wybór algorytmu
        </Btn>
      )}

      <hr />

      <Btn onClick={onClose} type="button">
        Zamknij
      </Btn>
    </ExtraControlsContainer>
  );
};
