export const RING_SPEAK_THRESHOLD = 35;
export const RING_ACTIVE_THRESHOLD = 2.5;

export const getRingColor = (ring: number) => {
  if (ring >= 50) {
    return "violet";
  }
  if (ring >= 40) {
    return "indigo";
  }
  if (ring >= 30) {
    return "blue";
  }
  if (ring >= 20) {
    return "green";
  }
  if (ring >= 15) {
    return "#DFFF00";
  }
  if (ring > 10) {
    return "#FFD700";
  }
  if (ring === 10) {
    return "#FFEA00";
  }
  if (ring >= 5) {
    return "#FFFF8F";
  }
  if (ring >= RING_ACTIVE_THRESHOLD) {
    return "#FBCEB1";
  }
  return undefined;
};
