import { FlashcardDto, Side } from "../../types";
import { useUpdateFlashcard } from "../../util/queries";
import { RefObject, useContext, useEffect, useState } from "react";
import { useAudioPlayer } from "../../util/audioPlayer";
import { GRAMMAR_CONTEXT } from "../Controls/consts";
import { flippedSide } from "../../util/side";
import { isComplete } from "../../util/flashcards";
import { isGrammar } from "../../util/grm";
import { RING_SPEAK_THRESHOLD } from "../../util/ring";
import { AppContext } from "../AppContext";

export const useSide = (startSide: Side, flashcard: FlashcardDto) => {
  const [_side, setSide] = useState(startSide);
  const grammar = isGrammar(flashcard);

  useEffect(() => {
    if (startSide === "dynamic") {
      setSide(getDynamicSide(flashcard));
    } else {
      setSide(startSide);
    }
  }, [startSide]);

  const getDynamicSide = ({ proficiency }: FlashcardDto) => {
    if (!isComplete(flashcard)) {
      return "eng";
    }

    if (proficiency % 5 === 0 || proficiency === -2) {
      return "description";
    }

    if (proficiency < 0) {
      return "eng";
    }

    return proficiency % 2 === 0 ? "eng" : "pol";
  };

  const flip = () => setSide(flippedSide(_side));

  const side = grammar ? "grammar" : _side;

  return [side, flip] as const;
};

export const useSpeakThreshold = () => {
  const { controlsState } = useContext(AppContext);
  const { speakThresholdState } = controlsState;
  return speakThresholdState[0] ? RING_SPEAK_THRESHOLD : 999999;
};

export const useUpdateProficiency = (
  flashcard: FlashcardDto,
  setProficiency: (proficiency: number) => void,
  setShownRing: (newValue: number) => void,
  bumpHeat: () => void
) => {
  const [updateFlashcard] = useUpdateFlashcard();
  const speakThreshold = useSpeakThreshold();

  return async (newProficiency: number) => {
    bumpHeat();
    const isFlaw = newProficiency < flashcard.proficiency;
    const lastAnswerFlaw = isFlaw;
    const flaws = isFlaw ? flashcard.flaws + 1 : flashcard.flaws;

    const oldRing = flashcard.ring || 0;
    const increasedRing = () => (oldRing + (oldRing > 0 ? 5 : 0)) * 1.15;
    const canDecrease = () =>
      oldRing >= speakThreshold
        ? window.confirm("Did you use it in real life?")
        : true;
    const decreasedRing = () => (canDecrease() ? oldRing / 2 : oldRing);
    const ring = isFlaw ? increasedRing() : decreasedRing();

    updateFlashcard({
      ...flashcard,
      proficiency: newProficiency,
      flaws,
      lastAnswerFlaw,
      ring,
      polText: flashcard.context.includes(GRAMMAR_CONTEXT)
        ? "-"
        : flashcard.polText,
    });
    setProficiency(newProficiency);
    setShownRing(ring);
  };
};

export const useUpdateFavourite = (
  flashcard: FlashcardDto,
  setFavourite: (newValue: boolean) => void
) => {
  const [updateFlashcard] = useUpdateFlashcard();

  return async (newIsFavourite: boolean) => {
    await updateFlashcard({
      ...flashcard,
      isFavourite: newIsFavourite,
    });
    setFavourite(newIsFavourite);
  };
};

export const useUpdateRing = (
  flashcard: FlashcardDto,
  setShownRing: (newValue: number) => void
) => {
  const [updateFlashcard] = useUpdateFlashcard();

  return async () => {
    const newRing = (flashcard.ring || 0) + 10;
    await updateFlashcard({
      ...flashcard,
      ring: newRing,
    });
    setShownRing(newRing);
  };
};

export const usePlayFlashcard = (
  id: string,
  ref?: RefObject<HTMLDivElement>
) => {
  const { playOne, isIdPlaying } = useAudioPlayer();

  const isPlaying = isIdPlaying(id);

  useEffect(() => {
    if (isPlaying) {
      ref?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isPlaying]);

  return { playOne, isPlaying };
};
