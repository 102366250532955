import { format } from "date-fns";
import { FlashcardDto } from "../../types";

export const metadataVisible = () =>
  sessionStorage.getItem("metadata") === "true";

const toggleMetadata = () => {
  sessionStorage.setItem("metadata", metadataVisible() ? "false" : "true");
};

(window as any).toggleMetadata = toggleMetadata;

console.log("Toggle metadata with toggleMetadata()");

const formatDateMeta = (date: number | undefined) => {
  return date ? format(new Date(date), "dd-MM-yy") : "never";
};
export const Metadata = ({ flashcard }: { flashcard: FlashcardDto }) => {
  return (
    <table style={{ fontSize: "0.7em", border: "1px solid grey" }}>
      <tr>
        <th>created</th>
        <th>updated</th>
        <th>flaw</th>
        <th>ring</th>
      </tr>
      <tr>
        <td>{formatDateMeta(flashcard.createTime)}</td>
        <td>{formatDateMeta(flashcard.updateTime)}</td>
        <td>{flashcard.lastAnswerFlaw ? "🔴" : "⚪️"}</td>
        <td>{(flashcard.ring || 0).toFixed(2)}</td>
      </tr>
    </table>
  );
};
