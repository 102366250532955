import { QueryClient, useMutation, useQuery } from "react-query";
import {
  askWritingAssistant,
  createCollection,
  createFlashcard,
  createNewBabble,
  createNewTopic,
  createQuizQuestion,
  createStoryWithAI,
  deleteBabble,
  deleteCollection,
  deleteFlashcard,
  deleteQuizQuestion,
  deleteStory,
  deleteTopic,
  generateExampleSentences,
  generateExampleSentencesSpecial,
  getAllFlashcards,
  getBabbles,
  getCollections,
  getFlashcard,
  getInsight,
  getQuizQuestions,
  getStories,
  getTopics,
  inquireAboutExplanation,
  inquireAboutInsight,
  regenerateTopicImage,
  updateBabble,
  updateCollection,
  updateFlashcard,
  updateQuizQuestion,
  updateTopic,
} from "./api";
import {
  BabbleDto,
  CollectionDto,
  FlashcardCreateDto,
  FlashcardDto,
  QuizQuestionDto,
  QuizQuestionTopic,
  StoryDto,
  TopicDto,
} from "../types";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../components/AppContext";

export const queryClient = new QueryClient();

const FLASHCARDS_KEY = "flashcards";
const COLLECTION_QUERY = "collections";
const STORY_QUERY = "stories";
const EXAMPLES_QUERY = "examples";
const WRITING_ASSISTANT_QUERY = "writing-assistant";
const EXAMPLES_SPECIAL_QUERY = "examples-special";
const SPEAK_TOPICS_QUERY = "speak-topics";
const BABBLES_QUERY = "babbles";
const INSIGHT_QUERY = "insight";

function updateFlashcardInCache(
  flashcardId: string,
  flashcard: Partial<FlashcardDto>
) {
  const flashcards = queryClient.getQueryData<FlashcardDto[]>(FLASHCARDS_KEY);

  if (flashcards) {
    const index = flashcards.findIndex((fl) => fl._id === flashcardId);
    flashcards[index] = { ...flashcards[index], ...flashcard };
    queryClient.setQueryData(FLASHCARDS_KEY, flashcards);
  }
}

export const useAllFlashcardsQuery = () => {
  const [error, setError] = useState<unknown | null>(null);
  const { data, isLoading } = useQuery(FLASHCARDS_KEY, async () => {
    try {
      const result = await getAllFlashcards();
      setError(null);
      return result;
    } catch (e) {
      setError(e);
    }
  });

  return [data, isLoading, error] as const;
};

export const useFlashcardQuery = (id: string) => {
  const { data, isLoading } = useQuery([FLASHCARDS_KEY, id], async () => {
    return getFlashcard(id);
  });

  return [data, isLoading] as const;
};

export const useCreateFlashcard = () => {
  const { setFlashcards } = useContext(AppContext);
  const { mutate, isLoading, data } = useMutation(
    (flashcard: FlashcardCreateDto) => {
      return createFlashcard(flashcard);
    },
    {
      onSuccess: (cr) => {
        queryClient.invalidateQueries(FLASHCARDS_KEY);
        setFlashcards((prev) => [cr, ...prev]);
      },
    }
  );

  return [mutate, isLoading, data] as const;
};

export const useUpdateFlashcard = () => {
  const { setFlashcards } = useContext(AppContext);
  const { mutate, isLoading } = useMutation(
    async (flashcard: FlashcardDto) => {
      return updateFlashcard(flashcard);
    },
    {
      onSuccess: (flashcard) => {
        updateFlashcardInCache(flashcard._id, flashcard);
        setFlashcards((prev) => {
          const index = prev.findIndex((fl) => fl._id === flashcard._id);
          prev[index] = flashcard;
          return [...prev];
        });
      },
    }
  );

  return [mutate, isLoading] as const;
};

export const useDeleteFlashcard = () => {
  const { setFlashcards } = useContext(AppContext);
  const { mutate, isLoading } = useMutation(
    async (id: string) => {
      await deleteFlashcard(id);
      return id;
    },
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(FLASHCARDS_KEY);
        setFlashcards((prev) => prev.filter((fl) => fl._id !== id));
      },
    }
  );

  return [mutate, isLoading] as const;
};

export const useAllCollectionsQuery = () => {
  const { data, isLoading } = useQuery(COLLECTION_QUERY, async () => {
    return getCollections();
  });

  return [data, isLoading] as const;
};

export const useUpdateCollection = () => {
  const { mutate, isLoading } = useMutation(
    async (collection: CollectionDto) => {
      await updateCollection(collection);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(COLLECTION_QUERY);
      },
    }
  );

  return [mutate, isLoading] as const;
};

export const useCreateCollection = () => {
  const { mutate, isLoading } = useMutation(
    async (name: string) => {
      await createCollection(name);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(COLLECTION_QUERY);
      },
    }
  );

  return [mutate, isLoading] as const;
};

export const useDeleteCollection = () => {
  const { mutate, isLoading } = useMutation(
    async (collectionId: string) => {
      await deleteCollection(collectionId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(COLLECTION_QUERY);
      },
    }
  );

  return [mutate, isLoading] as const;
};

export const useAllStoriesQuery = () => {
  const { data, isLoading } = useQuery(STORY_QUERY, async () => {
    const stories = await getStories();
    return stories.reverse();
  });

  return [data, isLoading] as const;
};

export const useCreateStory = (onSuccess: (story: StoryDto) => void) => {
  const { mutate, isLoading } = useMutation(
    async (flashcardIds: string[]) => {
      const story = await createStoryWithAI(flashcardIds);
      onSuccess(story);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(STORY_QUERY);
      },
    }
  );

  return [mutate, isLoading] as const;
};

export const useDeleteStory = () => {
  const { mutate, isLoading } = useMutation(
    async (id: string) => {
      await deleteStory(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(STORY_QUERY);
      },
    }
  );

  return [mutate, isLoading] as const;
};

export const useExamplesSpecialQuery = (
  flashcard: Partial<FlashcardCreateDto>
) => {
  const { data, isLoading, isRefetching, refetch } = useQuery(
    [flashcard.engText, EXAMPLES_SPECIAL_QUERY + "-" + flashcard._id],
    () => generateExampleSentencesSpecial(flashcard),
    {
      enabled: false,
    }
  );

  return [data, isLoading || isRefetching, refetch] as const;
};

export const useExamplesQuery = (
  flashcard: Partial<FlashcardCreateDto>,
  exampleContext?: string
) => {
  const { data, isLoading, isRefetching, refetch } = useQuery(
    [flashcard.engText, EXAMPLES_QUERY],
    () => generateExampleSentences(flashcard, exampleContext),
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (flashcard.engText) {
      refetch();
    }
  }, [flashcard.engText, flashcard.polText, exampleContext]);

  return [data, isLoading || isRefetching, refetch] as const;
};

export const useWritingAssistantQuery = (
  text: string,
  crucialPhrase?: string
) => {
  const { data, isLoading } = useQuery([text, WRITING_ASSISTANT_QUERY], () =>
    askWritingAssistant(text, crucialPhrase)
  );

  return [data, isLoading] as const;
};

export const useSpeakTopicsQuery = (newOnly: boolean) => {
  // let storedData: TopicDto[] = JSON.parse(localStorage.getItem("topics") || "[]");
  let storedData: TopicDto[] = [];

  const { data, isLoading } = useQuery(SPEAK_TOPICS_QUERY, () =>
    getTopics(newOnly)
  );

  useEffect(() => {
    if (data) {
      // localStorage.setItem("topics", JSON.stringify(data));
    }
  }, [data]);

  return [data || storedData, isLoading] as const;
};

export const useRemoveTopic = () => {
  const { mutate, isLoading } = useMutation(
    async (id: string) => {
      await deleteTopic(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SPEAK_TOPICS_QUERY);
      },
    }
  );

  return [mutate, isLoading] as const;
};

export const useUpdateTopic = () => {
  const { mutate, isLoading } = useMutation(
    async (topic: TopicDto) => {
      await updateTopic(topic);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SPEAK_TOPICS_QUERY);
      },
    }
  );

  return [mutate, isLoading] as const;
};

export const useRegenerateTopicImage = () => {
  const { mutate, isLoading } = useMutation(
    async (topic: TopicDto) => {
      await regenerateTopicImage(topic._id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SPEAK_TOPICS_QUERY);
      },
    }
  );

  return [mutate, isLoading] as const;
};

export const useCreateTopic = () => {
  const { mutate, isLoading } = useMutation(
    async () => {
      await createNewTopic("easy");
      await createNewTopic("advanced");
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SPEAK_TOPICS_QUERY);
      },
    }
  );

  return [mutate, isLoading] as const;
};

export const useBabblesQuery = (newOnly: boolean) => {
  const { data, isLoading } = useQuery(BABBLES_QUERY, async () => {
    return getBabbles(newOnly);
  });

  return [data || [], isLoading] as const;
};

export const useRemoveBabble = () => {
  const { mutate, isLoading } = useMutation(
    async (id: string) => {
      await deleteBabble(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(BABBLES_QUERY);
      },
    }
  );

  return [mutate, isLoading] as const;
};

export const useUpdateBabble = () => {
  const { mutate, isLoading } = useMutation(
    async (topic: BabbleDto) => {
      await updateBabble(topic);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(BABBLES_QUERY);
      },
    }
  );

  return [mutate, isLoading] as const;
};

export const useCreateBabble = () => {
  const { mutate, isLoading, data } = useMutation(
    async (text?: string) => {
      return createNewBabble(text);
    },
    {
      onSuccess: (babble) => {
        queryClient.invalidateQueries(BABBLES_QUERY);
      },
    }
  );

  const create = async (text?: string) => {
    const babble = await createNewBabble(text);
    queryClient.invalidateQueries(BABBLES_QUERY);
    return babble;
  };

  return [create] as const;
};

export const useInsightQuery = (flashcardId: string, regenerate: boolean) => {
  const { data, isLoading, isRefetching, refetch } = useQuery(
    [INSIGHT_QUERY, flashcardId],
    async () => {
      return await getInsight(flashcardId, regenerate);
    },
    {
      enabled: false,
    }
  );

  return [data, isLoading || isRefetching, refetch] as const;
};

export const useInquireAboutInsight = (flashcardId: string) => {
  const { mutate, isLoading } = useMutation(
    async (inquiry: string) => {
      return inquireAboutInsight(flashcardId, inquiry);
    },
    {
      onSuccess: (insight) => {
        queryClient.setQueryData([INSIGHT_QUERY, flashcardId], insight);
      },
    }
  );

  return [mutate, isLoading] as const;
};

export const useInquireAboutExplanation = (questionId: string) => {
  const { mutate, isLoading } = useMutation(
    async (inquiry: string) => {
      return inquireAboutExplanation(questionId, inquiry);
    },
    {
      onSuccess: (insight) => {
        queryClient.setQueryData(`explanation-${questionId}`, insight);
      },
    }
  );

  return [mutate, isLoading] as const;
};

export const useQuizQuestions = (type: QuizQuestionTopic) => {
  const { data, isLoading } = useQuery(`QUIZ-${type}`, async () => {
    return getQuizQuestions(type);
  });

  return [data || [], isLoading] as const;
};

export const useRemoveQuizQuestion = (type: QuizQuestionTopic) => {
  const { mutate, isLoading } = useMutation(
    async (id: string) => {
      await deleteQuizQuestion(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`QUIZ-${type}`);
      },
    }
  );

  return [mutate, isLoading] as const;
};

export const useUpdateQuizQuestion = (topic: QuizQuestionTopic) => {
  const { mutate, isLoading } = useMutation(
    async (question: QuizQuestionDto) => {
      await updateQuizQuestion(question);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`QUIZ-${topic}`);
      },
    }
  );

  return [mutate, isLoading] as const;
};

export const useCreateQuizQuestion = (topic: QuizQuestionTopic) => {
  const { mutate, isLoading, data } = useMutation(
    async (payload: { text: string | string[]; isFavourite?: boolean }) => {
      const { text, ...rest } = payload;
      return createQuizQuestion(topic, payload.text, rest);
    },
    {
      onSuccess: (babble) => {
        queryClient.invalidateQueries(`QUIZ-${topic}`);
      },
    }
  );
  return [mutate, isLoading] as const;
};
