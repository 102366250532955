import styled from "styled-components";
import React, { FC, PropsWithChildren } from "react";

const StyledDictLink = styled.a`
  text-decoration: none;
`;

interface DictLinkProps extends PropsWithChildren {
  engText: string;
}

export const getDictLink = (engText: string) =>
  `https://www.diki.pl/slownik-angielskiego?q=${engText}`;

export const DictLink: FC<DictLinkProps> = (props) => {
  const { engText, children } = props;

  return (
    <StyledDictLink
      href={getDictLink(engText)}
      target="_blank"
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </StyledDictLink>
  );
};
