import styled from "styled-components";
import React, { FC } from "react";

type TranslateService = "google" | "deepl";

export const getTranslationUrl = (text: string, service: TranslateService) => {
  const encoded = encodeURI(text);
  switch (service) {
    case "google":
      return `https://translate.google.pl/?hl=pl&sl=en&tl=pl&text=${encoded}&op=translate`;
    case "deepl":
      return `https://www.deepl.com/pl/translator#en/pl/${encoded}`;
  }
};

const StyledTranslationLink = styled.a`
  text-decoration: none;
  color: inherit !important;
`;

interface TranslationLinkProps {
  text: string;
  service?: TranslateService;
  beforeNavigate?: () => void;
}

export const TranslationLink: FC<TranslationLinkProps> = (props) => {
  const { text, service = "deepl", beforeNavigate } = props;

  const url = getTranslationUrl(text, service);

  return (
    <StyledTranslationLink
      href={url}
      target="_blank"
      onClick={(e) => {
        e.stopPropagation();
        beforeNavigate?.();
      }}
    >
      🅃
    </StyledTranslationLink>
  );
};
