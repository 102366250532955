let currentAudioElement: HTMLAudioElement | null = null;
let currentUtterance: SpeechSynthesisUtterance | null = null;

const API_KEY = process.env.REACT_APP_TRANSLATE_API_KEY!;

export type Lang = "en-GB" | "pl-PL";
type PreferredMethod = "google" | "speechSynthesis";
type EngAccent = "female-1" | "female-2" | "male-1";

export interface SpeakConfig {
  preferredMethod?: PreferredMethod;
  engAccent?: EngAccent;
  rate?: number;
}

export async function speak(
  text: string,
  lang: Lang,
  config: SpeakConfig = {}
): Promise<void> {
  stopSpeech();

  if (!text) {
    return;
  }

  const speakConfig: Required<SpeakConfig> = {
    preferredMethod: config.preferredMethod || "google",
    rate: config.rate || 0.9,
    engAccent: config.engAccent || "female-1",
  };

  try {
    if (speakConfig.preferredMethod === "speechSynthesis") {
      await speakWithSpeechSynthesis(text, lang, speakConfig);
      return;
    }

    try {
      await speakWithGoogleTTS(text, lang, speakConfig);
    } catch (error) {
      await speakWithSpeechSynthesis(text, lang, speakConfig);
    }
  } catch (error) {
    console.error(error);
  }
}

async function speakWithGoogleTTS(
  text: string,
  lang: Lang,
  speakConfig: Required<SpeakConfig>
): Promise<void> {
  const apiUrl =
    "https://texttospeech.googleapis.com/v1/text:synthesize?key=" + API_KEY;

  const getVoiceName = (lang: Lang, engAccent: EngAccent) => {
    if (lang === "pl-PL") {
      return `Wavenet-A`;
    }
    switch (engAccent) {
      case "female-1":
        return `Wavenet-A`;
      case "female-2":
        return `Wavenet-F`;
      case "male-1":
        return "Wavenet-D";
    }
  };

  const requestBody = {
    input: {
      text: text,
    },
    voice: {
      languageCode: lang,
      name: `${lang}-${getVoiceName(lang, speakConfig.engAccent)}`,
    },
    audioConfig: {
      audioEncoding: "MP3",
      speakingRate: speakConfig.rate,
    },
  };

  const response = await fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error(`Google Text-to-Speech API error: ${response.status}`);
  }

  const responseData = await response.json();
  const audioContent = responseData.audioContent;
  const audioBlob = base64ToBlob(audioContent, "audio/mpeg");

  const audioElement = new Audio(URL.createObjectURL(audioBlob));
  currentAudioElement = audioElement;

  return new Promise<void>((resolve, reject) => {
    audioElement.addEventListener("ended", () => {
      resolve();
    });

    audioElement.addEventListener("error", (error) => {
      reject(error);
    });

    audioElement.play();
  });
}

function base64ToBlob(base64: string, mimeType: string): Blob {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
}

function speakWithSpeechSynthesis(
  text: string,
  lang: Lang,
  speakConfig: Required<SpeakConfig>
): Promise<void> {
  const utterance = new SpeechSynthesisUtterance(text);
  utterance.lang = lang;
  utterance.rate = speakConfig.rate;
  currentUtterance = utterance;
  speechSynthesis.speak(utterance);

  return new Promise<void>((resolve, reject) => {
    // Obsłuż zdarzenie 'end', które jest wywoływane po zakończeniu odczytywania tekstu
    utterance.addEventListener("end", () => {
      resolve();
    });

    // Obsłuż zdarzenie 'error', które jest wywoływane, gdy wystąpi błąd podczas odczytywania tekstu
    utterance.addEventListener("error", (error) => {
      reject(error);
    });
  });
}

export function stopSpeech(): void {
  if (currentAudioElement) {
    currentAudioElement.pause();
    currentAudioElement = null;
  }

  if (currentUtterance) {
    speechSynthesis.cancel();
    currentUtterance = null;
  }
}

export const checkWillPlayAudio = () => {
  return localStorage.getItem("willPlayAudio") ? "new" : null;
};

export const setWillPlayAudioQuestion = () => {
  localStorage.setItem("willPlayAudio", "true");
};
export const removeWillPlayAudio = () => {
  localStorage.removeItem("willAdd");
};
