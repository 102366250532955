import { Radio } from "./Radio";
import { algorithms, ringAlgorithms } from "../util/algorithm";
import { useContext } from "react";
import { AppContext } from "./AppContext";
import styled from "styled-components";
import { usePersistedState } from "../util/react";

const StyledAlgorithmBox = styled.div<{ ring: boolean }>`
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  padding-top: 0;
  background: ${(props) =>
    props.ring ? "rgba(241,221,45,0.22)" : "rgba(255, 127, 80, 0.22)"};
  border: 2px solid gray;
  position: relative;

  label {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }

  .close {
    cursor: pointer;
    all: unset;
    position: absolute;
    right: 20px;
    top: 20px;
  }
`;

export const AlgorithmBox = ({ ring }: { ring: boolean }) => {
  const { algorithm, setAlgorithm, ringAlgorithm, setRingAlgorithm } =
    useContext(AppContext);
  const [algorithmPicker, setAlgorithmPicker] = usePersistedState(
    "algorithm-picker",
    true,
    sessionStorage
  );

  if (!algorithmPicker) return null;

  const value = ring ? ringAlgorithm : algorithm;
  const setValue = ring ? setRingAlgorithm : setAlgorithm;
  const options = ring ? ringAlgorithms : algorithms;

  return (
    <StyledAlgorithmBox
      style={{ gridColumn: "1 / 2", gridRow: "1 / 2" }}
      ring={ring}
    >
      <Radio
        name="algorithm"
        layout="vertical-2-columns"
        value={value}
        // @ts-ignore
        options={options}
        // @ts-ignore
        onChange={(a) => setValue(a)}
      />
      <button
        type="button"
        className="close"
        onClick={() => setAlgorithmPicker(false)}
      >
        x
      </button>
    </StyledAlgorithmBox>
  );
};
