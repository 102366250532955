import styled, { css } from "styled-components";
import { Button } from "../Button";
import { favouriteColor } from "./consts";

export const Overlay = styled.div<{ offline?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
  ${(props) =>
    props.offline &&
    css`
      border: 3px solid pink;
    `}
`;
export const Buttons = styled.div`
  position: absolute;
  z-index: 2;
  pointer-events: auto;

  display: flex;
  align-items: center;

  button,
  label {
    margin-left: 10px;
  }
`;
export const ButtonsTop = styled(Buttons)`
  top: 20px;
  left: 5px;
  right: 10px;
`;
export const ButtonsBottom = styled(Buttons)`
  bottom: 20px;
  right: 5px;
`;

export const NewButtonWrapper = styled.div`
  position: relative;
`;
export const NewButton = styled(Button)`
  & {
    width: 64px;
    height: 64px;
  }
`;

export const NewButtonUp = styled(Button)`
  position: absolute;
  border-bottom: none;
  top: -35px;
  right: 10px;

  span {
    opacity: 0.9;
  }
`;

export const ToggleFavouriteButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: ${favouriteColor};
`;

export const ButtonWithValue = styled(Button)<{ value: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  span:first-child {
    color: black;
    font-size: 0.7em;
  }

  span:last-child {
    opacity: 0.3;
    position: absolute;
    font-size: 1.2em;
  }
`;

export const ToggleCollectionButton = styled(ButtonWithValue)<{
  color: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  span:last-child {
    font-size: 1em;
    color: ${(props) => props.color};
    opacity: 1;
  }

  span:first-child {
    display: none;
  }

  ${(props) =>
    props.value > 0 &&
    css`
      span:last-child {
        opacity: 0.5;
      }

      span:first-child {
        display: block;
      }
    `}
`;
