import { FC } from "react";
import styled from "styled-components";
import { AddNewLink } from "../AddNewLink";

const ExpressionStyled = styled(AddNewLink)`
  text-decoration: none;
  color: gray;
  cursor: pointer;
`;

export const UsefulExpressions: FC<{ expressions?: string[] }> = (props) => {
  if (!props.expressions || props.expressions.length === 0) return null;
  return (
    <p className="useful-expressions">
      {props.expressions.map((expression, index) => (
        <ExpressionStyled
          key={expression}
          engText={expression}
          context="SPEAK"
        />
      ))}
    </p>
  );
};
