import { useContext, useEffect, useState } from "react";
import { useAllCollectionsQuery, useUpdateCollection } from "./queries";
import { usePersistedState } from "./react";
import { CollectionDto, FlashcardDto } from "../types";
import { COLLECTION_ICON } from "../components/Controls/consts";
import { AppContext } from "../components/AppContext";

export enum NamedCollection {
  USED = "used",
  SPEAK = "speak",
  QUIZ_P = "quiz-p",
  QUIZ_G = "quiz-g",
  QUIZ_A = "quiz-a",
  QUIZ_V = "quiz-v",
}

export const useCollections = () => {
  const [fetchedCollections] = useAllCollectionsQuery();
  const [updateCollection] = useUpdateCollection();

  const [currentCollectionName, setCurrentCollectionName] = usePersistedState(
    "currentCollectionName",
    "clipboard"
  );

  const [collections, _setCollections] = useState<CollectionDto[]>([]);
  const setCollections = (collections: CollectionDto[]) => {
    _setCollections(
      collections.sort((a, b) => {
        const aOrder = a.order || 999;
        const bOrder = b.order || 999;
        if (aOrder !== bOrder) {
          return aOrder - bOrder;
        } else {
          return a.name.localeCompare(b.name);
        }
      })
    );
  };

  useEffect(() => {
    if (fetchedCollections) {
      setCollections(fetchedCollections);
    }
  }, [fetchedCollections]);

  const requestUpdateCollection = (items: string[], collectionName: string) => {
    const collection = collections?.find(
      (collection) => collection.name === collectionName
    );
    if (collection) {
      updateCollection({
        ...collection,
        items,
      });
    }
  };

  const toggleCollectionItem = (
    collectionName: string,
    id: string,
    addOnly = false
  ) => {
    const collectionIndex = collections?.findIndex(
      (collection) => collection.name === collectionName
    );
    if (collectionIndex === -1) {
      return;
    }
    const updatedCollection = collections[collectionIndex];

    const itemInCollection = updatedCollection.items.includes(id);

    if (itemInCollection && addOnly) {
      return;
    }

    updatedCollection.items = itemInCollection
      ? updatedCollection.items.filter((i) => i !== id)
      : [...updatedCollection.items, id];
    setCollections(
      collections
        .filter((c) => c.name !== collectionName)
        .concat(updatedCollection)
    );
    requestUpdateCollection(updatedCollection.items, collectionName);
  };

  return {
    collections,
    currentCollection: currentCollectionName,
    setCurrentCollection: setCurrentCollectionName,
    toggleCollectionItem,
  };
};

export const useCollection = () => {
  const { collections } = useContext(AppContext);
  return (collectionName: string) => {
    return (
      collections?.find((collection) => collection.name === collectionName) || {
        color: "gray",
        icon: COLLECTION_ICON,
      }
    );
  };
};

export const getFlashcardCollections = (
  flashcardId: string,
  collections: CollectionDto[]
) => {
  return collections.filter((collection) =>
    collection.items.includes(flashcardId)
  );
};

export const getCollectionFlashcards = (
  flashcards: FlashcardDto[],
  collections: CollectionDto[],
  collectionName: string
) => {
  const collection = collections.find(
    (collection) => collection.name === collectionName
  );
  if (!collection) {
    return [];
  }
  return flashcards.filter((flashcard) =>
    collection.items.includes(flashcard._id)
  );
};
