import React, { FC, useEffect } from "react";
import { Topic } from "../components/Speak/Topic";
import { ControlsNavigation } from "../components/ControlsNavigation";
import { useLocation } from "react-router-dom";
import { Babble } from "../components/Speak/Babble";
import { useNavigate } from "react-router";
import { FullView } from "../components/Speak/style";

export const SpeakPage: FC = () => {
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const lastSeenSpeak = localStorage.getItem("last-seen-speak");

  useEffect(() => {
    if (pathname === "/speak") {
      switch (lastSeenSpeak) {
        case "topic":
          navigate("/speak/topic");
          break;
        case "babble":
          navigate("/speak/babble");
          break;
        default:
          navigate("/speak/topic");
      }
    }
  }, []);

  return (
    <FullView>
      {/*<SpeakNavigation />*/}
      {pathname === "/speak/topic" && <Topic />}
      {pathname === "/speak/babble" && <Babble />}
      <ControlsNavigation active={"speak"} />
    </FullView>
  );
};
