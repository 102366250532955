import React from "react";
import { getDictLink } from "../DictLink";
import styled from "styled-components";

interface AutoDictProps {
  engText: string;
  flashcardId?: string; // Optional flashcardId prop
}

// Styled-components for the container, iframe, and refresh button
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none; /* Remove border if necessary */
`;

const RefreshButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #4caf50;
  color: white;
  padding: 8px 16px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 10;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const AutoDictIframe: React.FC<AutoDictProps> = (props) => {
  const [engText, setEngText] = React.useState(props.engText);

  const handleRefresh = () => {
    setEngText(props.engText);
  };

  if (!engText) {
    return <div />;
  }

  const url = getDictLink(engText);

  return (
    <Container>
      {engText !== props.engText && (
        <RefreshButton onClick={handleRefresh}>🔄 Refresh</RefreshButton>
      )}
      <Iframe id="autodict" src={url} sandbox="true" />
    </Container>
  );
};
