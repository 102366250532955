import { useForm } from "react-hook-form";
import { FC, useState } from "react";
import styled from "styled-components";
import { ModalButton } from "./ModalComponents";

const FormStyled = styled.form`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin: 10px 0;
  padding: 10px 15px;
  font-size: 1.2em;
  gap: 10px;

  textarea {
    height: 100%;
    flex: 1;
    padding: 10px;
  }

  button {
    flex: 0;
    padding: 15px;
  }
`;

const Suggestions = styled.div`
  padding: 0 10px;
`;

const Suggestion = styled.p`
  color: orange;
  cursor: pointer;
`;

interface InquiryFormProps {
  onInquire: (inquiry: string) => void;
  inquirySuggestions?: string[];
}

export const InquiryForm: FC<InquiryFormProps> = (props) => {
  const { register, handleSubmit, reset } = useForm<{ inquiry: string }>();

  const submit = handleSubmit(({ inquiry }) => {
    if (!inquiry) return;
    props.onInquire(inquiry);
    reset();
  });

  const onSuggestion = (suggestion: string) => {
    props.onInquire(suggestion);
    reset();
  };

  return (
    <>
      <FormStyled onSubmit={submit}>
        <textarea
          {...register("inquiry")}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              submit();
            }
          }}
        />
        <ModalButton
          style={{ height: "100%", cursor: "pointer", margin: "0" }}
          type="submit"
        >
          ➢
        </ModalButton>
      </FormStyled>
      {props.inquirySuggestions && (
        <InquireSuggestions
          onClickSuggestion={onSuggestion}
          suggestions={props.inquirySuggestions}
        />
      )}
    </>
  );
};

const InquireSuggestions = ({
  suggestions,
  onClickSuggestion,
}: {
  suggestions: string[];
  onClickSuggestion: (suggestion: string) => void;
}) => {
  const [collapsed, setCollapse] = useState(true);

  const shownSuggestions = collapsed
    ? suggestions.filter((sug) => !sug.startsWith("-"))
    : suggestions;

  return (
    <Suggestions>
      {collapsed && (
        <Suggestion onClick={() => setCollapse(false)}>Show More</Suggestion>
      )}

      {shownSuggestions.map((suggestion) => (
        <Suggestion
          key={suggestion}
          onClick={() => onClickSuggestion(suggestion)}
        >
          {suggestion}
        </Suggestion>
      ))}
    </Suggestions>
  );
};
