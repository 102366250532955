import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const StyledConfigNavigation = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 400px;
  margin: 5px auto;
`;

const LinkStyled = styled(Link)<{ active: boolean }>`
  all: unset;
  cursor: pointer;
  text-align: center;
  margin: 2px;
  padding: 5px;
  background: aquamarine;
  border: 1px solid transparent;

  ${({ active }) =>
    active &&
    `
    border-color: black;
    `}
`;

export const ConfigNavigation = () => {
  const { pathname } = useLocation();
  return (
    <StyledConfigNavigation>
      <LinkStyled to={"/config/topic"} active={pathname === "/config/topic"}>
        Speak topic
      </LinkStyled>
      <LinkStyled to={"/config/babble"} active={pathname === "/config/babble"}>
        Babble
      </LinkStyled>
      <LinkStyled
        to={"/config/collection"}
        active={pathname === "/config/collection"}
      >
        Collection
      </LinkStyled>
      <LinkStyled
        to={"/config/insights"}
        active={pathname === "/config/insights"}
      >
        Insights
      </LinkStyled>
    </StyledConfigNavigation>
  );
};
