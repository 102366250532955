import React, { FC, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import styled, { css } from "styled-components";
import Button from "../Button";
import { scaleOnHover } from "../Style";

type RecordStatus = "off" | "progress" | "done";

export const RECORDED_COLOR = "#DF3CF4";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const RecordButton = styled.button<{
  status: RecordStatus;
}>`
  all: unset;
  cursor: pointer;
  font-size: 2em;

  ${scaleOnHover()};

  border-radius: 50%;
  border: 2px solid #000;
  padding: 20px;

  ${({ status }) => {
    switch (status) {
      case "off":
        return css`
          border-color: lightgray;
        `;
      case "progress":
        return css`
          border-color: blue;
        `;
      case "done":
        return css`
          border-color: ${RECORDED_COLOR};
        `;
    }
  }}
`;

const SaveButton = styled(Button)`
  background: none;
  border: none;
  padding: 10px 20px;
`;

interface RecordProps {
  recorded: string;
  setRecorded: (text: string) => void;
  saveRecorded: (text: string) => void;
}

export const Record: FC<RecordProps> = (props) => {
  const { recorded, setRecorded, saveRecorded } = props;
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition();

  useEffect(() => {
    setRecorded(transcript);
  }, [transcript]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  if (!isMicrophoneAvailable) {
    return <span>Microphone is not available.</span>;
  }

  const status = ((): RecordStatus => {
    if (listening) {
      return "progress";
    }

    if (recorded) {
      return "done";
    }
    return "off";
  })();

  return (
    <Wrapper>
      <RecordButton
        status={status}
        onClick={() => {
          if (listening) {
            SpeechRecognition.stopListening();
          } else {
            SpeechRecognition.startListening({ language: "en-GB" });
          }
        }}
      >
        🎤
      </RecordButton>
      {recorded && (
        <SaveButton onClick={() => saveRecorded(recorded)}>Save</SaveButton>
      )}
    </Wrapper>
  );
};
