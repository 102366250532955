import { ButtonsBottom, ButtonWithValue, Overlay } from "./Controls/style";
import Button from "./Button";
import React, { FC, useContext } from "react";
import { useNavigate } from "react-router";
import { AppContext } from "./AppContext";
import { ButtonAdd } from "./Controls/ButtonAdd";

interface ControlsNavigationProps {
  active: "config" | "speak" | "stories" | "insights" | "quiz";
}

export const ControlsNavigation: FC<ControlsNavigationProps> = (props) => {
  const { active } = props;
  const navigate = useNavigate();
  const { getQuizometer } = useContext(AppContext);
  const quizometer = getQuizometer("all");

  return (
    <Overlay>
      <ButtonsBottom>
        <Button
          topStyle
          isActive={active === "config"}
          onClick={() => navigate("/config")}
        >
          🛠️
        </Button>
        {/*<Button topStyle isActive>*/}
        {/*  🪄*/}
        {/*</Button>*/}
        <Button
          topStyle
          isActive={active === "speak"}
          onClick={() => navigate("/speak")}
        >
          💬
        </Button>
        <ButtonWithValue
          onClick={() => navigate("/quiz")}
          topStyle
          value={quizometer}
          isActive={active === "quiz"}
        >
          <span>{quizometer}</span>
          <span>🎲</span>
        </ButtonWithValue>
        <Button onClick={() => navigate("/#challenge")} topStyle>
          🔥
        </Button>
        <Button onClick={() => navigate("/#polishing")} topStyle>
          🧹
        </Button>
        <ButtonAdd autoPlay={false} />
      </ButtonsBottom>
    </Overlay>
  );
};
