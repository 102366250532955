import { useIsMobile } from "../../util/react";
import { FlashcardDto } from "../../types";
import React, { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { PlayAll } from "./PlayAll";
import { AppContext } from "../AppContext";
import { Button } from "../Button";
import { ContextSelect } from "./ContextSelect";
import { ProgressIndicator } from "./ProgressIndicator";
import { Search } from "./Search";
import { ControlsState, useExtraControls } from "./hooks";
import {
  ButtonsBottom,
  ButtonsTop,
  ButtonWithValue,
  Overlay,
  ToggleCollectionButton,
  ToggleFavouriteButton,
} from "./style";
import { Modal } from "../Modal";
import { ExtraControls } from "../ExtraControls";
import { COLLECTION_CONTEXT, FAVOURITES_CONTEXT } from "./consts";
import { CollectionModal } from "../CollectionModal";
import { useCollection } from "../../util/collections";
import { nextSelectableSide, sideIcon } from "../../util/side";
import { ButtonAdd } from "./ButtonAdd";
import { FaBell } from "react-icons/fa";
import { useIsWriterPage } from "../../util/writer";

export interface ControlsProps {
  controlsState: ControlsState;
  isShuffled: boolean;
  autoPlay: boolean;
  shuffle: () => void;
  resetShuffle: () => void;
  allContexts: string[];
  shownCount: number;
  shownIndex: number;
  markedNumber: number;
  flashcards: FlashcardDto[];
}

export const Controls: FC<ControlsProps> = (props) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const isWriter = useIsWriterPage();
  const {
    isOffline,
    heat,
    currentCollection,
    uses,
    getQuizometer,
    loadingFlashcards,
  } = useContext(AppContext);
  const quizometer = getQuizometer("all");

  const {
    controlsState,
    shuffle,
    resetShuffle,
    isShuffled,
    shownCount,
    shownIndex,
    flashcards,
    autoPlay,
    markedNumber,
  } = props;
  const {
    completionState,
    startSideState,
    contextsState,
    searchState,
    ringFavouriteState,
  } = controlsState;

  const [completion, setCompletion] = completionState;
  const [startSide, setStartSide] = startSideState;
  const [selectedContexts, setSelectedContexts] = contextsState;
  const [searchText, setSearchText] = searchState;

  const [searchVisible, setSearchVisible] = useState(false);
  const [ringFavourite] = ringFavouriteState;

  const [extraControlsVisible, closeExtraControls, showExtraControls] =
    useExtraControls();

  const [collectionModalVisible, setCollectionModalVisible] = useState(false);
  const closeCollectionModal = () => setCollectionModalVisible(false);

  const scrollUp = () => window.scrollTo({ top: 0 });

  const goToMain = () => {
    navigate("/");
    setCompletion("complete");
    scrollUp();
  };

  const goToChallenge = () => {
    navigate("/");
    setCompletion("challenge");
    scrollUp();
  };

  const goToPolishing = () => {
    navigate("/");
    setCompletion("incomplete");
    // resetShuffle();
    scrollUp();
  };

  const flashcardsReady = flashcards.length > 0;

  useEffect(() => {
    if (completion === "challenge") {
      shuffle();
    } else {
      resetShuffle();
    }
  }, [completion, flashcardsReady]);

  useEffect(() => {
    if (autoPlay) {
      setCompletion("challenge");
    }
  }, [autoPlay]);

  const showNonSearchButtons = !isMobile || !searchVisible;

  const isFavouriteContextSelected =
    selectedContexts.includes(FAVOURITES_CONTEXT);
  const toggleFavouriteContext = () => {
    if (isFavouriteContextSelected) {
      setSelectedContexts(
        selectedContexts.filter((c) => c !== FAVOURITES_CONTEXT)
      );
    } else {
      setSelectedContexts([...selectedContexts, FAVOURITES_CONTEXT]);
    }
  };

  const isCollectionContextSelected =
    selectedContexts.includes(COLLECTION_CONTEXT);
  const toggleCollectionContext = () => {
    if (isCollectionContextSelected) {
      setSelectedContexts(
        selectedContexts.filter((c) => c !== COLLECTION_CONTEXT)
      );
    } else {
      setSelectedContexts([...selectedContexts, COLLECTION_CONTEXT]);
    }
  };

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if ((window as any).insightOpen) return;

      if (e.key === "Escape") {
        setSearchVisible(false);
      }

      if (/^[a-zA-ZżŻćĆłŁ]$/.test(e.key)) {
        if (document.activeElement?.id !== "main-search") {
          setSearchVisible(true);
          setSearchText(e.key);
        }
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, []);

  useEffect(() => {
    if (!searchText) {
      setSearchVisible(false);
      setSearchText("");
    }
  }, [searchText]);

  useEffect(() => {
    // if url contains #challenge, remove it from url
    if (window.location.hash.includes("#challenge")) {
      window.location.hash = "";
      goToChallenge();
    } else if (window.location.hash === "#polishing") {
      window.location.hash = "";
      goToPolishing();
    } else if (window.location.hash === "#main") {
      window.location.hash = "";
      goToMain();
    }
  }, []);

  const getCollection = useCollection();

  return (
    <Overlay offline={isOffline}>
      <ButtonsTop>
        {showNonSearchButtons && (
          <>
            {" "}
            <Button
              onClick={() => setStartSide(nextSelectableSide(startSide))}
              topStyle
              smaller
            >
              {sideIcon(startSide)}
            </Button>
            <ContextSelect {...props} />
            {selectedContexts.filter(
              (ct) => ct !== FAVOURITES_CONTEXT && ct !== COLLECTION_CONTEXT
            ).length > 0 && (
              <Button
                onClick={() => {
                  setSelectedContexts(
                    selectedContexts.filter(
                      (c) =>
                        c === COLLECTION_CONTEXT || c === FAVOURITES_CONTEXT
                    )
                  );
                }}
                topStyle
                smaller
              >
                ∅
              </Button>
            )}
            <ToggleCollectionButton
              onClick={() =>
                !collectionModalVisible && toggleCollectionContext()
              }
              topStyle
              isActive={isCollectionContextSelected}
              smaller
              color={getCollection(currentCollection).color}
              onRightClick={() => setCollectionModalVisible(true)}
              value={uses}
            >
              {isWriter ? (
                <span
                  style={{ color: "black", opacity: 1, fontWeight: "bold" }}
                >
                  {uses}
                </span>
              ) : (
                <span>{getCollection(currentCollection).icon}</span>
              )}
            </ToggleCollectionButton>
            <ToggleFavouriteButton
              onClick={toggleFavouriteContext}
              topStyle
              isActive={isFavouriteContextSelected}
              smaller
            >
              {ringFavourite ? <FaBell /> : "★"}
            </ToggleFavouriteButton>
            <PlayAll
              flashcards={flashcards}
              startSide={startSide}
              autoPlay={isShuffled && autoPlay}
            />
          </>
        )}
        <Button
          onClick={() =>
            setSearchVisible((value) => {
              const newValue = !value;
              if (!newValue) {
                setSearchText("");
              }
              return newValue;
            })
          }
          isActive={Boolean(searchState[0])}
          topStyle
          smaller
        >
          {searchVisible ? "🔍" : "🔎"}{" "}
        </Button>

        {searchVisible && <Search state={searchState} />}
      </ButtonsTop>
      <ButtonsBottom>
        <ProgressIndicator
          current={shownIndex}
          max={shownCount}
          marked={markedNumber}
          onClick={() => !extraControlsVisible && scrollUp()}
          onRightClick={showExtraControls}
          loading={loadingFlashcards}
        />
        {/*<CreateStory flashcards={flashcards} startSide={startSide} />*/}
        <Button onClick={() => navigate("/speak")} topStyle>
          💬
        </Button>
        <ButtonWithValue
          onClick={() => navigate("/quiz")}
          topStyle
          value={quizometer}
        >
          <span>{quizometer}</span>
          <span>🎲</span>
        </ButtonWithValue>
        <ButtonWithValue
          isActive={completion === "challenge"}
          onClick={goToChallenge}
          topStyle
          value={heat}
        >
          <span>{heat}</span>
          <span>🔥</span>
        </ButtonWithValue>
        <Button
          isActive={completion === "incomplete"}
          onClick={goToPolishing}
          topStyle
        >
          🧹
        </Button>
        <ButtonAdd autoPlay={autoPlay} />
      </ButtonsBottom>
      {extraControlsVisible && (
        <Modal onClickOutside={closeExtraControls}>
          <ExtraControls
            onClose={closeExtraControls}
            controlsState={controlsState}
          />
        </Modal>
      )}
      {collectionModalVisible && (
        <CollectionModal onClose={closeCollectionModal} />
      )}
    </Overlay>
  );
};
