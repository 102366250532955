import React, { FC, useContext } from "react";
import { useNavigate, useParams } from "react-router";
import { FlashcardForm } from "../components/FlashcardsForm/FlashcardForm";
import { useDeleteFlashcard, useUpdateFlashcard } from "../util/queries";
import { asUntranslated } from "../util/translate";
import { Modal } from "../components/Modal";
import { AppContext } from "../components/AppContext";
import { message } from "../util/animation";

export const EditPageModal: FC = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <Modal onClickOutside={goBack}>
      <EditPage onClose={goBack} />
    </Modal>
  );
};

interface EditPageProps {
  onClose: () => void;
}

const EditPage: FC<EditPageProps> = ({ onClose }) => {
  const { id } = useParams<{ id: string }>();
  const { flashcards } = useContext(AppContext);
  const flashcard = flashcards.find((f) => f._id === id);

  const [updateFlashcard] = useUpdateFlashcard();
  const [deleteFlashcard] = useDeleteFlashcard();

  if (!id) {
    return <p>Id not found</p>;
  }

  if (!flashcard) {
    return <p>Flashcard not found</p>;
  }

  return (
    <>
      <FlashcardForm
        flashcard={flashcard}
        submitText="Zmień / zaakceptuj tłumaczenie"
        onSubmit={async (data) => {
          data.polText = asUntranslated(data.polText);
          await updateFlashcard({
            ...flashcard,
            ...data,
          });
          await message(`Updated!`, 500, "blue");
          onClose();
        }}
        onDelete={async () => {
          if (window.confirm("Czy na pewno usunąć?")) {
            await deleteFlashcard(id);
            await message(`Deleted!`, 500, "red");
            onClose();
          }
        }}
      />
    </>
  );
};
