import { Modal } from "./Modal";
import { FC, useContext } from "react";
import styled from "styled-components";
import { AppContext } from "./AppContext";
import { CollectionSelect } from "./CollectionSelect";

interface CollectionModalProps {
  onClose: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;

  padding: 20px;
  @media (min-width: 800px) {
    padding: 0;
  }

  h3 {
    text-align: center;
  }
`;

const Btn = styled.button`
  height: 40px;
  margin-top: 40px;
`;

export const CollectionModal: FC<CollectionModalProps> = (props) => {
  const { currentCollection, setCurrentCollection } = useContext(AppContext);

  return (
    <Modal onClickOutside={props.onClose} width={800}>
      <Container>
        <h3>Aktualna kolekcja?</h3>
        <CollectionSelect
          values={[currentCollection]}
          onChange={(col) => {
            setCurrentCollection(col);
            props.onClose();
          }}
        />
        <Btn onClick={props.onClose} type="button">
          Zamknij
        </Btn>
      </Container>
    </Modal>
  );
};
