export function parseText(text: string): string {
  // Replace line endings with <br />
  text = text.replace(/\n/g, "<br />");

  // Change all **x** to <b>x</b>
  text = text.replace(/\*(.*?)\*/g, "<b>$1</b>");

  return text;
}

export function extractSquareBrackets(input: string): {
  text: string;
  extracted: string;
} {
  const match = input.match(/^(.*?)\[(.*?)\](.*)$/);

  if (match) {
    return { text: match[1] + match[3], extracted: match[2] };
  } else {
    return { text: input, extracted: "" };
  }
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
