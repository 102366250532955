import styled, { css } from "styled-components";

export const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

export const scaleOnHover = (scale = 1.2) => css`
  transition: transform 0.05s ease-in-out;

  &:hover {
    transform: scale(${scale});
  }
`;
