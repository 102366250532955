import React, { FC, useEffect, useMemo, useState } from "react";
import { ExerciseGapSegment, parseExercise } from "../utils/gap-exercise";
import { QuizQuestionDto } from "../../../types";
import { ReadOut } from "../../ReadOut";

function useGaps(question: QuizQuestionDto) {
  return useMemo(() => {
    return parseExercise(question, 0)[0];
  }, [question._id]);
}

function useStates(question: QuizQuestionDto, gapIndexes: number[]) {
  const gapState1 = useState<string>("");
  const gapState2 = useState<string>("");
  const gapState3 = useState<string>("");
  const gapState4 = useState<string>("");
  const gapState5 = useState<string>("");
  const gapState6 = useState<string>("");
  const gapState7 = useState<string>("");
  const gapState8 = useState<string>("");
  const gapState9 = useState<string>("");
  const gapState10 = useState<string>("");
  const gapState11 = useState<string>("");
  const gapState12 = useState<string>("");

  useEffect(() => {
    gapState1[1]("");
    gapState2[1]("");
    gapState3[1]("");
    gapState4[1]("");
    gapState5[1]("");
    gapState6[1]("");
    gapState7[1]("");
    gapState8[1]("");
    gapState9[1]("");
    gapState10[1]("");
    gapState11[1]("");
    gapState12[1]("");
  }, [question._id]);

  function getGapState(gapIndex: number) {
    const arrayIndex = gapIndexes.indexOf(gapIndex);
    switch (arrayIndex) {
      case 0:
        return gapState1;
      case 1:
        return gapState2;
      case 2:
        return gapState3;
      case 3:
        return gapState4;
      case 4:
        return gapState5;
      case 5:
        return gapState6;
      case 6:
        return gapState7;
      case 7:
        return gapState8;
      case 8:
        return gapState9;
      case 9:
        return gapState10;
      case 10:
        return gapState11;
      case 11:
        return gapState12;
      default:
        throw new Error("Gap index out of range");
    }
  }

  return { getGapState };
}

interface ExerciseProps {
  question: QuizQuestionDto;
  submitted: boolean;

  onAnswer(question: QuizQuestionDto, correct: boolean): void;
}

export const Exercise: FC<ExerciseProps> = (props) => {
  const { question, submitted, onAnswer } = props;

  const { segments } = useGaps(question);

  const gapIndexes = segments.flatMap((segment, index) =>
    segment.type === "gap" ? [index] : []
  );

  const { getGapState } = useStates(question, gapIndexes);

  const isGapCorrect = (gapIndex: number) => {
    const [value] = getGapState(gapIndex);
    const segment = segments[gapIndex];

    return (segment as ExerciseGapSegment).answers
      .map((answer) => answer.toLowerCase().trim())
      .includes(value?.toLowerCase().trim() || "");
  };

  const allCorrect = gapIndexes.every(isGapCorrect);

  useEffect(() => {
    if (submitted) {
      onAnswer(question, allCorrect);
    }
  }, [question._id, submitted]);

  const getTextWithBrackets = (text: string) => {
    const regex = /(\(.*?\))/g;
    const parts = text.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ color: "blue" }}>
          {part}
        </span>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };

  const readable = question.text.replace(/\(\((.*?)\)\)/g, "$1");

  return (
    <>
      <p className="exercise">
        {segments.map((segment, index) => {
          if (segment.type === "text") {
            return (
              <span key={segment.id} className="separate-right">
                {getTextWithBrackets(segment.text)}
              </span>
            );
          } else {
            const [value, setValue] = getGapState(index);

            return (
              <Gap
                key={segment.id}
                submitted={submitted}
                segment={segment}
                isFirst={index === gapIndexes[0]}
                value={value}
                onChange={setValue}
                isCorrect={isGapCorrect(index)}
              />
            );
          }
        })}
        <ReadOut text={readable} />
      </p>
    </>
  );
};

interface GapProps {
  segment: ExerciseGapSegment;
  isFirst: boolean;
  submitted: boolean;
  value: string | undefined;
  isCorrect: boolean;

  onChange(value: string): void;
}

const Gap: FC<GapProps> = (props) => {
  const { value, onChange, isCorrect, segment, submitted, isFirst } = props;

  const renderAnswer = () => {
    if (submitted) {
      return <AnswerMark isCorrect={isCorrect} answer={segment.rawAnswer} />;
    }
    return null;
  };

  return (
    <>
      <input
        type="text"
        className="gap separate-right"
        autoComplete="off"
        autoFocus={isFirst}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      {renderAnswer()}
    </>
  );
};

interface AnswerMarkProps {
  isCorrect: boolean;
  answer: string;
}

export const AnswerMark: FC<AnswerMarkProps> = ({ isCorrect, answer }) => {
  return (
    <div
      className="separate-right"
      style={{ marginLeft: "5px", display: "inline-block" }}
    >
      {isCorrect ? "✅" : `❌ (${answer})`}
    </div>
  );
};
