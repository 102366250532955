import { FC } from "react";
import styled, { StyledComponent } from "styled-components";

const StyledLabel = styled.label`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  padding: 10px 5px;

  select {
    margin-top: 10px;
  }
`;

interface SelectProps {
  value: string;
  options: string[];
  label?: string;
  onChange: (value: string) => void;
  labelComponent?: StyledComponent<any, any>;
}

export const Select: FC<SelectProps> = ({
  label,
  value,
  options,
  onChange,
  labelComponent: LabelComponent = StyledLabel,
}) => {
  return (
    <LabelComponent>
      {label && <span>{label}</span>}
      <select value={value} onChange={(e) => onChange(e.target.value)}>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </LabelComponent>
  );
};
