import { QuizQuestionDto } from "../../../types";
import { isFavourite, isLastFlawed, isNotAsked } from "./quiz";
import { bisectArray, mergeArrays, shuffleArray } from "../../../util/array";
import { getRandom } from "../../../util/number";

export enum QuizAlgorithm {
  RANDOM = "rand",
  ALG_1 = "n/d/r",
  ALG_2 = "n/*/r",
  ALG_3 = "n/n/*r",
  ALG_4 = "<-n",
}

export const quizAlgorithmOptions = [
  QuizAlgorithm.RANDOM,
  QuizAlgorithm.ALG_1,
  QuizAlgorithm.ALG_2,
  QuizAlgorithm.ALG_3,
  QuizAlgorithm.ALG_4,
].map((value) => ({
  value,
  label: value,
}));

export const sortQuizQuestions = (
  questions: QuizQuestionDto[] | undefined,
  algorithm: QuizAlgorithm
) => {
  if (!questions) {
    return [];
  }

  switch (algorithm) {
    case QuizAlgorithm.RANDOM:
      return shuffleArray(questions);
    case QuizAlgorithm.ALG_1:
      return alg1(questions);
    case QuizAlgorithm.ALG_2:
      return alg2(questions);
    case QuizAlgorithm.ALG_3:
      return alg3(questions);
    case QuizAlgorithm.ALG_4:
      return alg4(questions);
    default:
      return questions;
  }
};

const alg1 = (questions: QuizQuestionDto[]) => {
  type Result = {
    new: QuizQuestionDto[];
    difficult: QuizQuestionDto[];
    rest: QuizQuestionDto[];
  };

  const result = shuffleArray(questions).reduce<Result>(
    (acc, question) => {
      if (isNotAsked(question)) {
        acc.new.push(question);
      } else if (isLastFlawed(question)) {
        acc.difficult.push(question);
      } else {
        acc.rest.push(question);
      }
      return acc;
    },
    { new: [], difficult: [], rest: [] }
  );

  return mergeArrays(result.new, result.difficult, result.rest);
};

const alg2 = (questions: QuizQuestionDto[]) => {
  type Result = {
    new: QuizQuestionDto[];
    favourite: QuizQuestionDto[];
    rest: QuizQuestionDto[];
  };

  const result = shuffleArray(questions).reduce<Result>(
    (acc, question) => {
      if (isNotAsked(question)) {
        acc.new.push(question);
      } else if (isFavourite(question)) {
        acc.favourite.push(question);
      } else {
        acc.rest.push(question);
      }
      return acc;
    },
    { new: [], favourite: [], rest: [] }
  );

  return mergeArrays(result.new, result.favourite, result.rest);
};

const alg3 = (questions: QuizQuestionDto[]) => {
  type Result = {
    new: QuizQuestionDto[];
    favourite: QuizQuestionDto[];
    rest: QuizQuestionDto[];
  };

  const result = shuffleArray(questions).reduce<Result>(
    (acc, question) => {
      if (isNotAsked(question)) {
        acc.new.push(question);
      } else if (isFavourite(question)) {
        acc.favourite.push(question);
      } else {
        acc.rest.push(question);
      }
      return acc;
    },
    { new: [], favourite: [], rest: [] }
  );

  const favAndRest = mergeArrays(result.favourite, result.rest);
  const [new1, new2] = bisectArray(result.new);

  return mergeArrays(new1, favAndRest, new2);
};

export const alg4 = (questions: QuizQuestionDto[]) => {
  return (
    questions
      .map((question) => ({
        ...question,
        order:
          question.askedCount - question.mistakesCount * 1.5 + getRandom(0, 10),
      }))
      // sort order ascending
      .sort((a, b) => a.order - b.order)
  );
};
