import { Side } from "../types";

export const flippedSide = (side: Side): Side => {
  switch (side) {
    case "eng":
      return "pol";
    case "pol":
      return "eng";
    case "description":
      return "dual";
    case "dual":
      return "description";
    default:
      return side;
  }
};
export const nextSelectableSide = (side: Side): Side => {
  const sidesOrder: Side[] = ["eng", "pol", "description", "dynamic"];

  return sidesOrder[(sidesOrder.indexOf(side) + 1) % sidesOrder.length];
};

export const sideIcon = (side: Side): string => {
  switch (side) {
    case "eng":
      return "🇬🇧";
    case "pol":
      return "🇵🇱";
    case "description":
      return "🇸🇴";
    case "dynamic":
      return "🏁";
    default:
      return "";
  }
};
