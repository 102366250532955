import { FC } from "react";
import {
  useCreateTopic,
  useSpeakTopicsQuery,
  useUpdateTopic,
} from "../../util/queries";
import { TopicDto } from "../../types";
import { FlashcardList } from "../FlashcardList";
import { usePersistedState } from "../../util/react";
import {
  Main,
  MoreButton,
  SpeakText,
  SpeakTextButtons,
  SplitColumn,
  SplitView,
} from "./style";
import { UsefulExpressions } from "./UsefulExpressions";
import { ReadOut } from "../ReadOut";
import { getRandomArrayElement, isTopicAdvanced, isTopicEasy } from "./util";
import { useSpkFlashcards } from "./spkFlashcards";
import { useSelectableText } from "./useSelectableText";
import styled from "styled-components";
import { AddNewLink } from "../AddNewLink";
import { TranslationLink } from "../TranslationLink";
import { Counter } from "./Counter";

function getTotalLength(topic?: TopicDto) {
  if (!topic) return 0;
  return (
    topic.text.length +
    (topic.usefulExpressions || []).reduce(
      (acc, expression) => acc + expression.length,
      0
    )
  );
}

const ChangeSpkTopic = styled.button`
  all: unset;
  position: absolute;
  top: 10px;
  left: 49%;
  font-size: 2em;
`;

const TopicView: FC<{ topic?: TopicDto }> = ({ topic }) => {
  const { text = "", usefulExpressions } = topic || {};
  const { selectableText, selection, sanitizedSelection } =
    useSelectableText(text);
  // const imageUrl = topic?.imageUrl;

  return (
    <SplitColumn>
      {/*{imageUrl && <Img src={imageUrl} />}*/}
      <SpeakText chars={getTotalLength(topic)} variant="topic">
        <h2>
          {selectableText} <ReadOut text={selection || text} />
        </h2>
        <UsefulExpressions expressions={usefulExpressions} />
      </SpeakText>
      <SpeakTextButtons>
        <AddNewLink engText={sanitizedSelection || text} context="SPEAK">
          ✚
        </AddNewLink>
        <TranslationLink text={sanitizedSelection || text} />
      </SpeakTextButtons>
    </SplitColumn>
  );
};

export const Topic: FC = () => {
  localStorage.setItem("last-seen-speak", "topic");

  const [allTopics] = useSpeakTopicsQuery(true);
  const [updateTopic] = useUpdateTopic();
  const [createTopic] = useCreateTopic();

  const [topicIds, setTopicIds] = usePersistedState("topic-ids", ["", ""]);
  const currentTopics = topicIds.map((id) =>
    allTopics.find((t) => t._id === id)
  );

  const newTopics = allTopics.filter(
    (topic) => topic.status === "new" && !topicIds.includes(topic._id)
  );
  const easyTopics = newTopics.filter(isTopicEasy);
  const advancedTopics = newTopics.filter(isTopicAdvanced);

  const { spkFlashcards, shuffleSpkFlashcards } = useSpkFlashcards();

  const speakMore = () => {
    createTopic();

    currentTopics.forEach((topic) => {
      if (topic) {
        updateTopic({ ...topic, status: "seen" });
      }
    });

    const newTopicIds = [
      getRandomArrayElement(easyTopics)._id,
      getRandomArrayElement(advancedTopics)._id,
    ];

    setTopicIds(newTopicIds);
    shuffleSpkFlashcards();
  };

  return (
    <Main>
      <SplitView>
        <TopicView topic={currentTopics[0]} />
        <TopicView topic={currentTopics[1]} />
      </SplitView>
      <MoreButton onClick={speakMore} magicColor="cyan" magicReverse>
        <Counter topicIds={topicIds} /> <span>More →</span>
      </MoreButton>
      <FlashcardList flashcards={spkFlashcards} startSide="eng" padding="none">
        {(flashcards) => (
          <div style={{ position: "relative", width: "100%" }}>
            {flashcards}
            <ChangeSpkTopic onClick={shuffleSpkFlashcards}>⟲</ChangeSpkTopic>
          </div>
        )}
      </FlashcardList>
    </Main>
  );
};
