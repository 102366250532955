import { FlashcardCreateDto } from "../../types";
import { FC, useEffect, useState } from "react";
import { useExamplesQuery, useExamplesSpecialQuery } from "../../util/queries";
import { Modal } from "../Modal";
import styled from "styled-components";
import Button from "../Button";
import { RainbowLoader } from "../RainbowLoader";
import { ModalFlashcardHeading } from "../ModalComponents";
import { ReadOut } from "../ReadOut";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  font-size: 1.2rem;
  text-align: center;

  .buttons {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  button {
    margin: 5px 0;
    padding: 20px 15px;
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.2);

    &:hover {
      border-color: blue;
    }
  }

  .example-option,
  .req-option {
    margin-bottom: 10px;

    .option-text {
      display: block;
      max-width: 500px;
      line-height: 1.5em;
      margin: 0 auto;
    }
  }

  .example-option {
    border: 1px solid blue;

    &:hover {
      background-color: blue;
      color: white;
    }
  }

  .special-example-option {
    border: 1px solid gold;

    &:hover {
      background-color: gold;
      color: white;
    }
  }

  .req-option {
    border: 1px solid blueviolet;
    color: blueviolet;

    &:hover {
      background-color: blueviolet;
      color: white;
    }
  }

  .reload {
    margin-top: 40px;
    border-color: transparent;
  }

  .cancel {
    flex: 1;
  }
`;

const ContextList = styled.div<{ custom?: boolean }>`
  padding: 10px;
  // grid
  display: grid;
  // minmax
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 0 10px;
  ${(props) =>
    props.custom &&
    `
    button {
        border: 1px dashed #aeaeae !important;
        }
  `}
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  margin: 20px 0 10px;
`;

interface ExampleGeneratorProps {
  flashcard: FlashcardCreateDto;
  workInBackground: boolean;
  onSelectExample: (example: string) => void;
  onClose: () => void;
}

const exampleContexts = [
  "Harry Potter",
  "Funny",
  "Dark fantasy",
  "Bible",
] as const;
type ExampleContext = (typeof exampleContexts)[number];
const AnyContext = "New context..." as const;

const isCustomContext = (context: string | number) => {
  return !exampleContexts.includes(context as any);
};

const getExampleContextText = (
  exampleContext: ExampleContext | string | number
) => {
  if (typeof exampleContext === "number") return "";

  switch (exampleContext) {
    case "Harry Potter":
      return "Use Harry Potter series context.";
    case "Funny":
      return "Create funny sentences. Be creative!";
    case "Dark fantasy":
      return "Use LOTR, Warhammer, Game of Thrones, Forgotten Realms or similar context. Make it look casual, not too long.";
    default:
      return `Use ${exampleContext} context.`;
  }
};

const promptContext = () => {
  const result = window.prompt("Enter context");
  if (!result) return null;
  saveLastContext(result);
  return result;
};

const getLastContexts = () => {
  const lastContexts = localStorage.getItem("lastContexts");
  if (!lastContexts) return [];
  return JSON.parse(lastContexts);
};

const saveLastContext = (context: string) => {
  let lastContexts = getLastContexts();
  const index = lastContexts.indexOf(context);
  if (index !== -1) {
    lastContexts.splice(index, 1);
  }
  lastContexts.unshift(context);
  lastContexts = lastContexts.slice(0, 5);
  localStorage.setItem("lastContexts", JSON.stringify(lastContexts));
};

export const ExampleGenerator: FC<ExampleGeneratorProps> = (props) => {
  const { flashcard, onSelectExample, workInBackground, onClose } = props;

  const [requirement, setRequirement] = useState<
    ExampleContext | string | number
  >(0);

  const [examples, loading, reload] = useExamplesQuery(
    flashcard,
    getExampleContextText(requirement)
  );

  const [specExamples, , specialReload] = useExamplesSpecialQuery(flashcard);

  useEffect(() => {
    if (requirement) {
      reload();
    }
  }, [requirement]);

  useEffect(() => {
    if (!workInBackground && flashcard.description) {
      specialReload();
    }
  }, [workInBackground]);

  const [reqModalOpen, setReqModalOpen] = useState(false);
  const openReqModal = () => setReqModalOpen(true);
  const closeReqModal = () => setReqModalOpen(false);

  if (workInBackground) {
    return null;
  }

  const renderExample = (specialExample: boolean) => (example: string) => {
    return (
      <Button
        key={example}
        className={specialExample ? "special-example-option" : "example-option"}
        onClick={(e) => {
          if ((e?.target as any)?.classList.contains("read-out-button")) {
            return;
          }
          onSelectExample(example);
          onClose();
        }}
      >
        <span className="option-text">
          {example} <ReadOut text={example} />
        </span>
      </Button>
    );
  };

  const renderContext = (req: any) => (
    <Button
      key={String(req)}
      className="req-option"
      onClick={() => {
        const requirement =
          req === AnyContext ? promptContext() : req || Math.random();
        if (!requirement) return;
        setRequirement(requirement);
        closeReqModal();
      }}
    >
      <span className="option-text">{req || "Normal"}</span>
    </Button>
  );

  return (
    <>
      <Modal level="2" width={800} onClickOutside={onClose}>
        <Content>
          <ModalFlashcardHeading flashcard={flashcard} />

          {exampleContexts.includes(requirement as ExampleContext) && (
            <p>
              <i>{requirement}</i>
            </p>
          )}

          {specExamples && <List>{specExamples.map(renderExample(true))}</List>}
          {loading && <RainbowLoader />}
          {examples && <List>{examples.map(renderExample(false))}</List>}
          <div className="buttons">
            <Button className="reload" onClick={openReqModal}>
              Generate other
            </Button>
            <Button className="cancel" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </Content>
      </Modal>
      {reqModalOpen && (
        <Modal level="3" width={600} onClickOutside={closeReqModal}>
          <Content>
            <ContextList>
              {[null, ...exampleContexts, AnyContext].map(renderContext)}
            </ContextList>
            <ContextList custom>
              {[...getLastContexts()].map(renderContext)}
            </ContextList>

            <div className="buttons">
              <Button className="cancel" onClick={closeReqModal}>
                Cancel
              </Button>
            </div>
          </Content>
        </Modal>
      )}
    </>
  );
};
