export enum Source {
  Mobile = "Mobile",
  Desktop = "Desktop",
}

export function getSource(): Source {
  return isMobileDevice() ? Source.Mobile : Source.Desktop;
}

export function isMobileDevice() {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  // Check for mobile devices
  if (/windows phone/i.test(userAgent)) {
    return true;
  }
  if (/android/i.test(userAgent)) {
    return true;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return true;
  }

  return false;
}
