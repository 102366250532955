import { Source } from "./util/source";

export interface FlashcardDto {
  _id: string;
  _idListSuffix?: string;
  engText: string;
  polText: string;
  description: string;
  polDescription: string;
  extraInfo: string;
  category: string;
  context: string;
  createTime: number;
  updateTime?: number;
  proficiency: number;
  flaws: number;
  lastAnswerFlaw?: boolean;
  isFavourite: boolean;
  source?: Source;
  insightLevel?: 0 | 1 | 2;
  ring?: number;
  cache?: boolean;
}

export type FlashcardCreateDto = Omit<FlashcardDto, "_id" | "createTime"> & {
  _id?: string;
  newFlashcardCollections?: string[];
};

export type CollectionDto = {
  _id: string;
  name: string;
  color: string;
  order?: number;
  icon?: string;
  items: string[];
};

export type StoryDto = {
  _id: string;
  text: string;
  flashcardIds: string[];
};

export type TopicDto = {
  _id: string;
  text: string;
  status: SpeakStatus;
  ids: string[];
  imageUrl?: string;
  usefulExpressions?: string[];
};

export type SpeakStatus = "new" | "seen";

export type BabbleDto = {
  _id: string;
  text: string;
  status: SpeakStatus;
  source?: "MANUAL" | "AI";
  favourite: boolean;
};

export type InsightDto = {
  _id: string;
  flashcardId: string;
  text: string;
  inquired: boolean;
  // meta
  createTime?: number;
  updateTime?: number;
  promptVersion?: number;
};

export type QuizQuestionTopic =
  | "prepositions"
  | "gerunds"
  | "articles"
  | "varia"
  | "live";

export const quizTopics: QuizQuestionTopic[] = [
  "prepositions",
  "gerunds",
  "articles",
  "varia",
];

export type AnswerResult = "correct" | "incorrect";

export interface QuizQuestionDto {
  _id: string;
  text: string;
  status: "new" | "live";
  setId: string | null;
  topic: QuizQuestionTopic;
  isFavourite: boolean;

  askedCount: number;
  mistakesCount: number;
  lastAsked?: number;
  lastAskedResult?: AnswerResult;
}

export interface ExplanationDto {
  _id: string;
  text: string;
  questionId: string;
  topic: QuizQuestionTopic;
}

export type TranslationDto = {
  engText: string;
  polText: string;
};

export type TranslationType = "eng-to-pol" | "pol-to-eng";

export type Side =
  | "eng"
  | "pol"
  | "description"
  | "dual"
  | "dynamic"
  | "grammar";

export type Completion = "incomplete" | "complete" | "challenge";
