let promptedToken = false;

export const getToken = (force?: boolean) => {
  const saved = localStorage.getItem("token");
  if (!saved || force) {
    const token = prompt("Enter token");
    if (token) {
      localStorage.setItem("token", token);
      return token;
    } else {
      throw new Error("No token provided");
    }
  }
  return saved;
};

export const onResponse = (res: Response) => {
  if (res.status === 403) {
    if (!promptedToken) {
      promptedToken = true;
      getToken(true);
    }
    throw new Error("Try providing the token again");
  }
  return res.json();
};
