import styled from "styled-components";
import { usePersistedState } from "../../util/react";
import { Radio } from "../Radio";
import { AudioPlayerMode } from "../../util/audioPlayer";
import { scaleOnHover } from "../Style";
import { useContext } from "react";
import { AppContext } from "../AppContext";
import { Algorithm } from "../../util/algorithm";

const StyledPlayControl = styled.div`
  position: fixed;
  top: 70px;
  left: 15px;
  border-radius: 30px;
  border: 1px solid lightgray;
  padding: 5px 10px;
  background: white;
  align-items: center;

  display: flex;
  gap: 20px;

  button {
    all: unset;
    cursor: pointer;
    font-size: 1.5em;
    ${scaleOnHover()}
  }
`;

const playModeOptions = [
  { label: "🌐", value: "discover" },
  { label: "🗣", value: "repeat" },
];

const useQuickSet = () => {
  const { setAlgorithm, controlsState } = useContext(AppContext);
  const setContexts = controlsState.contextsState[1];

  const onQuickSet = () => {
    setAlgorithm(Algorithm.ALGORITHM_2);
    setContexts([]);
  };

  return onQuickSet;
};

export const PlayControl = () => {
  const [playMode, setPlayMode] = usePersistedState<AudioPlayerMode>(
    "audio-player-mode",
    "discover"
  );

  const onQuickSet = useQuickSet();

  return (
    <StyledPlayControl>
      <Radio
        name="play-mode"
        value={playMode}
        options={playModeOptions}
        onChange={(mode) => setPlayMode(mode as AudioPlayerMode)}
        layout="horizontal"
      />
      <button onClick={onQuickSet}>🦄</button>
    </StyledPlayControl>
  );
};
