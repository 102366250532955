import React, { FC, useEffect } from "react";
import { ControlsNavigation } from "../components/ControlsNavigation";
import QuizPrepositions from "../components/Quizes/variants/QuizPrepositions";
import {
  QUIZ_PATHS,
  QuizNavigation,
} from "../components/Quizes/QuizNavigation";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { QuizLastSeen } from "../components/Quizes/utils/quiz";
import { QuizConfig } from "../components/Quizes/QuizConfig";
import QuizGerunds from "../components/Quizes/variants/QuizGerunds";
import QuizArticles from "../components/Quizes/variants/QuizArticles";
import QuizMix from "../components/Quizes/variants/QuizMix";
import QuizVaria from "../components/Quizes/variants/QuizVaria";
import QuizLive from "../components/Quizes/variants/QuizLive";

export const QuizPage: FC = () => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === "/quiz") {
      switch (QuizLastSeen.get()) {
        case null:
        case "prepositions":
          navigate(QUIZ_PATHS.PREPOSITIONS);
          break;

        case "gerunds":
          navigate(QUIZ_PATHS.GERUND);
          break;
        case "gerunds/config":
          navigate(QUIZ_PATHS.GERUND_CONFIG);
          break;
        case "articles":
          navigate(QUIZ_PATHS.ARTICLES);
          break;
        case "articles/config":
          navigate(QUIZ_PATHS.ARTICLES_CONFIG);
          break;
        case "varia":
          navigate(QUIZ_PATHS.VARIA);
          break;
        case "varia/config":
          navigate(QUIZ_PATHS.VARIA_CONFIG);
          break;
        case "live":
          navigate(QUIZ_PATHS.LIVE);
          break;
        case "mix":
          navigate(QUIZ_PATHS.MIX);
          break;
      }
    }
  }, []);

  return (
    <div>
      <QuizNavigation />
      {pathname === QUIZ_PATHS.PREPOSITIONS && <QuizPrepositions />}
      {pathname === QUIZ_PATHS.PREPOSITIONS_CONFIG && (
        <QuizConfig topic={"prepositions"} />
      )}

      {pathname === QUIZ_PATHS.GERUND && <QuizGerunds />}
      {pathname === QUIZ_PATHS.GERUND_CONFIG && (
        <QuizConfig topic={"gerunds"} />
      )}

      {pathname === QUIZ_PATHS.ARTICLES && <QuizArticles />}
      {pathname === QUIZ_PATHS.ARTICLES_CONFIG && (
        <QuizConfig topic="articles" />
      )}

      {pathname === QUIZ_PATHS.VARIA && <QuizVaria />}
      {pathname === QUIZ_PATHS.VARIA_CONFIG && <QuizConfig topic="varia" />}

      {pathname === QUIZ_PATHS.LIVE && <QuizLive />}

      {pathname === QUIZ_PATHS.MIX && <QuizMix />}

      <ControlsNavigation active={"quiz"} />
    </div>
  );
};
