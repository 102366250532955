import { FC, useState } from "react";
import styled from "styled-components";
import { favouriteColor } from "./Controls/consts";
import { FaBell } from "react-icons/fa";
import { getRingColor, RING_ACTIVE_THRESHOLD } from "../util/ring";
import { useSpeakThreshold } from "./Flashcard/hooks";

const ProficiencyBox = styled.div`
  display: flex;
  align-items: flex-end;

  gap: 15px;
`;

const ProficiencyButton = styled.button<{
  visibility?: boolean;
  negative?: boolean;
  positive?: boolean;
}>`
  all: unset;
  cursor: pointer;
  padding: 5px 15px;
  background: ${(props) => {
    if (props.positive) {
      return "#78EB93";
    }
    if (props.negative) {
      return "#F47861";
    }
    return "#aaaaaa25;";
  }};
`;

const FavouriteButton = styled(ProficiencyButton)<{ isFavourite: boolean }>`
  color: ${(props) => (props.isFavourite ? favouriteColor : "#2F2F2F")};
`;

const RingButton = styled(ProficiencyButton)<{ ring: number }>`
  position: relative;
  opacity: ${(props) => {
    if (props.ring <= RING_ACTIVE_THRESHOLD) {
      return 0.3;
    }
    if (props.ring < 10) {
      return 0.8;
    }
    return 1;
  }};
`;

const RingSpeakSymbol = styled.div`
  pointer-events: none;
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-size: 0.8em;
`;

const ProficiencyText = styled.span<{ isNegative: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span.flaws {
    font-size: 0.6em;
    color: #a83030;
  }
`;

interface ProficiencyControlProps {
  proficiency: number;
  onChangeProficiency: (newProficiency: number) => void;
  isFavourite: boolean;
  ring: number;
  onChangeFavourite: (newIsFavourite: boolean) => void;
  onRing: () => void;
  complete: boolean;
  flaws: number | null;
}

export const ProficiencyControl: FC<ProficiencyControlProps> = (props) => {
  const {
    proficiency,
    isFavourite,
    complete,
    onChangeProficiency,
    onChangeFavourite,
    flaws,
    ring,
    onRing,
  } = props;
  const [changeProficiency, setChangeProficiency] = useState(0);

  const increaseProficiency = () => {
    setChangeProficiency((prev) => prev + 1);
    onChangeProficiency(proficiency + 1);
  };

  const decreaseProficiency = () => {
    setChangeProficiency((prev) => prev - 1);
    onChangeProficiency(proficiency - 1);
  };

  const toggleFavourite = () => {
    onChangeFavourite(!isFavourite);
  };

  const ringFavourite = true;

  const speakThreshold = useSpeakThreshold();

  return (
    <ProficiencyBox>
      {ringFavourite ? (
        <RingButton
          onClick={(e) => {
            e.stopPropagation();
            onRing();
          }}
          ring={ring}
          type="button"
          title={ring === 0 ? "Ring" : `Ring ${ring}`}
        >
          <FaBell color={getRingColor(ring)} />
          {ring >= speakThreshold && <RingSpeakSymbol>🗣️</RingSpeakSymbol>}
        </RingButton>
      ) : (
        <FavouriteButton
          onClick={(e) => {
            e.stopPropagation();
            toggleFavourite();
          }}
          isFavourite={isFavourite}
          type="button"
        >
          ★
        </FavouriteButton>
      )}

      {complete && (
        <>
          <ProficiencyButton
            onClick={(e) => {
              e.stopPropagation();
              decreaseProficiency();
            }}
            type="button"
            negative={changeProficiency < 0}
          >
            -
          </ProficiencyButton>
          <ProficiencyText isNegative={proficiency < 0}>
            <span>{proficiency}</span>{" "}
            {flaws !== null && <span className="flaws">{flaws}</span>}
          </ProficiencyText>
          <ProficiencyButton
            onClick={(e) => {
              e.stopPropagation();
              increaseProficiency();
            }}
            type="button"
            positive={changeProficiency > 0}
          >
            +
          </ProficiencyButton>
        </>
      )}
    </ProficiencyBox>
  );
};
