import { FC, useContext, useState } from "react";
import { FlashcardDto, StoryDto } from "../types";
import styled, { css } from "styled-components";
import { useDeleteStory } from "../util/queries";
import { AppContext } from "./AppContext";
import { FlashcardList } from "./FlashcardList";
import { playStory } from "../util/audioPlayer";
import { PersistedState, usePersistedState } from "../util/react";
import { getFlashcardElement } from "../util/flashcards";

function extractIds(html: string): string[] {
  const idPattern = /data-id="([^"]*)"/g;
  let match;
  const ids = [];
  while ((match = idPattern.exec(html)) !== null) {
    ids.push(match[1]);
  }
  return ids;
}

const StyledStory = styled.div<{ isShown: boolean; isPlaying: boolean }>`
  margin: 10px;
  margin-bottom: 20px;
  border: 2px solid gray;
  position: relative;

  nav {
    position: sticky;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: #dcdcdc;
    cursor: pointer;

    ${(props) =>
      props.isShown &&
      css`
        background-color: lightblue;
      `}
  }
}

h1 {
  margin: 0;
  font-size: 1em;
}

b {
  cursor: pointer;
}

${(props) =>
  props.isPlaying &&
  css`
    border: 2px solid blue;
  `}

}

.content {
  margin: 0 auto;
  padding: 10px 20px;
  margin-bottom: 10px;
  max-width: 800px;
  line-height: 1.5;
  font-size: 1.2em;
}
`;

const StoryButtons = styled.div`
  width: 100%;
  display: flex;
  max-width: 600px;
  justify-content: space-evenly;
  margin: 0 auto;

  button {
    all: unset;
    padding: 15px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

interface StoryListProps {
  stories: StoryDto[];
}

export const StoryList: FC<StoryListProps> = (props) => {
  const { stories } = props;

  const { flashcards } = useContext(AppContext);
  const getUsedFlashcards = (story: StoryDto) => {
    const storyFlashcardIds = extractIds(story.text);
    return flashcards.filter((f) => storyFlashcardIds.includes(f._id));
  };

  const [deleteStory] = useDeleteStory();
  const onDeleteStory = (id: string) => {
    if (window.confirm("Czy na pewno usunąć?")) {
      deleteStory(id);
    }
  };

  const shownStoryState = usePersistedState<string | null>("shownStory", null);

  return (
    <div>
      {stories.map((story) => (
        <Story
          key={story._id}
          story={story}
          shownStoryState={shownStoryState}
          getUsedFlashcards={getUsedFlashcards}
          onDelete={onDeleteStory}
        />
      ))}
    </div>
  );
};

interface StoryProps {
  story: StoryDto;
  shownStoryState: PersistedState<string | null>;

  getUsedFlashcards(story: StoryDto): FlashcardDto[];

  onDelete(id: string): void;
}

export const Story: FC<StoryProps> = (props) => {
  const { story, shownStoryState, getUsedFlashcards, onDelete } = props;

  const [shownStory, setShownStory] = shownStoryState;
  const toggleShownStory = () => {
    if (shownStory === story._id) {
      setShownStory(null);
    } else {
      setShownStory(story._id);
    }
  };
  const [showFlashcards, setShowFlashcards] = useState(false);
  const [highlightedFlashcardId, setHighlightedFlashcardId] = useState<
    string | null
  >(null);

  const isPlaying = false;

  const isShown = shownStory === story._id;

  return (
    <StyledStory
      key={story._id}
      isPlaying={isPlaying}
      isShown={isShown}
      onClick={(e) => {
        // if clicked on <b> element
        if (e.target instanceof HTMLElement && e.target.tagName === "B") {
          const flashcardId = e.target.getAttribute("data-id");
          setShowFlashcards(true);
          getFlashcardElement(flashcardId)?.scrollIntoView({
            behavior: "auto",
            block: "center",
          });
          setHighlightedFlashcardId(flashcardId);
          setTimeout(() => {
            if (highlightedFlashcardId === flashcardId) {
              setHighlightedFlashcardId(null);
            }
          }, 5000);
        }

        if (e.target instanceof HTMLElement && e.target.tagName === "H1") {
          setShownStory(null);
        }
      }}
    >
      <nav onClick={toggleShownStory}>
        <StoryButtons>
          <button
            onClick={(e) => {
              e.stopPropagation();
              playStory(story);
            }}
            className="play"
          >
            🔈
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onDelete(story._id);
            }}
            className="delete"
          >
            🗑
          </button>
          <button
            onClick={(e) => {
              if (!isShown) {
                return;
              }
              e.stopPropagation();
              setShowFlashcards(!showFlashcards);
            }}
            className="show-flashcards"
            style={{ visibility: isShown ? "visible" : "hidden" }}
          >
            {showFlashcards ? "Ukryj fiszki" : "Pokaż fiszki"}
          </button>
        </StoryButtons>
      </nav>

      <div
        className="content"
        dangerouslySetInnerHTML={{
          __html: isShown ? story.text : story.text.slice(0, 100) + "...",
        }}
        onClick={() => setShownStory(story._id)}
      />

      {showFlashcards && isShown && (
        <FlashcardList
          flashcards={getUsedFlashcards(story)}
          startSide="eng"
          padding="none"
          highlightedId={highlightedFlashcardId}
        />
      )}
    </StyledStory>
  );
};
