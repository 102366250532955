export function insertItemAtIndex<T>(array: T[], item: T, index: number): T[] {
  if (index < 0 || index > array.length) {
    throw new Error("Index out of range");
  }

  // Create a new array to hold the result
  const resultArray = [...array];

  // Use the splice method to insert the item at the specified index
  resultArray.splice(index, 0, item);

  return resultArray;
}

export const uniqueArray = <T>(arr: T[]) => {
  return arr.reduce<T[]>((acc, item) => {
    if (!acc.includes(item)) {
      acc.push(item);
    }
    return acc;
  }, []);
}; // get x random array elements
export function shuffleArray<T>(array: T[]): T[] {
  const _array = array.slice(0);
  for (let i = _array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [_array[i], _array[j]] = [_array[j], _array[i]];
  }
  return _array;
}

export function mergeArrays<T>(...arrays: T[][]): T[] {
  const result: T[] = [];
  let longestArrayLength = Math.max(...arrays.map((a) => a.length));

  for (let i = 0; i < longestArrayLength; i++) {
    for (const array of arrays) {
      if (i < array.length) {
        result.push(array[i]);
      }
    }
  }

  return result;
}

export function getRandomElements<T>(array: T[], numberOfItems: number): T[] {
  const shuffledArray = shuffleArray(array);
  return shuffledArray.slice(0, numberOfItems);
}

export function getRandomElement<T>(array: T[]): T {
  return getRandomElements(array, 1)[0];
}

export function bisectArray<T>(array: T[], index?: number): [T[], T[]] {
  const bisectIndex = index ?? Math.floor(array.length / 2);
  const left = array.slice(0, bisectIndex);
  const right = array.slice(bisectIndex);
  return [left, right];
}

export function arrayOf<T>(count: number, item: T): T[] {
  return new Array<T>(count).fill(item);
}

export function mergeAndDistributeProportionally<T>(
  arr1: T[],
  arr2: T[],
  arr3: T[]
): T[] {
  // Calculate the total number of items
  const totalItems = arr1.length + arr2.length + arr3.length;
  const result: T[] = [];
  let idx1 = 0,
    idx2 = 0,
    idx3 = 0;

  // Calculate the frequency of distribution for each array
  const freq1 = arr1.length / totalItems;
  const freq2 = arr2.length / totalItems;
  const freq3 = arr3.length / totalItems;

  // Counters to keep track of the number of items added from each array
  let count1 = 0,
    count2 = 0,
    count3 = 0;

  // Keep track of the ideal number of items to be added from each array
  let targetCount1 = freq1,
    targetCount2 = freq2,
    targetCount3 = freq3;

  for (let i = 0; i < totalItems; i++) {
    if (count1 < targetCount1 && idx1 < arr1.length) {
      result.push(arr1[idx1++]);
      count1++;
    }
    if (count2 < targetCount2 && idx2 < arr2.length) {
      result.push(arr2[idx2++]);
      count2++;
    }
    if (count3 < targetCount3 && idx3 < arr3.length) {
      result.push(arr3[idx3++]);
      count3++;
    }

    // Update target counters based on frequency
    targetCount1 += freq1;
    targetCount2 += freq2;
    targetCount3 += freq3;
  }

  return result;
}
