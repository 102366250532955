import { useState } from "react";

export const useExtraExample = (
  descriptionText: string,
  clearDescription: () => void
) => {
  const [firstExample, setFirstExample] = useState("");
  const onExtraExample = () => {
    setFirstExample(descriptionText);
    clearDescription();
  };

  return {
    firstExample,
    onExtraExample,
  };
};
