import { FC, useContext } from "react";
import { AppContext } from "../components/AppContext";
import { CollectionDto } from "../types";
import { ControlsNavigation } from "../components/ControlsNavigation";
import { ConfigNavigation } from "../components/ConfigNavigation";
import { Container } from "../components/Style";
import {
  useCreateCollection,
  useDeleteCollection,
  useUpdateCollection,
} from "../util/queries";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { NamedCollection } from "../util/collections";

const CollectionForm = styled.form<{ isDirty: boolean }>`
  display: grid;
  grid-template-columns: 2fr repeat(7, 1fr);

  @media (max-width: 600px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(4, 1fr);
  }

  grid-gap: 1rem;
  padding: 1rem;
  margin: 1rem 0;

  background: honeydew;

  ${({ isDirty }) => isDirty && "background: lightyellow;"}
  input,
    span {
    margin: 0 auto;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    padding: 5px 10px;
  }

  input[type="color"] {
    padding: 0;
  }
`;

const NewButton = styled.button`
  display: block;
  margin: 40px auto 20px;
  width: 100%;
  padding: 20px 10px;
  cursor: pointer;
  background: honeydew;
  border: 1px solid lightgrey;
  font-weight: bold;
`;

export const CollectionConfigPage: FC = () => {
  const { collections } = useContext(AppContext);
  localStorage.setItem("last-seen-config", "collection");

  const [createCollection] = useCreateCollection();

  return (
    <Container>
      <ConfigNavigation />
      {collections.map((collection) => (
        <Collection collection={collection} key={collection._id} />
      ))}
      <NewButton
        type="button"
        onClick={() => {
          const name = prompt("Nazwa kolekcji");
          if (name) {
            createCollection(name);
          }
        }}
      >
        Dodaj kolekcję
      </NewButton>
      <ControlsNavigation active={"config"} />
    </Container>
  );
};

interface CollectionProps {
  collection: CollectionDto;
}

export const Collection: FC<CollectionProps> = (props) => {
  const { collection } = props;
  const [updateCollection] = useUpdateCollection();
  const [deleteCollection] = useDeleteCollection();

  const isNamedCollection = (collection: CollectionDto) => {
    return Object.values(NamedCollection).includes(
      collection.name as NamedCollection
    );
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm<CollectionDto>({
    defaultValues: collection,
  });

  const color = watch("color");

  return (
    <CollectionForm
      onSubmit={handleSubmit(async (values) => {
        await updateCollection(values);
        alert("Saved");
      })}
      isDirty={isDirty}
    >
      <input {...register("name")} />
      <input type="number" {...register("order")} />
      <input
        type="color"
        {...register("color")}
        onChange={(e) => setValue("color", e.target.value)}
      />
      <input
        type="text"
        {...register("color")}
        onChange={(e) => setValue("color", e.target.value)}
      />
      <input type="text" {...register("icon")} style={{ color }} />
      <span>{collection.items.length}</span>
      <button type="submit">Save</button>
      {isNamedCollection(collection) ? (
        <div />
      ) : (
        <button
          type="button"
          onClick={(e) => {
            if (
              (window as any).confirm(`Na pewno usunąć ${collection.name}?`)
            ) {
              deleteCollection(collection._id);
            }
          }}
        >
          🗑️
        </button>
      )}
    </CollectionForm>
  );
};
