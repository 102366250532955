import { getPersistedValue, PersistedKey } from "./react";

export function getUrlState(key: string, isArray: boolean) {
  const params = new URLSearchParams(window.location.search);
  const result = params.get(key);
  if (result) {
    return isArray ? JSON.stringify(result.split(",")) : result;
  }
  return null;
}

const URL_PARAMS: PersistedKey[] = [
  "currentCollectionName",
  "completion",
  "contexts",
  "algorithm",
  "algorithm-picker",
];

const buildUrl = (otherParams: PersistedKey[] = []) => {
  const included: PersistedKey[] = [...URL_PARAMS, ...otherParams];

  const params = new URLSearchParams();

  included.forEach((key) => {
    const value = getPersistedValue(key);
    const wantParam = window.confirm(`Include ${key} : ${value}?`);
    if (value && wantParam) {
      params.set(key, value);
    }
  });
  return window.origin + "?" + params.toString();
};

(window as any).urlGen = () => console.log(buildUrl());
console.log("urlGen() - for url gen");
