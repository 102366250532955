import { usePersistedState } from "./react";
import { message } from "./animation";

interface SaveOffline {
  engText: string;
  context: string;
  id: string;
}

export const useSaveOffline = () => {
  const [savedOffline, setSavedOffline] = usePersistedState<SaveOffline[]>(
    "save-offline",
    []
  );

  const saveOffline = async (saved: Omit<SaveOffline, "id">) => {
    const newSaved = { ...saved, id: Math.random().toString() };
    setSavedOffline([...savedOffline, newSaved]);
    await message("Saved offline!", 500, "teal");
  };

  const popSavedOffline = (id: string) => {
    const found = savedOffline.find((save) => save.id === id);
    setSavedOffline(savedOffline.filter((save) => save.id !== id));
    return found;
  };

  return { savedOffline, saveOffline, popSavedOffline };
};
