import { FC } from "react";
import styled from "styled-components";
import { Button } from "../Button";

const StyledProgressIndicator = styled(Button)`
  font-size: 0.9em;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  opacity: 0.7;

  div {
    width: 100%;
    height: 1px;
    background: black;
  }
`;

interface ProgressIndicatorProps {
  current?: number;
  max: number;
  marked?: number;
  loading?: boolean;
  onClick: () => void;
  onRightClick?: () => void;
}

export const ProgressIndicator: FC<ProgressIndicatorProps> = (props) => {
  const max = props.max;
  const current =
    props.current === undefined ? undefined : Math.min(props.current, max);

  if (props.loading) {
    return (
      <StyledProgressIndicator
        onClick={props.onClick}
        onRightClick={props.onRightClick}
        topStyle
      >
        <span>⌛️</span>
      </StyledProgressIndicator>
    );
  }

  return (
    <StyledProgressIndicator
      onClick={props.onClick}
      onRightClick={props.onRightClick}
      topStyle
    >
      {current !== undefined && (
        <>
          <span>{current}</span>
          <div />
        </>
      )}
      {props.marked ? (
        <span style={{ color: "red" }}>{props.marked}</span>
      ) : (
        <span>{max}</span>
      )}
    </StyledProgressIndicator>
  );
};
