import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { Flashcard } from "./Flashcard/Flashcard";
import styled from "styled-components";
import { FlashcardDto, Side } from "../types";
import { MoreMenu } from "./Flashcard/MoreMenu";

const StyledGrid = styled.div<{
  padding: "big" | "none";
  items: number;
}>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  padding: ${(props) => (props.padding === "big" ? "120px 5px" : "10px")};
`;

interface FlashcardListProps {
  flashcards: FlashcardDto[];
  startSide: Side;
  highlightedId?: string | null;
  startingElement?: ReactNode;
  isMarked?: (id: string) => boolean | undefined;

  onProgress?: (lastVisible: number) => void;

  padding?: "big" | "none";
  carousel?: boolean;

  children?(content: ReactNode): ReactNode;
}

const CHUNK_SIZE = 50;

function useLazyLoad(flashcards: FlashcardDto[], bottomThreshold = 300) {
  const [maxVisible, setMaxVisible] = useState(CHUNK_SIZE);

  useEffect(() => {
    setMaxVisible(CHUNK_SIZE);
  }, [flashcards.length]);

  const loadMore = () => {
    setMaxVisible((value) => value + CHUNK_SIZE);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const nearBottom =
        document.documentElement.offsetHeight - bottomThreshold;

      if (scrollPosition >= nearBottom) {
        loadMore();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return flashcards.slice(0, maxVisible);
}

function useProgressIndicator(
  shownCount: number,
  onProgress?: (lastVisible: number) => void
) {
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const maxScroll = document.documentElement.offsetHeight;
      onProgress?.(Math.round((scrollPosition / maxScroll) * shownCount));
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [shownCount]);
}

export const FlashcardList: FC<FlashcardListProps> = (props) => {
  const {
    flashcards,
    startSide,
    highlightedId,
    onProgress,
    padding = "big",
    isMarked,
    children = (chld) => chld,
  } = props;

  const shownFlashcards = useLazyLoad(flashcards);

  useProgressIndicator(shownFlashcards.length, onProgress);

  const gridRef = useRef<HTMLDivElement>(null);

  const [menuFlashcard, setMenuFlashcard] = useState<FlashcardDto | null>(null);

  return (
    <StyledGrid padding={padding} items={shownFlashcards.length} ref={gridRef}>
      {children(
        <>
          {shownFlashcards.map((flashcard, index) => (
            <Flashcard
              key={flashcard._id + (flashcard._idListSuffix || "")}
              flashcard={flashcard}
              startSide={startSide}
              isHighlighted={flashcard._id === highlightedId}
              isMarked={isMarked?.(flashcard._id)}
              menuStatus={
                !menuFlashcard
                  ? "none"
                  : menuFlashcard?._id === flashcard._id
                  ? "active"
                  : "inactive"
              }
              onMenu={(flashcard) => {
                setMenuFlashcard(flashcard);
              }}
            />
          ))}
        </>
      )}
      {menuFlashcard && (
        <MoreMenu
          flashcard={menuFlashcard}
          onClose={() => setMenuFlashcard(null)}
        />
      )}
    </StyledGrid>
  );
};
