import { useSaveOffline } from "../util/offline";
import { storeTransientFlashcardData } from "../util/flashcards";
import styled from "styled-components";

const Box = styled.div`
  cursor: pointer;
  background: #eeb4c3;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SavedOffline: React.FC<{}> = () => {
  const { savedOffline, popSavedOffline } = useSaveOffline();
  return (
    <>
      {savedOffline.map((saved) => {
        const onClick = () => {
          storeTransientFlashcardData({
            engText: saved.engText,
            context: saved.context,
          });
          popSavedOffline(saved.id);
          window.open("/add");
        };

        return (
          <Box key={saved.id} onClick={onClick}>
            <p>☕</p>
            <b>{saved.engText}</b>
            <p>{saved.context.split(",").join(" ")}</p>
          </Box>
        );
      })}
    </>
  );
};
