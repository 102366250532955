import React, { FC } from "react";
import { useAllStoriesQuery } from "../util/queries";
import { StoryList } from "../components/StoryList";
import { Container } from "../components/Style";
import { ControlsNavigation } from "../components/ControlsNavigation";

export const StoriesPage: FC = () => {
  const [stories = []] = useAllStoriesQuery();

  return (
    <Container>
      <StoryList stories={stories} />
      <ControlsNavigation active={"stories"} />
    </Container>
  );
};
