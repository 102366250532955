import { createLastSeen } from "../../../util/last-seen";
import { QuizQuestionDto, QuizQuestionTopic } from "../../../types";
import { NamedCollection, useCollection } from "../../../util/collections";
import { QUIZ_PATHS } from "../QuizNavigation";
import { capitalizeFirstLetter } from "../../../util/text";
import { useTitle } from "../../../util/title";

export type QuizPage =
  | "prepositions"
  | "prepositions/config"
  | "gerunds"
  | "gerunds/config"
  | "articles"
  | "articles/config"
  | "varia"
  | "varia/config"
  | "live"
  | "live/config"
  | "mix";

export const QuizLastSeen = createLastSeen<QuizPage>("quiz");

export function useQuizPage(quizPage: QuizPage) {
  QuizLastSeen.save(quizPage);

  const [page, config] = quizPage.split("/");
  let title = capitalizeFirstLetter(page);
  if (config) {
    title += " - config";
  }
  useTitle(title);
}

export const isVerified = (question: Pick<QuizQuestionDto, "text">) => {
  return !question.text.endsWith("*");
};

export const hasAnyGaps = (question: Pick<QuizQuestionDto, "text">) => {
  return question.text.includes("((") && question.text.includes("))");
};

export const isDifficult = (question: QuizQuestionDto) => {
  return question.mistakesCount > 0;
};

export const isLastFlawed = (question: QuizQuestionDto) => {
  return question.lastAskedResult === "incorrect";
};

export const isNotAsked = (question: QuizQuestionDto) => {
  return question.askedCount === 0;
};

export const isFavourite = (question: QuizQuestionDto) => {
  return question.isFavourite;
};

export const isAI = (question: Pick<QuizQuestionDto, "setId">) => {
  return !!question.setId;
};

export const getStatusIcon = (question: QuizQuestionDto) => {
  let result = "";

  if (isAI(question)) {
    result += "🤖";
  }

  if (isLastFlawed(question)) {
    return result + "❌️";
  }

  if (isNotAsked(question) && question.status !== "live") {
    return result + "🆕";
  }

  if (isFavourite(question)) {
    return result + "⭐";
  }

  return null;
};

export function useTopicColors() {
  const getCollection = useCollection();
  const getTopicColor = (topic: QuizQuestionTopic) => {
    if (topic === "live") {
      return "violet";
    }

    return getCollection(getTopicCollection(topic))?.color;
  };
  return getTopicColor;
}

export function useTopicColor(topic: QuizQuestionTopic) {
  const getTopicColor = useTopicColors();
  return getTopicColor(topic);
}

export const getTopicCollection = (topic: QuizQuestionTopic) => {
  switch (topic) {
    case "prepositions":
      return NamedCollection.QUIZ_P;
    case "gerunds":
      return NamedCollection.QUIZ_G;
    case "articles":
      return NamedCollection.QUIZ_A;
    case "varia":
      return NamedCollection.QUIZ_V;
    default:
      throw new Error(`Unknown topic: ${topic}`);
  }
};

export const getTopicAbbrev = (topic: QuizQuestionTopic) => {
  return capitalizeFirstLetter(topic.substring(0, 3));
};

export const getTopicPath = (topic: QuizQuestionTopic) => {
  switch (topic) {
    case "prepositions":
      return QUIZ_PATHS.PREPOSITIONS;
    case "gerunds":
      return QUIZ_PATHS.GERUND;
    case "articles":
      return QUIZ_PATHS.ARTICLES;
    case "varia":
      return QUIZ_PATHS.VARIA;
    default:
      throw new Error(`Unknown topic: ${topic}`);
  }
};

export const getTopicConfigPath = (topic: QuizQuestionTopic) => {
  switch (topic) {
    case "prepositions":
      return QUIZ_PATHS.PREPOSITIONS_CONFIG;
    case "gerunds":
      return QUIZ_PATHS.GERUND_CONFIG;
    case "articles":
      return QUIZ_PATHS.ARTICLES_CONFIG;
    case "varia":
      return QUIZ_PATHS.VARIA_CONFIG;
    default:
      throw new Error(`Unknown topic: ${topic}`);
  }
};

export const checkWillAdd = () => {
  return localStorage.getItem("willAdd") ? "new" : null;
};

export const setWillAddQuestion = () => {
  localStorage.setItem("willAdd", "true");
};
export const removeWillAdd = () => {
  localStorage.removeItem("willAdd");
};
