import React, { useContext, useEffect, useState } from "react";
import { useQuizPage } from "../utils/quiz";
import { Quiz } from "../Quiz";
import { AppContext } from "../../AppContext";
import { QuizQuestionDto } from "../../../types";
import { createFlashcardQuizQuestion } from "../../../util/api";
import { RainbowLoader } from "../../RainbowLoader";
import { usePersistedState } from "../../../util/react";
import { Select } from "../../Select";
import styled from "styled-components";
import { defaultLiveMode, liveSettings } from "./liveSettings";
import { metadataVisible } from "../../Flashcard/Metadata";
import { getFlashcardCollections } from "../../../util/collections";
import { isComplete } from "../../../util/flashcards";

type LiveMode = keyof typeof liveSettings;

const Bar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  margin: 10px auto;
  opacity: 0.5;

  label {
    flex: 1;
  }
`;

export default function QuizLive() {
  useQuizPage("live");

  const { flashcards, collections } = useContext(AppContext);
  const [liveMode, setLiveMode] = usePersistedState<LiveMode>(
    "live-mode",
    defaultLiveMode
  );

  // export const getFlashcardCollections = (
  //     flashcardId: string,
  //     collections: CollectionDto[]
  // ) => {
  //   return collections.filter((collection) =>
  //       collection.items.includes(flashcardId)
  //   );
  // };

  const belongsToCollection = (collectionName: string, flashcardId: string) => {
    return getFlashcardCollections(flashcardId, collections).some(
      (col) => col.name === collectionName
    );
  };

  const includedFlashcards = flashcards.filter((f) => {
    return liveSettings[liveMode](f, belongsToCollection) && isComplete(f);
  });
  const [questions, setQuestions] = useState<QuizQuestionDto[]>([]);
  const [round, setRound] = useState(1);

  const getRandomId = () => {
    return includedFlashcards[
      Math.floor(Math.random() * includedFlashcards.length)
    ];
  };

  const hasIncludedFlashcards = !!includedFlashcards.length;

  useEffect(() => {
    if (hasIncludedFlashcards) {
      setQuestions([]);
      setRound(1);
      addNextQuestion();
      addNextQuestion();
    }
  }, [hasIncludedFlashcards, liveMode]);

  const addNextQuestion = () => {
    createFlashcardQuizQuestion(getRandomId()._id).then((question) => {
      setQuestions((currentQuestions) => [...currentQuestions, question]);
    });
  };

  if (!questions.length) {
    return <RainbowLoader />;
  }

  return (
    <>
      <Bar>
        <Select
          value={liveMode}
          options={Object.keys(liveSettings)}
          onChange={(value) => setLiveMode(value as LiveMode)}
        />
        {metadataVisible() && (
          <span>{includedFlashcards.length} flashcards</span>
        )}
      </Bar>
      <Quiz
        type="varia"
        live
        externalQuestions={questions}
        externalRound={round}
        onNextQuestion={() => {
          setRound((currentRound) => currentRound + 1);
          addNextQuestion();
        }}
      />
    </>
  );
}
