import { FC } from "react";
import { Modal } from "../Modal";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Buttons, ModalButton } from "../ModalComponents";
import { BabbleDto } from "../../types";
import { useUpdateBabble } from "../../util/queries";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;

  h3 {
    margin: 0;
    margin-top: 20px;
    text-align: center;
  }

  textarea {
    padding: 20px;
    font-size: 1.2em;
    margin: 0 10px;
  }

  button {
  }
`;

interface RecordEditProps {
  record: string;
  setRecord: (txt: string) => void;
  onClose: () => void;
}

export const RecordEdit: FC<RecordEditProps> = (props) => {
  const { record, setRecord, onClose } = props;
  const { register, handleSubmit } = useForm({ defaultValues: { record } });

  return (
    <Modal onClickOutside={onClose} width={600}>
      <StyledForm
        onSubmit={handleSubmit((data) => {
          setRecord(data.record);
          onClose();
        })}
      >
        <h3>Edit recording</h3>
        <textarea {...register("record")} rows={10} />
        <Buttons>
          <ModalButton onClick={onClose}>Cancel</ModalButton>
          <ModalButton type="submit">Save</ModalButton>
        </Buttons>
      </StyledForm>
    </Modal>
  );
};

interface BabbleEditProps {
  babble: BabbleDto;
  onClose: () => void;
}

export const BabbleEdit: FC<BabbleEditProps> = (props) => {
  const { babble, onClose } = props;
  const { register, handleSubmit } = useForm({ defaultValues: babble });
  const [updateBabble, updateLoading] = useUpdateBabble();

  return (
    <Modal onClickOutside={onClose} width={600}>
      <StyledForm
        onSubmit={handleSubmit(async (data) => {
          await updateBabble(data);
          onClose();
        })}
      >
        <h3>Edit babble</h3>
        <textarea {...register("text")} rows={10} />
        <Buttons>
          <ModalButton onClick={onClose}>Cancel</ModalButton>
          <ModalButton type="submit" disabled={updateLoading}>
            Save
          </ModalButton>
        </Buttons>
      </StyledForm>
    </Modal>
  );
};
