import { createPortal } from "react-dom";
import { FC, PropsWithChildren, ReactNode, useEffect } from "react";
import styled from "styled-components";

const ModalBackground = styled.div<{ level: ModalLevel }>`
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding: 10px;

  background: ${(props) => {
    switch (props.level) {
      default:
        return "rgba(0, 0, 0, 0.2)";
      case "2":
        return "blue";
      case "3":
        return "blueviolet";
      case "4":
        return "#ffef6b";
      case "5":
        return "rgba(136, 8, 8, 0.3)";
    }
  }};
`;

const ModalBox = styled.div<{ width?: number }>`
  background: white;
  border: 1px solid black;
  width: 100%;
  max-width: ${(props) => props.width || 1200}px;
  max-height: 100%;
  box-sizing: border-box;
  overflow: scroll;
  padding: 10px 0;
  @media (min-width: 800px) {
    padding: 60px 20px;
  }
`;

export type ModalLevel = "1" | "1.5" | "2" | "3" | "4" | "5";

interface ModalProps {
  children: ReactNode;
  width?: number;
  level?: ModalLevel;
  onClickOutside?: () => void;
  box?: FC<{ children: ReactNode; width?: number }>;
}

export const Modal: FC<ModalProps> = (props) => {
  const {
    level = "1",
    onClickOutside,
    children,
    width,
    box: Box = ModalBox,
  } = props;

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <ModalPortal level={level}>
      <ModalBackground
        level={level}
        id={`modal-background-${level}`}
        onClick={(e) => {
          e.stopPropagation();
          if (
            e.target === document.getElementById(`modal-background-${level}`)
          ) {
            onClickOutside?.();
          }
        }}
      >
        <Box width={width}>{children}</Box>
      </ModalBackground>
    </ModalPortal>
  );
};

const ModalPortal = ({
  level,
  children,
}: PropsWithChildren<{ level: ModalLevel }>) => {
  return createPortal(
    children,
    document.getElementById(`modal-root-${level}`)!
  );
};
