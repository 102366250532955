import { FC, useContext } from "react";
import { ControlsNavigation } from "../components/ControlsNavigation";
import { ConfigNavigation } from "../components/ConfigNavigation";
import { Container } from "../components/Style";
import { AppContext } from "../components/AppContext";
import { InsightButton } from "../components/Insight";
import styled from "styled-components";
import { format } from "date-fns";

const InsightsTable = styled.table`
  margin: 0 auto;

  tr,
  td {
    border: 1px solid #aaa;
  }

  td {
    padding: 10px;
  }

  .eng-text {
    max-width: 300px;
  }

  .insight-button button {
    font-size: 2em;
  }
`;

export const InsightsConfigPage: FC = () => {
  localStorage.setItem("last-seen-config", "insights");

  const { flashcards } = useContext(AppContext);

  const withInsights = flashcards
    .filter((fl) => (fl.insightLevel || 0) > 0)
    .sort((a, b) => {
      return (b.updateTime || 0) - (a.updateTime || 0);
    });

  return (
    <Container>
      <ConfigNavigation />
      <InsightsTable>
        <tbody>
          {withInsights.map((fl) => {
            return (
              <tr key={fl._id}>
                <td className="eng-text">{fl.engText}</td>
                <td className="eng-text">
                  {fl.updateTime && format(new Date(fl.updateTime), "dd.MM.yy")}
                </td>
                <td className="insight-button">
                  <InsightButton flashcard={fl} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </InsightsTable>
      <ControlsNavigation active={"config"} />
    </Container>
  );
};
