import { FC, useState } from "react";
import {
  useBabblesQuery,
  useCreateBabble,
  useRemoveBabble,
  useUpdateBabble,
} from "../../util/queries";
import { ItemBox } from "./style";
import { usePersistedState } from "../../util/react";
import { useNavigate } from "react-router";
import { BabbleEdit } from "./SpeakEdit";
import { BabbleDto } from "../../types";

export const BabbleConfig: FC = () => {
  const [allBabbles] = useBabblesQuery(false);
  const [removeBabble] = useRemoveBabble();
  const [updateBabble] = useUpdateBabble();
  const [createBabble] = useCreateBabble();

  const navigate = useNavigate();

  const onAddBabble = () => {
    const text = prompt("Enter babble text");
    if (text) {
      createBabble(text);
    }
  };

  const [activeBabbleId, setActiveBabbleId] = usePersistedState(
    "babble-id",
    ""
  );
  const onActivateBabble = (babbleId: string) => {
    setActiveBabbleId(babbleId);
    navigate(`/speak/babble`);
  };

  const [editedBabble, setEditedBabble] = useState<BabbleDto | null>(null);

  return (
    <div>
      <ItemBox variant="babble" status="new">
        <button onClick={onAddBabble}>Add babble</button>
      </ItemBox>
      {allBabbles.map((babble) => (
        <ItemBox key={babble._id} status={babble.status} variant="babble">
          <p>{babble.text}</p>
          <div className="buttons">
            <button onClick={() => removeBabble(babble._id)}>Remove</button>
            {babble.status !== "new" && (
              <button
                onClick={() =>
                  updateBabble({
                    ...babble,
                    status: "new",
                  })
                }
              >
                Unseen
              </button>
            )}
            {activeBabbleId !== babble._id && (
              <button
                onClick={() => {
                  onActivateBabble(babble._id);
                }}
              >
                Activate
              </button>
            )}
            <button
              onClick={() => {
                setEditedBabble(babble);
              }}
            >
              Edit
            </button>
          </div>
        </ItemBox>
      ))}
      {editedBabble && (
        <BabbleEdit
          babble={editedBabble}
          onClose={() => setEditedBabble(null)}
        />
      )}
    </div>
  );
};
