import { getRandomArrayElement } from "./util";
import { ReadOut } from "../ReadOut";
import { Main, MoreButton, SpeakText, SpeakTextButtons } from "./style";
import { usePersistedState } from "../../util/react";
import {
  useBabblesQuery,
  useCreateBabble,
  useUpdateBabble,
} from "../../util/queries";
import { useSelectableText } from "./useSelectableText";
import { useState } from "react";
import { Record } from "./Record";
import { BabbleEdit, RecordEdit } from "./SpeakEdit";
import { AddNewLink } from "../AddNewLink";
import { TranslationLink } from "../TranslationLink";

export const Babble = () => {
  localStorage.setItem("last-seen-speak", "babble");

  //

  const [allBabbles] = useBabblesQuery(true);
  const [updateBabble] = useUpdateBabble();
  const [createBabble] = useCreateBabble();

  const [babbleId, setBabbleId] = usePersistedState("babble-id", "");
  const currentBabble = allBabbles.find((t) => t._id === babbleId);

  const newBabbles = allBabbles.filter(
    (babble) => babble.status === "new" && babble._id !== babbleId
  );

  //

  const speakMore = () => {
    setRecorded("");
    createBabble(undefined);

    if (currentBabble) {
      updateBabble({ ...currentBabble, status: "seen" });
    }

    setBabbleId(getRandomArrayElement(newBabbles)._id);
  };

  const [recorded, setRecorded] = useState("");

  const text = recorded || currentBabble?.text || "";
  const { selectableText, selection, sanitizedSelection } =
    useSelectableText(text);

  const [modal, setModal] = useState(false);
  const closeModal = () => setModal(false);

  const saveRecorded = async () => {
    const created = await createBabble(recorded);
    setRecorded("");
    setBabbleId(created._id);
  };

  return (
    <Main>
      <SpeakText chars={text.length} variant={recorded ? "record" : "babble"}>
        <h2>
          {selectableText}
          <ReadOut
            text={selection || text}
            speakConfig={{
              rate: selection ? 0.8 : 1,
              engAccent: "male-1",
            }}
          />
        </h2>
      </SpeakText>
      <MoreButton onClick={speakMore}>Practice more!</MoreButton>
      <Record
        recorded={recorded}
        setRecorded={setRecorded}
        saveRecorded={saveRecorded}
      />
      <SpeakTextButtons>
        <button onClick={() => setModal(true)}>✐️</button>
        <AddNewLink engText={sanitizedSelection || text} context="SPEAK">
          ✚
        </AddNewLink>

        <TranslationLink text={sanitizedSelection || text} />
      </SpeakTextButtons>
      <div />
      <div />
      {modal &&
        (recorded ? (
          <RecordEdit
            onClose={closeModal}
            record={recorded}
            setRecord={setRecorded}
          />
        ) : (
          <BabbleEdit onClose={closeModal} babble={currentBabble!} />
        ))}
    </Main>
  );
};
