import { Fragment, useEffect, useState } from "react";
import { sanitizeText } from "./util";

export const useSelectableText = (text: string) => {
  const [selectionStart, setSelectionStart] = useState<number | null>(null);
  const [selectionEnd, setSelectionEnd] = useState<number | null>(null);

  const set = (start: number | null, end: number | null) => {
    setSelectionStart(start);
    setSelectionEnd(end);
  };

  useEffect(() => {
    setSelectionStart(null);
    setSelectionEnd(null);
  }, [text]);

  const isSelected = (index: number) => {
    if (selectionStart === null) {
      return false;
    }
    if (selectionStart === index) {
      return true;
    }
    return (
      selectionEnd !== null && selectionEnd >= index && selectionStart <= index
    );
  };

  const pieces = text.split(" ");

  const selectableText = pieces.map((piece, index) => (
    <Fragment key={index}>
      <span
        data-piece={index}
        className={"selectable " + (isSelected(index) ? "selected" : "")}
        onClick={() => {
          if (selectionStart === null) {
            set(index, null);
            return;
          }
          if (selectionStart === index) {
            set(null, null);
            return;
          }
          if (selectionStart > index || selectionEnd !== null) {
            set(index, null);
            return;
          }
          if (selectionStart < index) {
            set(selectionStart, index);
            return;
          }
        }}
      >
        {piece}
      </span>{" "}
    </Fragment>
  ));

  const selection = pieces
    .filter((piece, index) => isSelected(index))
    .join(" ");

  const sanitizedSelection = sanitizeText(selection);

  return { selectableText, selection, sanitizedSelection };
};
