import { FC } from "react";
import { Select } from "../Select";
import { ControlsProps } from "./index";
import { ALL_CONTEXTS, COLLECTION_CONTEXT, FAVOURITES_CONTEXT } from "./consts";
import styled from "styled-components";
import { buttonStyles } from "../Button";

const ContextSelectLabel = styled.label`
  select {
    ${buttonStyles({ topStyle: true, smaller: true })};

    text-align: center;
    font-size: 0.8em;
  }
`;

const ContextSelectLabelSelected = styled(ContextSelectLabel)`
  select {
    border-width: 3px;
    font-weight: bold;
    border-color: skyblue;
  }
`;

export const ContextSelect: FC<ControlsProps> = (props) => {
  const {
    controlsState: { contextsState },
    allContexts,
  } = props;

  const [contexts, setContexts] = contextsState;

  const contextOptions = [ALL_CONTEXTS, ...allContexts];
  const currentOption =
    contexts.filter(
      (ct) => ct !== FAVOURITES_CONTEXT && ct !== COLLECTION_CONTEXT
    )[0] || ALL_CONTEXTS;

  return (
    <Select
      options={contextOptions}
      value={currentOption}
      labelComponent={
        currentOption === ALL_CONTEXTS
          ? ContextSelectLabel
          : ContextSelectLabelSelected
      }
      onChange={(context) => {
        if (context === ALL_CONTEXTS) {
          setContexts(
            contexts.filter(
              (ct) => ct === FAVOURITES_CONTEXT || ct === COLLECTION_CONTEXT
            )
          );
        } else {
          setContexts([
            ...contexts.filter(
              (ct) => ct === FAVOURITES_CONTEXT || ct === COLLECTION_CONTEXT
            ),
            context,
          ]);
        }
      }}
    />
  );
};
