import React, { FC } from "react";
import { ControlsNavigation } from "../components/ControlsNavigation";
import { ConfigNavigation } from "../components/ConfigNavigation";
import { Container } from "../components/Style";
import { BabbleConfig } from "../components/Speak/BabbleConfig";

export const BabbleConfigPage: FC = () => {
  localStorage.setItem("last-seen-config", "babble");

  return (
    <Container>
      <ConfigNavigation />
      <BabbleConfig />
      <ControlsNavigation active="config" />
    </Container>
  );
};
