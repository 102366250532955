import React, { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

export const useAssistantText = (descriptionText: string) => {
  const [assistantText, setAssistantText] = useState("");

  const debouncedSetAssistantText = useDebouncedCallback(
    (text) => setAssistantText(text),
    500
  );

  const [typed, setTyped] = useState(false);

  const handleDescriptionKeyDown = (event: React.KeyboardEvent) => {
    setTyped(true);
  };

  const stopAssistant = () => {
    setAssistantText("");
    debouncedSetAssistantText.cancel();
  };

  useEffect(() => {
    if (typed) {
      if (
        descriptionText.endsWith(".") ||
        descriptionText.endsWith("?") ||
        descriptionText.endsWith("!")
      ) {
        if (assistantText) {
          debouncedSetAssistantText(descriptionText);
        } else {
          setAssistantText(descriptionText);
        }
      } else {
        stopAssistant();
      }
    }
  }, [typed, descriptionText]);

  return {
    assistantText,
    handleDescriptionKeyDown,
    stopAssistant,
  };
};
