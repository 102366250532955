import styled, { css } from "styled-components";
import { SpeakVariant } from "./util";
import { SpeakStatus } from "../../types";
import { RECORDED_COLOR } from "./Record";
import { scaleOnHover } from "../Style";
import { ModalButton } from "../ModalComponents";

export const FullView = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 80px;
`;

export const Main = styled.main<{ img?: string }>`
  max-width: 800px;
  width: 100%;
  box-sizing: border-box;

  font-family: Arial, sans-serif;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  position: relative;

  ${(props) =>
    props.img &&
    css`
      background: url(${props.img}) no-repeat center center;
      background-size: cover;
    `}
`;

export const Img = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  opacity: 0.2;
`;

const getFontSize = (chars: number) => {
  // if (chars > 200) {
  //   return "1em";
  // }
  // if (chars > 150) {
  //   return "1.2em";
  // }
  // if (chars > 100) {
  //   return "1.3em";
  // }
  // return "1.6em";

  if (chars > 200) {
    return "0.8em";
  }
  if (chars > 150) {
    return "0.9em";
  }
  if (chars > 100) {
    return "1em";
  }
  return "1.1em";
};

export const SpeakText = styled.div<{
  chars: number;
  variant: SpeakVariant;
}>`
  h2 {
    font-size: ${(props) => getFontSize(props.chars)};
    text-align: center;
    white-space: pre-wrap;
    line-height: 1.5;
    padding: 10px;
    margin-bottom: 0;
    margin-top: 0;

    ${(props) => {
      switch (props.variant) {
        case "babble":
          return ``;
        case "topic":
          return ``;
        case "record":
          return css`
            color: ${RECORDED_COLOR};
          `;
      }
    }}
  }

  .selectable {
    cursor: pointer;
  }

  .selectable.selected {
    color: #ff6985;
  }

  .useful-expressions {
    color: grey;
    text-align: center;
    margin-top: 5px;
    line-height: 1.5;
    padding: 0 20px;
  }
`;

export const SpeakTextButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  margin: 0 auto;
  background: lightcyan;
  padding: 10px 0;

  button,
  a {
    font-size: 1.4em !important;
    all: unset;
    vertical-align: middle;
    margin: 0 !important;
    cursor: pointer;

    ${scaleOnHover()};
  }
`;

export const MoreButton = styled(ModalButton)`
  margin-bottom: 10px;
  margin-top: 10px;
  max-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  padding: 20px 40px;
`;

export const ItemBox = styled.div<{
  status: SpeakStatus;
  variant: SpeakVariant;
}>`
  margin: 10px 5px;
  border: 1px solid black;
  padding: 10px 5px;
  background-color: ${(props) => {
    const index = props.variant === "topic" ? 0 : 1;
    switch (props.status) {
      case "new":
        return ["honeydew", "lightpink"][index];
      case "seen":
        return ["lightyellow", "hotpink"][index];
      default:
        return "white";
    }
  }};

  .extra {
    color: grey;
  }

  .buttons {
    display: block;

    button {
      margin-right: 10px;
    }
  }
`;

export const SplitView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SplitColumn = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:first-child {
    padding-right: 7px;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
  }
`;
