import { FC, useEffect } from "react";
import { FlashcardDto, Side } from "../../types";
import { useAudioPlayer } from "../../util/audioPlayer";
import { Button } from "../Button";
import { READ_ICON, ReadOutIcon } from "../ReadOut";
import { PlayControl } from "./PlayControl";

export interface PlayAllProps {
  flashcards: FlashcardDto[];
  startSide: Side;
  autoPlay: boolean;
}

export const PlayAll: FC<PlayAllProps> = (props) => {
  const { flashcards, autoPlay } = props;
  const { playMany, isSomethingPlaying, stop } = useAudioPlayer();

  useEffect(() => {
    stop();

    return () => {
      stop();
    };
  }, [flashcards.length]);

  useEffect(() => {
    if (autoPlay) {
      playMany(flashcards);
    }
  }, [autoPlay]);

  return (
    <>
      <Button
        onClick={() => {
          playMany(flashcards);
        }}
        topStyle
        smaller
      >
        <ReadOutIcon isPlaying={isSomethingPlaying()}>{READ_ICON}</ReadOutIcon>
      </Button>
      {isSomethingPlaying() && <PlayControl />}
    </>
  );
};
