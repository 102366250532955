import { FC } from "react";
import {
  useRegenerateTopicImage,
  useRemoveTopic,
  useSpeakTopicsQuery,
  useUpdateTopic,
} from "../../util/queries";
import { ItemBox } from "./style";
import { UsefulExpressions } from "./UsefulExpressions";
import { TopicDto } from "../../types";

export const TopicConfig: FC = () => {
  const [allTopics] = useSpeakTopicsQuery(false);
  const [removeTopic] = useRemoveTopic();
  const [updateTopic] = useUpdateTopic();
  const [regenerateImage] = useRegenerateTopicImage();

  const openTopicImage = (topic: TopicDto) => {
    window.open(topic.imageUrl, "_blank");
  };

  return (
    <div>
      {allTopics.map((topic) => (
        <ItemBox key={topic._id} status={topic.status} variant="topic">
          <p>{topic.text}</p>
          <UsefulExpressions expressions={topic.usefulExpressions} />
          <div className="buttons">
            <button onClick={() => removeTopic(topic._id)}>Remove</button>
            {topic.imageUrl && (
              <button onClick={() => openTopicImage(topic)}>🌁</button>
            )}
            <button onClick={() => regenerateImage(topic)}>⟲</button>
            {topic.status !== "new" && (
              <button
                onClick={() =>
                  updateTopic({
                    ...topic,
                    status: "new",
                  })
                }
              >
                Unseen
              </button>
            )}
          </div>
        </ItemBox>
      ))}
    </div>
  );
};
