import { FC, useEffect, useState } from "react";
import { Modal } from "../../Modal";
import { Buttons, ModalButton } from "../../ModalComponents";
import { parseText } from "../../../util/text";
import { ExplanationDto, QuizQuestionDto } from "../../../types";
import { useQuery } from "react-query";
import styled from "styled-components";
import { RainbowLoader } from "../../RainbowLoader";
import { InsightContent } from "../../Insight";
import { FormattedGaps } from "./FormattedGaps";
import { useInquireAboutExplanation } from "../../../util/queries";
import { InquiryForm } from "../../InquiryForm";
import { useTopicColor } from "../utils/quiz";

const Loader = styled.div`
  text-align: center;
  margin: 20px 0;
  font-size: 1.2em;
`;

const OriginalText = styled(FormattedGaps)`
  font-size: 1.2em;
  font-weight: bold;
  padding: 10px 20px;
  margin: 0;

  span {
    color: blue;
  }
`;

interface ExplanationModalProps {
  closeModal: () => void;
  explainFn: (id: string, regenerate?: boolean) => Promise<ExplanationDto>;
  question: QuizQuestionDto;
}

export const ExplanationModal: FC<ExplanationModalProps> = (props) => {
  const { question, explainFn, closeModal } = props;
  const [regenerate, setRegenerate] = useState(false);

  const {
    data: explanation,
    isLoading: explanationLoading,
    refetch: load,
    isRefetching,
  } = useQuery(
    `explanation-${question._id}`,
    () => explainFn(question._id, regenerate),
    {
      enabled: false,
    }
  );

  useEffect(() => {
    (window as any).insightOpen = true;
    return () => {
      (window as any).insightOpen = false;
    };
  }, []);

  useEffect(() => {
    load();

    if (regenerate) {
      setRegenerate(false);
    }
  }, [regenerate]);

  const [inquire, inquireLoading] = useInquireAboutExplanation(question._id);

  const anythingLoading = explanationLoading || inquireLoading;

  const topicColor = useTopicColor(question.topic);

  const loader = (label: string) => (
    <Loader>
      <p>{label}...</p>
      <RainbowLoader />
    </Loader>
  );

  return (
    <Modal width={600} onClickOutside={closeModal}>
      <OriginalText text={question.text} />

      {(explanationLoading || isRefetching) &&
        loader("Generating the explanation")}

      {explanation && (
        <InsightContent>
          <div
            dangerouslySetInnerHTML={{
              __html: parseText(explanation.text || ""),
            }}
          />
        </InsightContent>
      )}

      {!anythingLoading && <InquiryForm onInquire={inquire} />}
      {inquireLoading && loader("Inquiring")}

      <Buttons>
        {explanation && (
          <ModalButton
            type="button"
            onClick={() => setRegenerate(true)}
            magicColor={topicColor}
          >
            Regenerate
          </ModalButton>
        )}
        <ModalButton type="button" onClick={closeModal} magicColor={topicColor}>
          Close
        </ModalButton>
      </Buttons>
    </Modal>
  );
};
