import { QuizQuestionDto, QuizQuestionTopic } from "../../types";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useCreateQuizQuestion,
  useUpdateQuizQuestion,
} from "../../util/queries";
import { Modal } from "../Modal";
import { Buttons, ModalButton } from "../ModalComponents";
import styled from "styled-components";
import { getTranslationUrl } from "../TranslationLink";
import { gapAnswerWithFirstAnswers } from "./utils/format";
import { FormattedGaps } from "./components/FormattedGaps";
import Switch from "react-switch";
import { message } from "../../util/animation";
import { favouriteColor, FAVOURITES_CONTEXT } from "../Controls/consts";
import { isVerified, removeWillAdd, useTopicColor } from "./utils/quiz";
import { MagicGenerator } from "./components/MagicGenerator";
import {
  ClickableWordGerund,
  ClickableWords,
  ClickableWordVaria,
} from "./components/ClickableWords";

const StyledForm = styled.form<{ topicColor: string }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 15px;

  h3 {
    margin: 0;
    margin-top: 20px;
    text-align: center;
    color: ${(props) => props.topicColor};
  }

  textarea {
    padding: 20px;
    font-size: 1.2em;
    margin: 0 10px;
  }

  .preview {
    font-size: 1.2em;
  }
`;

export const Switches = styled.div`
  display: flex;
  gap: 20px;

  label {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

const Fav = styled.button<{ active: boolean }>`
  all: unset;
  font-size: 1.5em;
  cursor: pointer;
  ${(props) =>
    props.active
      ? `
      
    color: ${favouriteColor};
    `
      : ``}
`;

interface QuizQuestionModalProps {
  type: QuizQuestionTopic;
  question: QuizQuestionDto | "new";
  newText?: string;
  onClose: () => void;
  onAdd?: () => void;
}

export const QuestionModal: FC<QuizQuestionModalProps> = (props) => {
  removeWillAdd();

  const { question, onClose, onAdd, type, newText } = props;
  const { register, handleSubmit, watch, setValue, reset } = useForm({
    defaultValues: question === "new" ? { text: newText || "" } : question,
  });
  const [updateQuizQuestion, updateLoading] = useUpdateQuizQuestion(type);
  const [createQuizQuestion] = useCreateQuizQuestion(type);

  const topicColor = useTopicColor(type);

  const [inputMode, setInputMode] = useState<"text" | "clickable">(
    newText ? "clickable" : "text"
  );
  const toggleInputMode = () => {
    setInputMode(inputMode === "text" ? "clickable" : "text");
  };

  const [markAsCustom, setMarkAsCustom] = useState(true);
  const toggleMarkAsCustom = () => {
    setMarkAsCustom(!markAsCustom);
  };

  const [addMultiple, setAddMultiple] = useState(false);
  const toggleAddMultiple = () => {
    setAddMultiple(!addMultiple);
  };

  const [sequence, setSequence] = useState(false);
  const toggleSequence = () => {
    setSequence(!sequence);
  };

  const [gerundSubmitted, setGerundSubmitted] = useState(false);

  const onSubmit = handleSubmit(async (data) => {
    if (!data.text.includes("((") && inputMode === "text") {
      setInputMode("clickable");
      return;
    }

    if (
      type === "gerunds" &&
      !data.text.includes("((") &&
      inputMode === "clickable"
    ) {
      setGerundSubmitted(true);
      return;
    }

    if (isAddNew) {
      if (addMultiple) {
        try {
          const texts = parseMultiple(data.text);
          await createQuizQuestion({
            text: texts,
            isFavourite: data.isFavourite,
          });
          await message(`Multiple questions added!!`, 600, topicColor);
        } catch (error) {
          console.log(error);
          alert("Error in parsing items");
          return;
        }
      } else {
        await createQuizQuestion({
          text: cleanText(data.text),
          isFavourite: data.isFavourite,
        });
        await message(`Question added!`, 500, topicColor);
        onAdd?.();
      }
    } else {
      await updateQuizQuestion({
        ...data,
        text: cleanText(data.text),
        setId: markAsCustom ? null : question.setId,
      });
      await message("Question updated!", 500, topicColor);
    }
    if (sequence) {
      reset({
        text: "",
        isFavourite: data.isFavourite,
      });
      setInputMode("text");
    } else {
      onClose();
    }
  });

  const txt = watch("text");

  useEffect(() => {
    if (txt.includes(",,")) {
      setValue("text", txt.replaceAll(",,", " ((-)) "));
    }
  }, [txt]);

  const isAIGenerated = !!watch("setId");
  const isFavourite = watch("isFavourite");

  const toggleFavourite = () => {
    setValue("isFavourite", !isFavourite);
  };

  const unverified = !isVerified({ text: txt });
  const toggleUnverified = () => {
    const newTxt = unverified ? txt.slice(0, -1) : txt + "*";
    setValue("text", newTxt);
  };

  const isAddNew = question === "new";

  const [magicGen, setMagicGen] = useState("");
  const closeMagicGen = () => setMagicGen("");

  const onMagicGen = async () => {
    if (!magicGen) {
      setMagicGen(txt);
    } else {
      closeMagicGen();
    }
  };

  const onSelectGenerated = (text: string) => {
    setValue("text", text);
    setInputMode("clickable");
    setSequence(true);
  };

  return (
    <Modal width={600}>
      <StyledForm onSubmit={onSubmit} topicColor={topicColor}>
        {isAddNew ? (
          <h3>Add {type} question</h3>
        ) : (
          <h3>Edit {type} question</h3>
        )}
        {!addMultiple && <FormattedGaps className="preview" text={txt} />}
        <Switches>
          {!addMultiple && (
            <label>
              <span>Clickable</span>
              <Switch
                onChange={toggleInputMode}
                checked={inputMode === "clickable"}
              />
            </label>
          )}
          {isAIGenerated && (
            <label>
              <span>As custom</span>
              <Switch onChange={toggleMarkAsCustom} checked={markAsCustom} />
            </label>
          )}
          <label>
            <span>To verify</span>
            <Switch checked={unverified} onChange={toggleUnverified} />
          </label>
          <Fav active={isFavourite} type="button" onClick={toggleFavourite}>
            {FAVOURITES_CONTEXT}
          </Fav>
        </Switches>
        <MagicGenerator
          textForGeneration={magicGen}
          onSelect={onSelectGenerated}
          onClose={closeMagicGen}
          topic={type}
        />
        <textarea
          {...register("text")}
          autoFocus
          style={{ display: inputMode === "text" ? "block" : "none" }}
          rows={6}
          onKeyDown={
            // @ts-ignore
            (e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                onSubmit();
              }

              const text = gapAnswerWithFirstAnswers(
                (e.target as any).value as string
              );

              if (e.key === "@") {
                e.preventDefault();
                window.open(getTranslationUrl(text, "google"), "_blank");
              }
              if (e.key === "#") {
                e.preventDefault();
                window.open(getTranslationUrl(text, "deepl"), "_blank");
              }

              if (e.key === "§") {
                e.preventDefault();
                onMagicGen();
              }
            }
          }
        />
        {inputMode === "clickable" &&
          (type === "gerunds" ? (
            <ClickableWordGerund
              text={txt}
              onChange={(newText) => {
                setValue("text", newText);
                setInputMode("text");
                if (gerundSubmitted) {
                  setGerundSubmitted(false);
                  onSubmit();
                }
              }}
              gerundSubmitted={gerundSubmitted}
            />
          ) : type === "varia" ? (
            <ClickableWordVaria
              text={txt}
              onChange={(newText) => {
                setValue("text", newText);
              }}
              topic={type}
            />
          ) : (
            <ClickableWords
              text={txt}
              onChange={(newText) => {
                setValue("text", newText);
              }}
              topic={type}
            />
          ))}

        {isAddNew && !newText && (
          <Switches>
            <Fav active={false} type="button" onClick={onMagicGen}>
              💿
            </Fav>
            <label>
              <span>JSON</span>
              <Switch checked={addMultiple} onChange={toggleAddMultiple} />
            </label>
            <label>
              <span>Sequence</span>
              <Switch checked={sequence} onChange={toggleSequence} />
            </label>
          </Switches>
        )}

        <Buttons>
          <ModalButton onClick={onClose} magicColor={topicColor}>
            Cancel
          </ModalButton>
          <ModalButton
            type="submit"
            disabled={updateLoading}
            magicColor={topicColor}
            magicReverse
          >
            Save
          </ModalButton>
        </Buttons>
      </StyledForm>
    </Modal>
  );
};

function cleanText(text: string) {
  return text
    .replace(/\s+/g, " ")
    .replaceAll(" .", ".")
    .replaceAll(" ,", ",")
    .replaceAll(" ?", "?")
    .replaceAll(" !", "!")
    .trim();
}

function parseMultiple(text: string) {
  text = text.trim();

  if (text.endsWith(",")) {
    text = text.slice(0, -1);
  }

  if (!text.startsWith("[")) {
    text = `[${text}`;
  }
  if (!text.endsWith("]")) {
    text = `${text}]`;
  }

  return JSON.parse(text);
}
