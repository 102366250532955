import { useEffect, useState } from "react";
import { QuizQuestionDto } from "../../../types";
import { uniqueArray } from "../../../util/array";

export interface GapExercise {
  id: string;
  originalText: string;
  allAnswers: string[];
  segments: ExerciseSegment[];
  question: QuizQuestionDto;
}

export interface ExerciseTextSegment {
  type: "text";
  id: string;
  text: string;
}

export interface ExerciseGapSegment {
  type: "gap";
  id: string;
  rawAnswer: string;
  answers: string[];
}

export type ExerciseSegment = ExerciseTextSegment | ExerciseGapSegment;

export const useGapExercises = (fetchFn: () => Promise<QuizQuestionDto[]>) => {
  const [exercises, setExercises] = useState<GapExercise[]>([]);

  useEffect(() => {
    fetchFn()
      .then((rawExerices) => rawExerices.flatMap(parseExercise))
      .then(setExercises);
  }, []);

  return [exercises] as const;
};

export const parseExercise = (
  question: QuizQuestionDto,
  index: number
): GapExercise[] => {
  const rawExercise = question.text;

  try {
    const openingBracketsLength = rawExercise.match(/\(\(/g)?.length || 0;
    const closingBracketsLength = rawExercise.match(/\)\)/g)?.length || 0;

    if (!openingBracketsLength || !closingBracketsLength) {
      throw new Error("Missing some brackets");
    }

    if (openingBracketsLength !== closingBracketsLength) {
      throw new Error("Number of opening and closing brackets is different");
    }

    const segments = parseSegments(rawExercise, question._id);

    return [
      {
        id: question._id,
        question,
        originalText: rawExercise,
        segments,
        allAnswers: parseAllSegmentsAnswers(segments),
      },
    ];
  } catch (e) {
    console.warn(`Failed to parse exercise: ${rawExercise}`);
    console.error(e);
    return [];
  }
};

export const parseSegments = (originalText: string, questionId: string) => {
  const segments: ExerciseSegment[] = [];

  originalText.split("))").forEach((entry, index) => {
    const [text, rawAnswer] = entry.split("((").map((s) => s.trim());
    if (text) {
      segments.push({
        type: "text",
        id: `${questionId}-${segments.length}`,
        text,
      });
    }
    if (rawAnswer) {
      segments.push({
        type: "gap",
        id: `${questionId}-${segments.length}`,
        rawAnswer,
        answers: parseAnswers(rawAnswer),
      });
    }
  });
  return segments;
};

const parseAnswers = (rawAnswer: string) => {
  const answers = rawAnswer.split("/");
  if (answers.includes("-")) {
    answers.push("");
  }
  return answers;
};

const parseAllSegmentsAnswers = (segments: ExerciseSegment[]) => {
  return segments.flatMap((segment) => {
    if (segment.type === "gap") {
      return uniqueArray(segment.answers);
    }
    return [];
  });
};
