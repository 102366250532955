import styled from "styled-components";
import { FC, useEffect, useRef } from "react";
import { ControlsState } from "./hooks";

interface SearchProps {
  state: ControlsState["searchState"];
}

const StyledSearch = styled.input`
  margin-left: 20px;
  height: 40px;
  padding: 5px;
  width: 400px;
`;
export const Search: FC<SearchProps> = (props) => {
  const { state } = props;
  const [searchState, setSearchState] = state;

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        ref.current?.focus();
      }, 0);
    }
  }, [ref.current]);

  return (
    <StyledSearch
      type="search"
      id="main-search"
      autoCapitalize="none"
      value={searchState || ""}
      onChange={(e) => setSearchState(e.target.value)}
      ref={ref}
    />
  );
};
