import { CollectionButton } from "./Flashcard/style";
import { COLLECTION_ICON } from "./Controls/consts";
import { FC, useContext, useState } from "react";
import { AppContext } from "./AppContext";
import {
  getFlashcardCollections,
  NamedCollection,
  useCollection,
} from "../util/collections";
import { Modal, ModalLevel } from "./Modal";
import { CollectionSelect } from "./CollectionSelect";
import styled from "styled-components";

type CollectionToggleButtonProps = {
  flashcardId?: string;
  newFlashcardCollections?: string[];
  modalLevel?: ModalLevel;
  absolute?: boolean;
};

const ExtraButtons = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
`;

const MarkUsedButton = styled.button<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background: ${(props) => props.color};
  color: white;
  cursor: pointer;
  font-size: 1em;
  margin: 0;

  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 1.5em;
`;

export const CollectionToggleButton: FC<CollectionToggleButtonProps> = (
  props
) => {
  const {
    flashcardId,
    newFlashcardCollections,
    modalLevel = "1.5",
    absolute,
  } = props;
  const { collections, toggleCollectionItem, bumpUses } =
    useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [advancedMode, setAdvancedMode] = useState(false);
  const getCollection = useCollection();

  const hideModal = () => {
    setShowModal(false);
    setAdvancedMode(false);
  };

  const flashcardCollections = flashcardId
    ? getFlashcardCollections(flashcardId, collections)
    : collections.filter((col) => newFlashcardCollections?.includes(col.name));

  const markUsed = () => {
    if (!flashcardId) {
      return;
    }

    flashcardCollections.forEach((collection) => {
      if (
        collection.name.endsWith("+") ||
        collection.name === NamedCollection.USED
      ) {
        toggleCollectionItem(collection.name, flashcardId);
      }
    });
    toggleCollectionItem(NamedCollection.USED, flashcardId);
    bumpUses();
    hideModal();
  };

  return (
    <>
      <CollectionButton
        absolute={absolute}
        className="collection-button"
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
          // right click
          if (e.button === 2) {
            setAdvancedMode(true);
          }
        }}
        onContextMenu={(event) => {
          event.preventDefault();
          setShowModal(true);
          setAdvancedMode(true);
        }}
      >
        {flashcardCollections.length ? (
          <>
            {flashcardCollections.map((collection) => (
              <CollectionItem key={collection._id} name={collection.name} />
            ))}
          </>
        ) : (
          <CollectionItem />
        )}
      </CollectionButton>
      {showModal && (
        <Modal onClickOutside={hideModal} width={600} level={modalLevel}>
          <div style={{ padding: "20px" }}>
            <CollectionSelect
              values={flashcardCollections.map((col) => col.name)}
              onChange={(collectionName) => {
                if (flashcardId) {
                  toggleCollectionItem(collectionName, flashcardId);
                } else {
                  newFlashcardCollections?.push(collectionName);
                }
                if (!advancedMode) {
                  hideModal();
                }
              }}
            />
            <ExtraButtons>
              {flashcardId && (
                <MarkUsedButton
                  onClick={markUsed}
                  color={getCollection(NamedCollection.USED).color}
                >
                  {getCollection(NamedCollection.USED).icon}
                </MarkUsedButton>
              )}
            </ExtraButtons>
          </div>
        </Modal>
      )}
    </>
  );
};

const CollectionItem = ({ name }: { name?: string }) => {
  const getCollection = useCollection();
  const collection = name
    ? getCollection(name)
    : { icon: COLLECTION_ICON, color: "rgba(0,0,0,0.5)" };
  return <div style={{ color: collection.color }}>{collection.icon}</div>;
};
