import { FC, useEffect } from "react";
import { useNavigate } from "react-router";

export const ConfigPage: FC = () => {
  const navigate = useNavigate();
  const lastSeenConfig = localStorage.getItem("last-seen-config");

  useEffect(() => {
    switch (lastSeenConfig) {
      case "topic":
        navigate("/config/topic");
        break;
      case "babble":
        navigate("/config/babble");
        break;
      case "collection":
        navigate("/config/collection");
        break;
      case "insights":
        return navigate("/config/insights");
      default:
        navigate("/config/topic");
        break;
    }
  }, []);

  return null;
};
