import { FC, useContext } from "react";
import { AppContext } from "./AppContext";
import styled, { css } from "styled-components";

const CollectionSelectGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

const CollectionButton = styled.button<{ color: string; active: boolean }>`
  display: flex;
  justify-content: center;

  .flag {
    color ${(props) => props.color};
    margin-right: 10px;
  }


  border: 1px solid black;
  cursor: pointer;
  font-size: 1em;

  ${(props) =>
    props.active &&
    css`
      border: 2px solid red;
    `}

  width: 100%;
  padding: 10px;
`;

interface CollectionSelectProps {
  values: string[];
  onChange: (value: string) => void;
}

export const CollectionSelect: FC<CollectionSelectProps> = (props) => {
  const { values, onChange } = props;
  const { collections } = useContext(AppContext);

  return (
    <CollectionSelectGrid>
      {collections.map((col) => {
        return (
          <CollectionButton
            key={col._id}
            onClick={() => onChange(col.name)}
            color={col.color}
            active={values.includes(col.name)}
          >
            <div className="flag">{col.icon}</div>
            {col.name}
          </CollectionButton>
        );
      })}
    </CollectionSelectGrid>
  );
};
