import {
  BabbleDto,
  CollectionDto,
  ExplanationDto,
  FlashcardCreateDto,
  FlashcardDto,
  InsightDto,
  QuizQuestionDto,
  QuizQuestionTopic,
  StoryDto,
  TopicDto,
  TranslationDto,
  TranslationType,
} from "../types";
import { wait } from "./wait";
import { dispatchInsightEvent } from "./react";
import { getToken, onResponse } from "./token";

const API_URL = process.env.REACT_APP_API_URL;

const get = async function <T>(url: string, params?: object) {
  // Construct the URL with query parameters
  const queryString = new URLSearchParams(params as any).toString();
  const fullUrl = `${API_URL}${url}?${queryString}`;

  return fetch(fullUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-Auth": getToken(),
    },
  }).then(onResponse) as T;
};

const post = async function <T>(url: string, body: object) {
  return fetch(API_URL + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Auth": getToken(),
    },
    body: JSON.stringify(body),
  }).then(onResponse) as T;
};

const put = async function <T>(url: string, body: object) {
  return fetch(API_URL + url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-Auth": getToken(),
    },
    body: JSON.stringify(body),
  }).then(onResponse) as T;
};

const del = async function (url: string) {
  return fetch(API_URL + url, {
    method: "DELETE",
    headers: {
      "X-Auth": getToken(),
    },
  }).then(onResponse);
};

export const getAllFlashcards = async () => {
  return await get<FlashcardDto[]>("/flashcard");
};

export const getFlashcardsChunk = async (page = 1, limit = 500) => {
  return await get<FlashcardDto[]>("/flashcard/v2", {
    page,
    limit,
  });
};

export const getFlashcard = async (flashcardId: string) => {
  return await get<FlashcardDto>(`/flashcard/${flashcardId}`);
};

export const createFlashcard = async (
  flashcard: FlashcardCreateDto
): Promise<FlashcardDto> => {
  return await post("/flashcard", flashcard);
};

export const updateFlashcard = async (flashcard: FlashcardDto) => {
  return await put<FlashcardDto>(`/flashcard/${flashcard._id}`, flashcard);
};

export const deleteFlashcard = async (flashcardId: string) => {
  return await del(`/flashcard/${flashcardId}`);
};

export const getCollections = async () => {
  return await get<CollectionDto[]>("/collection");
};

export const updateCollection = async (collection: CollectionDto) => {
  return await put(`/collection/${collection._id}`, collection);
};

export const createCollection = async (
  collectionName: string
): Promise<CollectionDto> => {
  return await put("/collection", { name: collectionName });
};

export const deleteCollection = async (collectionId: string) => {
  return await del(`/collection/${collectionId}`);
};

export const getStories = async () => {
  return await get<StoryDto[]>("/stories");
};

export const createStoryWithAI = async (
  flashcardIds: string[]
): Promise<StoryDto> => {
  return await post("/story", { flashcardIds });
};

export const deleteStory = async (storyId: string) => {
  return await del(`/story/${storyId}`);
};

export const generateExampleSentences = async (
  flashcard: Partial<FlashcardCreateDto>,
  exampleContext = ""
) => {
  if (!flashcard.engText) {
    return [];
  }

  return post(`/examples`, {
    flashcard,
    extraRequirements: exampleContext,
  }) as Promise<string[]>;
};

export const generateExampleSentencesSpecial = async (
  flashcard: Partial<FlashcardCreateDto>
) => {
  if (!flashcard.engText) {
    return [];
  }

  return post(`/examples/special`, {
    flashcard,
  }) as Promise<string[]>;
};

export const askWritingAssistant = async (
  text: string,
  crucialPhrase?: string
) => {
  if (!text) {
    return { response: "", text };
  }

  return post(`/writing-assistant`, {
    text,
    crucialPhrase,
  }) as Promise<{ response: string; text: string }>;
};

const mockGenerateExampleSentences = async () => {
  await wait(3000);
  return [
    "When Ron accidentally walked into the girls' bathroom, he was so abashed that he turned bright red and stammered apologies for the next hour.",
    "When Ron accidentally walked into the girls' bathroom, he was so abashed that he turned bright red and stammered apologies for the next hour.",
    "When Ron accidentally walked into the girls' bathroom, he was so abashed that he turned bright red and stammered apologies for the next hour.",
  ];
};

export const getTopics = async (newOnly: boolean) => {
  return await get<TopicDto[]>("/topics", {
    newOnly,
  });
};

export const createNewTopic = async (level: "easy" | "advanced") => {
  return await post("/topic", { level });
};

export const deleteTopic = async (topicId: string) => {
  return await del(`/topic/${topicId}`);
};

export const updateTopic = async (topic: TopicDto) => {
  return await put(`/topic/${topic._id}`, topic);
};

export const regenerateTopicImage = async (topicId: string) => {
  return await put(`/topic/${topicId}/regenerate-image`, {});
};

// ---

export const getBabbles = async (newOnly: boolean) => {
  return await get<BabbleDto[]>("/babbles", {
    newOnly,
  });
};

// export const getBabblesLegacy = async (): Promise<BabbleDto[]> => {
//   const EXAMPLES_URL = "https://api.npoint.io/19916fafc8f939e5d3fe";
//
//   const babbles = (await fetch(EXAMPLES_URL).then((data) =>
//     data.json()
//   )) as string[];
//
//   return babbles.map((babble, index) => {
//     return {
//       _id: babble,
//       text: babble,
//       status: "new",
//     };
//   });
// };

export const createNewBabble = async (text?: string) => {
  return await post<BabbleDto>("/babble", { text });
};

export const deleteBabble = async (babbleId: string) => {
  return await del(`/babble/${babbleId}`);
};

export const updateBabble = async (babble: BabbleDto) => {
  return await put(`/babble/${babble._id}`, babble);
};

// ---

export const getInsight = async (flashcardId: string, regenerate: boolean) => {
  return await get<InsightDto>(`/insight/${flashcardId}`, {
    regenerate,
  }).then((insight) => {
    dispatchInsightEvent(flashcardId);
    return insight;
  });
};

export const inquireAboutInsight = async (
  flashcardId: string,
  inquiry: string
) => {
  return await post<InsightDto>(`/insight/${flashcardId}`, {
    inquiry,
  });
};

export const inquireAboutExplanation = async (
  questionId: string,
  inquiry: string
) => {
  return await post<ExplanationDto>(`/explain/${questionId}`, {
    inquiry,
  });
};

export const getTranslations = async (
  word: string,
  translationType: TranslationType
) => {
  if (translationType === "eng-to-pol") {
    // todo
    return [];
  }

  return await get<TranslationDto[]>(`/smart-translate/${encodeURI(word)}`, {
    maxItems: 4,
    translationType,
  });
};

export const getQuizQuestions = async (type: QuizQuestionTopic) => {
  return get<QuizQuestionDto[]>(`/quiz/${type}`).then((res) => res.reverse());
};

export const createQuizQuestions = async (type: QuizQuestionTopic) => {
  return post<QuizQuestionDto[]>(`/quiz/${type}`, {});
};

export const createFlashcardQuizQuestion = async (flashcardId: string) => {
  return put<QuizQuestionDto>(`/quiz/flashcard/${flashcardId}`, {});
};

export const createQuizQuestion = async (
  type: QuizQuestionTopic,
  text: string | string[],
  payload: Partial<QuizQuestionDto> = {}
) => {
  const body = Array.isArray(text)
    ? { texts: text, ...payload }
    : { text, ...payload };

  return post<QuizQuestionDto>(`/quiz/${type}`, body);
};

export const deleteQuizQuestion = async (questionId: string) => {
  return await del(`/quiz/${questionId}`);
};

export const updateQuizQuestion = async (quizQuestion: QuizQuestionDto) => {
  return await put(`/quiz/${quizQuestion._id}`, quizQuestion);
};

export const getExplanation = async (
  topic: QuizQuestionTopic,
  quizQuestionId: string,
  regenerate = false
) => {
  return await get<ExplanationDto>(`/explain/${topic}/${quizQuestionId}`, {
    regenerate,
  });
};

export const getBareInfinitive = async (verb: string) => {
  return await get<string>(`/grammar/bare-infinitive/${encodeURI(verb)}`);
};

export const getQuizVariaQuestionDesc = async ({
  selectedText,
  fullText,
}: {
  selectedText: string;
  fullText: string;
}) => {
  return await post<{ text: string }>("/quiz/varia/generate", {
    selectedText,
    fullText,
  });
};
