import { usePersistedState } from "./react";
import { isAfter, subHours } from "date-fns";
import { QuizQuestionTopic } from "../types";

const MAX_RECORDS_STORED = 800;

export const useHeatometer = () => {
  const [heatometer, setHeatometer] = usePersistedState<number[]>("heat", []);

  const heat = getFromThisHour(heatometer);

  const bumpHeat = () => {
    setHeatometer(
      [new Date().getTime(), ...heatometer].slice(0, MAX_RECORDS_STORED)
    );
  };

  return { heat, bumpHeat };
};

// ====

export const useQuizometer = () => {
  const [quizometer, setQuizometer] = usePersistedState<string[]>(
    "quizometer",
    []
  );

  const bumpQuizometer = (type: QuizQuestionTopic) => {
    const newItem = `${type}-${new Date().getTime()}`;

    setQuizometer([newItem, ...quizometer].slice(0, MAX_RECORDS_STORED));
  };

  const getQuizometer = (type: QuizQuestionTopic | "all") => {
    const items = quizometer
      .filter((i) => type === "all" || i.startsWith(`${type}`))
      .map((item) => item.split("-")[1])
      .map(Number);
    return getDailyUses(items);
  };

  return { getQuizometer, bumpQuizometer };
};

// ====
export const useUsemeter = () => {
  const [usemeter, setUsemeter] = usePersistedState<number[]>("uses", []);

  const uses = getDailyUses(usemeter);

  const bumpUses = () => {
    setUsemeter(
      [new Date().getTime(), ...usemeter].slice(0, MAX_RECORDS_STORED)
    );
  };

  return { uses, bumpUses };
};

const getFromThisHour = (items: number[]) => {
  const hourAgo = subHours(new Date(), 1);
  return items.filter((h) => isAfter(h, hourAgo)).length;
};

const getDailyUses = (items: number[]) => {
  const closest2am = getLast2AMDate();
  return items.filter((h) => isAfter(h, closest2am)).length;
};

function getLast2AMDate(): Date {
  let date = new Date();

  // Get current hour
  let currentHour = date.getHours();

  // If it's before 2 AM, set the date to yesterday
  if (currentHour < 2) {
    date.setDate(date.getDate() - 1);
  }

  // Set the hour, minutes, seconds, and milliseconds to 2 AM
  date.setHours(2, 0, 0, 0);

  return date;
}
