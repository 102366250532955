import { FlashcardDto } from "../types";
import { RING_ACTIVE_THRESHOLD } from "./ring";
import { useContext } from "react";
import { AppContext } from "../components/AppContext";
import { getFlashcardCollections, NamedCollection } from "./collections";
import { useMatch } from "react-router";

export const useIsWriterPage = () => {
  return Boolean(useMatch("/writer"));
};

export const useWriter = (
  isWriter: boolean,
  shownFlashcards: FlashcardDto[]
): FlashcardDto[] => {
  const { collections } = useContext(AppContext);

  if (!isWriter) {
    return shownFlashcards;
  }

  return shownFlashcards.filter((fl) => {
    const cols = getFlashcardCollections(fl._id, collections);
    const colNames = cols.map((col) => col.name);

    if (colNames.includes(NamedCollection.USED)) {
      return false;
    }

    const isRingActive = (fl.ring || 0) > RING_ACTIVE_THRESHOLD;
    const isUseCollection = colNames.some((col) => col.includes("use-"));
    const isFlawed = fl.proficiency <= -2;
    return isRingActive || isUseCollection || isFlawed;
  });
};
