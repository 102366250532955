import React, { FC, useState } from "react";
import { QuizQuestionDto, QuizQuestionTopic } from "../../types";
import {
  useQuizQuestions,
  useRemoveQuizQuestion,
  useUpdateQuizQuestion,
} from "../../util/queries";
import styled from "styled-components";
import { QuestionModal, Switches } from "./QuestionModal";
import { FormattedGaps } from "./components/FormattedGaps";
import { usePersistedState } from "../../util/react";
import Switch from "react-switch";
import {
  checkWillAdd,
  hasAnyGaps,
  isAI,
  isNotAsked,
  isVerified,
  useQuizPage,
  useTopicColors,
} from "./utils/quiz";
import { Radio } from "../Radio";
import { ExplanationModal } from "./components/ExplanationModal";
import { getExplanation } from "../../util/api";
import { QuizAlgorithm, quizAlgorithmOptions } from "./utils/algorithm";

const ItemBox = styled.div<{
  status: "new" | "live";
  topicColor: string;
}>`
  margin: 10px 5px;
  padding: 10px 5px;
  background-color: ${(props) => {
    switch (props.status) {
      case "new":
        return props.topicColor;
      default:
        return "white";
    }
  }};

  .info-icon {
    display: inline-block;
    margin-right: 20px;
  }

  .count {
    margin: 5px 0;
  }

  .buttons {
    display: block;

    button {
      margin-right: 10px;
    }
  }
`;

enum QuizView {
  ALL = "🌐",
  NEW = "🆕",
  FAVOURITE = "🌟",
  AI = "🤖",
  MISTAKES = "❌",
  UNVERIFIED = "❓",
}

const quizViews = [
  QuizView.ALL,
  QuizView.NEW,
  QuizView.FAVOURITE,
  QuizView.AI,
  QuizView.MISTAKES,
  QuizView.UNVERIFIED,
];

const quizViewOptions = quizViews.map((view) => ({ label: view, value: view }));

enum SearchCommand {
  DUPLICATE = "duplicate",
}

const searchCommands = [SearchCommand.DUPLICATE];

function useFiltering(
  questions: QuizQuestionDto[],
  topic: QuizQuestionTopic,
  includeAutogen: boolean,
  search: string
) {
  const [quizView, setView] = usePersistedState<QuizView>(
    `quiz-view-${topic}`,
    QuizView.ALL
  );

  let shownQuestions = questions.filter((question) => {
    if (includeAutogen) {
      return true;
    }
    return !question.setId;
  });

  if (quizView === QuizView.UNVERIFIED) {
    shownQuestions = shownQuestions.filter(
      (question) => !isVerified(question) || !hasAnyGaps(question)
    );
  }

  if (quizView === QuizView.NEW) {
    shownQuestions = shownQuestions.filter(isNotAsked);
  }

  if (quizView === QuizView.MISTAKES) {
    shownQuestions = shownQuestions
      .filter((question) => question.lastAskedResult === "incorrect")
      .sort((a, b) => {
        const aLastAsked = a.lastAsked || 0;
        const bLastAsked = b.lastAsked || 0;
        return bLastAsked.toString().localeCompare(aLastAsked.toString());
      });
  }
  if (quizView === QuizView.FAVOURITE) {
    shownQuestions = shownQuestions.filter((question) => question.isFavourite);
  }

  if (quizView === QuizView.AI) {
    shownQuestions = shownQuestions.filter(isAI);
  }

  if (quizView)
    if (search === SearchCommand.DUPLICATE) {
      // filter all which have the same text
      const texts = new Set<string>();
      shownQuestions = shownQuestions.filter((question) => {
        if (texts.has(question.text)) {
          return true;
        }
        texts.add(question.text);
        return false;
      });
    } else if (search) {
      shownQuestions = shownQuestions.filter((question) =>
        question.text.toLowerCase().includes(search.toLowerCase())
      );
    }

  return [shownQuestions, quizView, setView] as const;
}

interface QuizConfigProps {
  topic: QuizQuestionTopic;
}

export const QuizConfig: FC<QuizConfigProps> = ({ topic }) => {
  useQuizPage(`${topic}/config`);

  const [questions] = useQuizQuestions(topic);

  const [removeQuizQuestion] = useRemoveQuizQuestion(topic);
  const [updateQuizQuestion] = useUpdateQuizQuestion(topic);

  const [editedQuizQuestion, setEditedQuizQuestion] = useState<
    QuizQuestionDto | null | "new"
  >(checkWillAdd());

  const onClickRemove = (id: string, isCustom: boolean) => {
    if (
      !isCustom ||
      window.confirm("Are you sure you want to remove this question?")
    ) {
      removeQuizQuestion(id);
    }
  };

  const [autogenerateOn, setAutogenerateOn] = usePersistedState(
    `quiz-autogenerate-${topic}`,
    false
  );
  const toggleAutogenerate = () => setAutogenerateOn(!autogenerateOn);

  const [includeAutogen, setIncludeAutogen] = usePersistedState(
    `quiz-include-autogen-${topic}`,
    false
  );
  const toggleIncludeAutogen = () => setIncludeAutogen(!includeAutogen);

  const [quizAlgorithm, setQuizAlgorithm] = usePersistedState<QuizAlgorithm>(
    `quiz-algorithm-${topic}`,
    QuizAlgorithm.RANDOM
  );

  const [search, setSearch] = useState("");

  const [shownQuestions, quizView, setQuizView] = useFiltering(
    questions,
    topic,
    includeAutogen,
    search
  );

  const [questionToExplain, setQuestionToExplain] =
    useState<QuizQuestionDto | null>(null);

  const getTopicColor = useTopicColors();
  const topicColor = getTopicColor(topic);

  return (
    <div style={{ marginBottom: "100px" }}>
      <ItemBox status="new" topicColor={topicColor}>
        <button onClick={() => setEditedQuizQuestion("new")}>
          Add a quiz question
        </button>
      </ItemBox>
      <ItemBox status="new" topicColor={topicColor}>
        <Switches>
          <label>
            <span>Autogen</span>
            <Switch checked={autogenerateOn} onChange={toggleAutogenerate} />
          </label>
          <label>
            <span>Use autogen</span>
            <Switch checked={includeAutogen} onChange={toggleIncludeAutogen} />
          </label>
        </Switches>
        <Switches style={{ flexDirection: "column", marginTop: "10px" }}>
          <label>
            <Radio
              name={"quizAlgorithm"}
              value={quizAlgorithm}
              layout="horizontal-rows"
              options={quizAlgorithmOptions}
              onChange={(a) => setQuizAlgorithm(a as QuizAlgorithm)}
              between={10}
            />
          </label>
          <label>
            <Radio
              name={"quizViewOptions"}
              value={quizView}
              layout="horizontal-rows"
              options={quizViewOptions}
              onChange={(a) => setQuizView(a as QuizView)}
              between={10}
            />
          </label>
          <label>
            <span>({shownQuestions.length})</span>
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{
                color: searchCommands.includes(search as SearchCommand)
                  ? "blue"
                  : undefined,
              }}
              placeholder="Search..."
            />
          </label>
        </Switches>
      </ItemBox>
      {shownQuestions.map((question) => {
        const isAIGenerated = isAI(question);
        const askedCount = question.askedCount || 0;
        const correctCount = askedCount - (question.mistakesCount || 0);

        return (
          <ItemBox
            key={question._id}
            status={question.status}
            topicColor={topicColor}
          >
            <FormattedGaps text={question.text} />
            <div className="buttons">
              {isAIGenerated && <span className="info-icon">🤖</span>}
              {question.isFavourite && <span className="info-icon">🌟</span>}
              <button
                onClick={() => onClickRemove(question._id, !isAIGenerated)}
              >
                Remove
              </button>
              {question.status !== "new" && (
                <button
                  onClick={() =>
                    updateQuizQuestion({
                      ...question,
                      status: "new",
                    })
                  }
                >
                  Unseen
                </button>
              )}
              <button
                type="button"
                onClick={() => {
                  setEditedQuizQuestion(question);
                }}
              >
                Edit
              </button>
              <button
                type="button"
                onClick={() => {
                  setQuestionToExplain(question);
                }}
              >
                🌡️
              </button>
              <span>
                ({correctCount}/{askedCount})
              </span>
            </div>
          </ItemBox>
        );
      })}
      {editedQuizQuestion && (
        <QuestionModal
          question={editedQuizQuestion}
          type={topic}
          onClose={() => setEditedQuizQuestion(null)}
        />
      )}
      {questionToExplain && (
        <ExplanationModal
          closeModal={() => setQuestionToExplain(null)}
          explainFn={(id: string, regenerate?: boolean) => {
            return getExplanation(topic, id, regenerate);
          }}
          question={questionToExplain}
        />
      )}
    </div>
  );
};
