import { FC, useEffect, useState } from "react";
import { useSelectableText } from "../../Speak/useSelectableText";
import styled, { css } from "styled-components";
import { getBareInfinitive, getQuizVariaQuestionDesc } from "../../../util/api";
import { RainbowLoader } from "../../RainbowLoader";
import { QuizQuestionTopic } from "../../../types";
import { useTopicColor, useTopicColors } from "../utils/quiz";
import { insertItemAtIndex } from "../../../util/array";

interface WithTopicColor {
  topicColor: string;
}

const ClickableWordsWrapper = styled.div<WithTopicColor>`
    padding: 10px;
    padding-bottom: 15px;
    box-sizing: border-box;
    margin: 5px;
    width: 100%;
    border: 1px dashed ${(props) => props.topicColor};

    .selected {
        background-color: ${(props) => props.topicColor};
    }
}
`;

const Word = styled.span<
  WithTopicColor & {
    isGap: boolean;
  }
>`
  cursor: pointer;
  font-size: 1.2em;
  display: inline-block;
  margin: 20px 10px 0px 0px;
  background-color: ${(props) => (props.isGap ? props.topicColor : "unset")};

  color: ${(props) => (props.isGap ? "white" : "unset")};

  span {
    color: gray;
  }
`;

const splitIntoWords = (text: string) => {
  return text.split(/(\s+|(?<=[.,!?])\s*|\s*(?=[.,!?]))/).filter(Boolean);
};

function replaceDoubleParens(input: string): string {
  return input.replace("((", "").replace("))", "");
}

const updateWord = (word: string) => {
  if (word === " ") {
    return "((-))";
  }
  if (word === "((-))") {
    return "";
  }

  return word.includes("((") && word.includes("))")
    ? replaceDoubleParens(word)
    : `((${word}))`;
};

interface ClickableWordsProps {
  text: string;
  onChange: (text: string) => void;
  topic: QuizQuestionTopic;
}

export const ClickableWords: FC<ClickableWordsProps> = (props) => {
  const { text, onChange, topic } = props;

  const words = splitIntoWords(text);

  const [spaceMode, setSpaceMode] = useState(false);
  const toggleSpaceMode = () => setSpaceMode(!spaceMode);

  const getTopicColor = useTopicColors();
  const topicColor = getTopicColor(topic);

  const onClickWord = (wordIndex: number, spaceAble: boolean) => {
    let newWords = [...words];

    if (spaceMode) {
      if (!spaceAble) {
        return;
      }
      newWords = insertItemAtIndex(newWords, "((-))", wordIndex);
    } else {
      newWords[wordIndex] = updateWord(newWords[wordIndex]);
    }
    const newTxt = newWords.join(" ");
    onChange(newTxt);
  };

  const canAddSpace = (word: string, index: number) => {
    return (
      spaceMode &&
      !word.includes(" ") &&
      !word.includes("-") &&
      !words[index - 2]?.includes("-")
    );
  };

  return (
    <ClickableWordsWrapper topicColor={topicColor}>
      {words.map((word, i) => {
        const isGap = word.includes("((") || word.includes("))");
        word = isGap ? word.replace("((", "").replace("))", "") : word;
        const spaceAble = canAddSpace(word, i);

        return (
          <Word
            key={i}
            onClick={() => onClickWord(i, spaceAble)}
            isGap={isGap}
            topicColor={topicColor}
          >
            {spaceAble && <span>⇽</span>}
            {word}
          </Word>
        );
      })}
      <BackButton
        type="button"
        onClick={toggleSpaceMode}
        topicColor={topicColor}
        active={spaceMode}
      >
        ✦
      </BackButton>
    </ClickableWordsWrapper>
  );
};

const Selectable = styled.p`
  font-size: 1.2em;

  span {
    cursor: pointer;
    margin-right: 5px;
  }
`;

const BackButton = styled.button<
  WithTopicColor & {
    active?: boolean;
  }
>`
  all: unset;
  cursor: pointer;
  margin-left: 20px;
  font-size: 1.2em;
  ${(props) =>
    props.active &&
    css`
      color: ${props.topicColor};
    `}
`;

interface ClickableWordsGerundProps {
  text: string;
  gerundSubmitted: boolean;
  onChange: (text: string) => void;
}

export const ClickableWordGerund: FC<ClickableWordsGerundProps> = (props) => {
  const { text, onChange, gerundSubmitted } = props;
  const { selectableText, selection, sanitizedSelection } =
    useSelectableText(text);

  const gerundColor = useTopicColor("gerunds");

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(false);
  }, [text]);

  useEffect(() => {
    if (gerundSubmitted) {
      submit();
    }
  }, [gerundSubmitted]);

  const submit = async () => {
    try {
      setLoading(true);
      const bareInfinitive = await getBareInfinitive(sanitizedSelection);

      const newText = text.replace(
        sanitizedSelection,
        `((${sanitizedSelection})) (${bareInfinitive})`
      );

      onChange(newText);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <RainbowLoader />;
  }

  return (
    <ClickableWordsWrapper topicColor={gerundColor}>
      <Selectable>
        {selectableText}
        <BackButton type="button" onClick={submit} topicColor={gerundColor}>
          ✁
        </BackButton>
      </Selectable>
    </ClickableWordsWrapper>
  );
};

export const ClickableWordVaria: FC<ClickableWordsProps> = (props) => {
  const { text, onChange } = props;
  const { selectableText, sanitizedSelection } = useSelectableText(text);

  const variaColour = useTopicColor("varia");

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(false);
  }, [text]);

  const submit = async () => {
    try {
      setLoading(true);
      const desc = await getQuizVariaQuestionDesc({
        selectedText: sanitizedSelection,
        fullText: text,
      });

      const newText = text.replace(
        sanitizedSelection,
        `${sanitizedSelection
          .split(" ")
          .map((word) => "((" + word + "))")
          .join(" ")} (${desc})`
      );

      onChange(newText);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <RainbowLoader />;
  }

  return (
    <ClickableWordsWrapper topicColor={variaColour}>
      <Selectable>
        {selectableText}
        <BackButton type="button" onClick={submit} topicColor={variaColour}>
          ✁
        </BackButton>
      </Selectable>
    </ClickableWordsWrapper>
  );
};
