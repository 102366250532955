import axios from "axios";

const API_KEY = process.env.REACT_APP_TRANSLATE_API_KEY;

export async function translate(
  text: string,
  target: "en" | "pl",
  addAsterisk?: boolean
) {
  const params =
    target === "en"
      ? { q: text, source: "pl", target: "en" }
      : { q: text, source: "en", target: "pl" };

  let res = await axios.post(
    `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`,
    params
  );
  let translation = res.data.data.translations[0].translatedText as string;
  return translation + (addAsterisk ? "*" : "");
}

export const isTranslated = (text?: string) => Boolean(text?.includes("*"));

export const asUntranslated = (text: string) => {
  return text.replaceAll("*", "");
};
