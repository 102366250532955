import { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";
import { FlashcardDto } from "../../types";
import { shuffleArray } from "./util";
import {
  getCollectionFlashcards,
  NamedCollection,
} from "../../util/collections";

export const useSpkFlashcards = () => {
  const { flashcards, collections } = useContext(AppContext);
  const [spkFlashcards, setSpkFlashcards] = useState<FlashcardDto[]>([]);

  const shuffleSpkFlashcards = () => {
    if (!flashcards?.length) return;
    setSpkFlashcards(
      shuffleArray(
        getCollectionFlashcards(flashcards, collections, NamedCollection.SPEAK)
      ).slice(0, 1)
    );
  };

  useEffect(() => {
    shuffleSpkFlashcards();
  }, [flashcards]);

  return { spkFlashcards, shuffleSpkFlashcards };
};
