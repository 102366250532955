import styled from "styled-components";
import { FC, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { storeTransientFlashcardData } from "../util/flashcards";
import { TranslationDto, TranslationType } from "../types";
import { translate } from "../util/translate";
import { RainbowLoader } from "./RainbowLoader";
import { getTranslations } from "../util/api";

const getModeColor = (translationType: TranslationType) => {
  if (translationType === "pol-to-eng") return "rgb(80, 240, 255)";
  if (translationType === "eng-to-pol") return "rgb(249,126,143)";
  return "black";
};

const StyledSearchTranslateBox = styled.div<{
  active?: boolean;
  translationType: TranslationType;
}>`
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  padding-top: 0;
  background: ${(props) => getModeColor(props.translationType)};
  border: 2px solid gray;
  text-decoration: none;

  ${({ active }) => active && `cursor: pointer;`}
  .loader-inner {
    margin: 0 auto;
    transform: translate(-50%, 0%);
  }

  .eng {
    font-size: 1.2em;
  }

  .pol {
    font-size: 1em;
    color: #515151;
  }
`;

const LoaderBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AdvancedButton = styled.button<{
  translationType: TranslationType;
}>`
  all: unset;
  margin-top: 20px;
  font-size: 2em;
  cursor: pointer;
  padding: 10px 30px;
  background: white;
  color: ${(props) => getModeColor(props.translationType)};

  &:hover {
    color: gray;
  }
`;

const MIN_SEARCH_LENGTH = 4;

interface SearchTranslateBoxProps {
  search: string;
  translationType: TranslationType;
}

export const SearchTranslate: FC<SearchTranslateBoxProps> = (props) => {
  const { search, translationType } = props;
  const [translations, setTranslations] = useState<TranslationDto[]>([]);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [loading, setLoading] = useState(0);

  const _translate = async () => {
    setLoading((val) => val + 1);

    if (showAdvanced) {
      const smartTranslations = await getTranslations(search, translationType);
      setTranslations(smartTranslations);
    } else {
      let engText = "";
      let polText = "";

      if (translationType === "pol-to-eng") {
        engText = await translate(search, "en");
        polText = await translate(engText, "pl");
      }
      if (translationType === "eng-to-pol") {
        polText = await translate(search, "pl");
        engText = await translate(polText, "en");
      }

      setTranslations([{ engText, polText }]);
    }

    setLoading((val) => val - 1);
  };

  const debouncedTranslation = useDebouncedCallback(_translate, 500);

  useEffect(() => {
    setShowAdvanced(false);
    if (search.length < MIN_SEARCH_LENGTH) {
      setTranslations([]);
      return;
    }
    debouncedTranslation();
  }, [search]);

  useEffect(() => {
    if (search.length < MIN_SEARCH_LENGTH) return;
    _translate();
  }, [showAdvanced]);

  return (
    <>
      {translations.map((translation, index) => (
        <StyledSearchTranslateBox
          translationType={translationType}
          key={index}
          active
          onClick={() => {
            storeTransientFlashcardData({
              engText: translation.engText,
              polText: translation.polText,
              context: "SELF",
            });

            // navigate in new tab

            window.open("/add");
          }}
        >
          <p className="eng">{translation.engText}</p>
          <p className="pol">{translation.polText}</p>
          {index === 0 && (
            <AdvancedButton
              translationType={translationType}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setShowAdvanced((val) => !val);
              }}
            >
              {showAdvanced ? "-" : "+"}
            </AdvancedButton>
          )}
        </StyledSearchTranslateBox>
      ))}
      {loading > 0 && (
        <StyledSearchTranslateBox translationType={translationType}>
          <LoaderBox>
            <RainbowLoader />
          </LoaderBox>
        </StyledSearchTranslateBox>
      )}
    </>
  );
};
