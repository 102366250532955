import { TopicDto } from "../../types";

export type SpeakVariant = "babble" | "topic" | "record";

export function getRandomArrayElement<T>(array: T[]) {
  return array[Math.floor(Math.random() * array.length)];
}

export function shuffleArray<T>(array: T[]) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return [...array];
}

export function sanitizeText(input: string): string {
  // Use a regex pattern to replace any characters that aren't alphanumeric or spaces
  let sanitized = input.replace(/[^a-zA-Z0-9 ]/g, "");

  // Trim any extra spaces at the beginning or end
  sanitized = sanitized.trim();

  return sanitized;
}

export const isTopicAdvanced = (topic: TopicDto) => {
  return topic.text.endsWith("*");
};

export const isTopicEasy = (topic: TopicDto) => {
  return !isTopicAdvanced(topic);
};
