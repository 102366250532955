import styled from "styled-components";
import { FC } from "react";

const color1 = "hsl(0, 80%, 60%)";
const color2 = "hsl(60, 80%, 60%)";
const color3 = "hsl(120, 80%, 60%)";
const color4 = "hsl(180, 80%, 60%)";
const color5 = "hsl(240, 80%, 60%)";

const StyledRainbowLoader = styled.div`
  .loader {
    display: block;
    position: relative;
    height: 100px;
  }

  .loader-inner {
    bottom: 0;
    height: 60px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
  }

  .loader-line-wrap {
    animation: spin 2000ms cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
    box-sizing: border-box;
    height: 50px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform-origin: 50% 100%;
    width: 100px;
  }

  .loader-line {
    border: 4px solid transparent;
    border-radius: 100%;
    box-sizing: border-box;
    height: 100px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
  }

  .loader-line-wrap:nth-child(1) {
    animation-delay: -50ms;
  }

  .loader-line-wrap:nth-child(2) {
    animation-delay: -100ms;
  }

  .loader-line-wrap:nth-child(3) {
    animation-delay: -150ms;
  }

  .loader-line-wrap:nth-child(4) {
    animation-delay: -200ms;
  }

  .loader-line-wrap:nth-child(5) {
    animation-delay: -250ms;
  }

  .loader-line-wrap:nth-child(1) .loader-line {
    border-color: ${color1};
    height: 90px;
    width: 90px;
    top: 7px;
  }

  .loader-line-wrap:nth-child(2) .loader-line {
    border-color: ${color2};
    height: 76px;
    width: 76px;
    top: 14px;
  }

  .loader-line-wrap:nth-child(3) .loader-line {
    border-color: ${color3};
    height: 62px;
    width: 62px;
    top: 21px;
  }

  .loader-line-wrap:nth-child(4) .loader-line {
    border-color: ${color4};
    height: 48px;
    width: 48px;
    top: 28px;
  }

  .loader-line-wrap:nth-child(5) .loader-line {
    border-color: ${color5};
    height: 34px;
    width: 34px;
    top: 35px;
  }

  @keyframes spin {
    0%,
    15% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const RainbowLoader: FC = () => {
  return (
    <StyledRainbowLoader>
      <div className="loader">
        <div className="loader-inner">
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
        </div>
      </div>
    </StyledRainbowLoader>
  );
};
