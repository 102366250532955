import styled, { css } from "styled-components";
import React from "react";

interface ButtonProps {
  children?: React.ReactNode;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  onRightClick?: () => void;
  className?: string;

  topStyle?: boolean;
  smaller?: boolean;
  opacity?: number;
  isActive?: boolean;
}

export const buttonStyles = (props: ButtonProps) => css`
  cursor: pointer;
  text-align: center;
  color: black;

  ${props.topStyle &&
  css`
    border-radius: 50%;
    width: 44px;
    height: 44px;
    border: 1px solid #aeaeae;
    background: #fff;
    font-size: 1.6rem;
  `}

  ${props.smaller &&
  css`
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
  `}

  ${props.opacity !== undefined &&
  css`
    opacity: ${props.opacity};
  `}

  ${props.isActive &&
  css`
    border: 3px solid red;
  `}
`;

const StyledButton = styled.button<ButtonProps>`
  ${(props) => buttonStyles(props)}
`;

// export const Button: FC<ButtonProps> = (props) => {
//   const { onContextMenu, ...styledProps } = props;
//
//   return (
//     <StyledButton
//       {...styledProps}
//       onClick={}
//       on={(e) => {
//         if (onContextMenu) {
//           e.preventDefault();
//           onContextMenu();
//         }
//       }}
//     />
//   );
// };
//
//
// import React from "react";

export function Button(props: ButtonProps) {
  const { onClick, onRightClick, ...styledProps } = props;

  function handleMouseDown(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    if (event.button === 0 && !event.ctrlKey) {
      onClick && onClick(event);
    }
  }

  function handleTouchStart(event: React.TouchEvent<HTMLButtonElement>) {
    if (event.touches.length === 1) {
      const touch = event.touches[0];
      touchTimeout = setTimeout(() => {
        onRightClick && onRightClick();
      }, 750);
    }
  }

  function handleTouchEnd(event: React.TouchEvent<HTMLButtonElement>) {
    if (touchTimeout) {
      clearTimeout(touchTimeout);
      touchTimeout = null;
    } else {
      onClick && onClick();
    }
  }

  let touchTimeout: ReturnType<typeof setTimeout> | null = null;

  return (
    <StyledButton
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onContextMenu={(e) => {
        e.preventDefault();
        onRightClick && onRightClick();
      }}
      {...styledProps}
    />
  );
}

export default Button;
