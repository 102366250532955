import { FC } from "react";
import { GAPS_REGEXP } from "../utils/format";

export const FormattedGaps: FC<{ text: string; className?: string }> = ({
  text,
  className,
}) => {
  const transformedText = text.replace(
    GAPS_REGEXP,
    "<span style='color: blue'>$1</span>"
  );

  return (
    <p
      className={className}
      dangerouslySetInnerHTML={{ __html: transformedText }}
    />
  );
};
