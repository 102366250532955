import React, { FC, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Lang } from "../util/speech";
import styled, { css } from "styled-components";
import { scaleOnHover } from "./Style";

const StyledVoiceRecorder = styled.span<{ listening: boolean }>`
  all: unset;
  cursor: pointer;
  padding: 10px;
  vertical-align: middle;
  ${scaleOnHover()};
  border-radius: 50%;

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }

  opacity: 0.6;
  ${({ listening }) =>
    listening &&
    css`
      animation: pulse-animation 1s infinite;
      opacity: 1;
    `}
`;

interface VoiceRecorderProps {
  language: Lang;
  onRecorded: (text: string) => void;
  autoListen?: boolean;
}

export const VoiceRecorder: FC<VoiceRecorderProps> = (props) => {
  const { language, onRecorded, autoListen } = props;

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition();

  const listen = () => SpeechRecognition.startListening({ language });
  const stopListening = () => SpeechRecognition.stopListening();

  useEffect(() => {
    if (transcript) {
      onRecorded(transcript);
    }
  }, [transcript]);

  useEffect(() => {
    if (autoListen) {
      listen();
    }
  }, [autoListen]);

  useEffect(() => {
    return () => {
      stopListening();
    };
  }, []);

  const onToggle = () => {
    if (listening) {
      stopListening();
    } else {
      listen();
    }
  };

  if (!browserSupportsSpeechRecognition) {
    console.warn("Browser doesn't support speech recognition.");
    return null;
  }

  if (!isMicrophoneAvailable) {
    console.warn("Microphone is not available.");
    return null;
  }

  return (
    <StyledVoiceRecorder onClick={onToggle} listening={listening}>
      🎤
    </StyledVoiceRecorder>
  );
};
