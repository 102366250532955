import { FC, useEffect, useState } from "react";
import { useExamplesQuery } from "../../../util/queries";
import { RainbowLoader } from "../../RainbowLoader";
import { List } from "../../FlashcardsForm/ExampleGenerator";
import { ModalButton } from "../../ModalComponents";
import { QuizQuestionTopic } from "../../../types";
import { useTopicColor } from "../utils/quiz";
import { extractSquareBrackets } from "../../../util/text";

interface MagicGeneratorProps {
  textForGeneration: string;
  topic: QuizQuestionTopic;
  onSelect: (text: string) => void;
  onClose: () => void;
}

const getExtraReqText = (extracted: string) => {
  if (!extracted) {
    return undefined;
  }
  if (extracted.startsWith("c ")) {
    return extracted.replace("c ", "Use context of");
  }
  return extracted;
};

export const MagicGenerator: FC<MagicGeneratorProps> = (props) => {
  const { textForGeneration, topic, onSelect, onClose } = props;

  const { text, extracted } = extractSquareBrackets(textForGeneration);

  const [generatedExamples, loading] = useExamplesQuery(
    {
      engText: text.trim(),
    },
    getExtraReqText(extracted)
  );

  const [examples, setExamples] = useState<string[]>([]);

  const onSelectExample = (example: string) => {
    onSelect(example);
    const newExamples = examples.filter((e) => e !== example);
    setExamples(newExamples);
    if (!newExamples.length) {
      onClose();
    }
  };

  useEffect(() => {
    if (generatedExamples) {
      setExamples(generatedExamples);
    }
  }, [generatedExamples]);

  useEffect(() => {
    if (!textForGeneration) {
      setExamples([]);
    }
  }, [textForGeneration]);

  const topicColor = useTopicColor(topic);

  if (loading) {
    return <RainbowLoader />;
  }

  if (examples?.length) {
    return (
      <List>
        {examples.map((example) => {
          return (
            <ModalButton
              key={example}
              style={{
                marginBottom: "10px",
              }}
              className="example-option"
              magicColor={topicColor}
              onClick={(e) => {
                if ((e?.target as any)?.classList.contains("read-out-button")) {
                  return;
                }
                onSelectExample(example);
              }}
            >
              <span className="option-text">
                {example}
                {/* <ReadOut text={example} /> */}
              </span>
            </ModalButton>
          );
        })}
      </List>
    );
  }

  return null;
};
