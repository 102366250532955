import { FC } from "react";
import styled, { StyledComponent } from "styled-components";

const StyledRadioLabel = styled.label`
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2rem;
  padding: 5px;
  margin-bottom: 5px;

  input {
    margin-right: 10px;
  }
`;

const StyedBox = styled.div<{ layout: RadioProps["layout"]; gap: number }>`
  gap: ${(props) => props.gap}px;

  ${(props) =>
    props.layout === "vertical" &&
    `
        display: flex;
        flex-direction: column;
    `}

  ${(props) =>
    props.layout === "horizontal" &&
    `
        display: flex;
        flex-direction: row;
    `}

  ${(props) =>
    props.layout === "horizontal-rows" &&
    `
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(70px, 100px));
    `}


  ${(props) =>
    props.layout === "vertical-2-columns" &&
    `
            display: grid;
            grid-template-columns: 1fr 1fr;
    `}
`;

interface RadioProps {
  name: string;
  value: string;
  options: { value: string; label: string }[];
  onChange: (value: string) => void;
  labelComponent?: StyledComponent<any, any>;
  between?: number;
  layout?: "horizontal" | "horizontal-rows" | "vertical" | "vertical-2-columns";
}

export const Radio: FC<RadioProps> = ({
  name,
  value,
  options,
  onChange,
  between = 0,
  layout = "vertical",
  labelComponent: LabelComponent = StyledRadioLabel,
}) => {
  return (
    <StyedBox layout={layout} gap={between}>
      {options.map((option) => (
        <LabelComponent key={option.value}>
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={(e) => onChange(e.target.value)}
          />
          {option.label}
        </LabelComponent>
      ))}
    </StyedBox>
  );
};
