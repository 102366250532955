import { FC } from "react";
import styled from "styled-components";

const StyledLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  padding: 10px 5px;

  span {
    margin-left: 10px;
  }
`;

interface ToggleProps {
  value: boolean;
  toggle: () => void;
  label: string;
}

export const Toggle: FC<ToggleProps> = ({ label, value, toggle }) => {
  return (
    <StyledLabel>
      <input type="checkbox" checked={value} onChange={toggle} />
      <span>{label}</span>
    </StyledLabel>
  );
};
