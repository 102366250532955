import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { quizTopics } from "../../types";
import { Fragment } from "react";
import { getTopicAbbrev, getTopicConfigPath, getTopicPath } from "./utils/quiz";

const StyledConfigNavigation = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  max-width: 400px;
  margin: 5px auto;
`;

const LinkStyled = styled(Link)<{ active: boolean }>`
  all: unset;
  cursor: pointer;
  text-align: center;
  margin: 2px;
  padding: 5px;

  ${({ active }) =>
    active &&
    `
    color: blue;
    `}
`;

export enum QUIZ_PATHS {
  PREPOSITIONS = "/quiz/prepositions",
  PREPOSITIONS_CONFIG = "/quiz/config/prepositions",
  GERUND = "/quiz/gerund",
  GERUND_CONFIG = "/quiz/config/gerund",
  ARTICLES = "/quiz/articles",
  ARTICLES_CONFIG = "/quiz/config/articles",
  VARIA = "/quiz/varia",
  VARIA_CONFIG = "/quiz/config/varia",
  LIVE = "/quiz/live",
  MIX = "/quiz/mix",
}

export const QuizNavigation = () => {
  const { pathname } = useLocation();
  const activeTopic = quizTopics.find((topic) => pathname.includes(topic));
  return (
    <>
      <StyledConfigNavigation>
        {quizTopics.map((topic) => (
          <LinkStyled
            to={getTopicPath(topic)}
            active={pathname === getTopicPath(topic)}
            key={topic}
          >
            {getTopicAbbrev(topic)}
          </LinkStyled>
        ))}
        <LinkStyled to={QUIZ_PATHS.LIVE} active={pathname === QUIZ_PATHS.LIVE}>
          Live
        </LinkStyled>
        <LinkStyled to={QUIZ_PATHS.MIX} active={pathname === QUIZ_PATHS.MIX}>
          Mix
        </LinkStyled>
      </StyledConfigNavigation>
      {activeTopic && (
        <StyledConfigNavigation>
          <LinkStyled
            to={getTopicPath(activeTopic)}
            active={pathname === getTopicPath(activeTopic)}
          >
            ⬇︎
          </LinkStyled>
          <LinkStyled
            to={getTopicConfigPath(activeTopic)}
            active={pathname === getTopicConfigPath(activeTopic)}
          >
            ⚙
          </LinkStyled>
        </StyledConfigNavigation>
      )}
    </>
  );
};
