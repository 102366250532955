import { FlashcardDto } from "../../types";
import { useAllFlashcardsQuery } from "../../util/queries";
import { useEffect } from "react";
import { getFlashcardsChunk } from "../../util/api";

export function useFlashcardsWithBackup(
  setFlashcards: (flashcards: FlashcardDto[]) => void,
  setIsOffline: (isOffline: boolean) => void
) {
  const STORAGE_KEY = "stored-flashcards";
  const [loadedFlashcards, loading, loadingFlashcardsError] =
    useAllFlashcardsQuery();

  useEffect(() => {
    if (loadedFlashcards) {
      setIsOffline(false);

      localStorage.setItem(STORAGE_KEY, JSON.stringify(loadedFlashcards));

      setFlashcards(loadedFlashcards);
    }
  }, [loadedFlashcards]);

  useEffect(() => {
    if (loadingFlashcardsError) {
      setIsOffline(true);

      const stored = JSON.parse(localStorage.getItem(STORAGE_KEY) || "[]");

      setFlashcards(stored);
    }
  }, [loadingFlashcardsError]);
}

export function useFlashcardsLoader(
  async: boolean,
  setFlashcards: (flashcards: FlashcardDto[]) => void,
  setLoading: (loading: boolean) => void
) {
  const STORAGE_KEY = "stored-flashcards";

  useEffect(() => {
    const loadFlashcards = async () => {
      // Load flashcards from local storage
      const localFlashcards = JSON.parse(
        localStorage.getItem(STORAGE_KEY) || "[]"
      ) as FlashcardDto[];

      setFlashcards(
        localFlashcards.map((fl) => ({
          ...fl,
          cache: true,
        }))
      );

      let allFlashcards: FlashcardDto[] = [];
      let page = 1;
      const limit = async ? 1000 : 99999;
      let fetchedAll = false;
      setLoading(true);

      while (!fetchedAll) {
        const chunk = await getFlashcardsChunk(page, limit);
        if (chunk.length < limit) {
          fetchedAll = true;
          setLoading(false);
        }
        allFlashcards = [...allFlashcards, ...chunk];
        setFlashcards([...allFlashcards]); // Update state with each chunk
        localStorage.setItem(STORAGE_KEY, JSON.stringify(allFlashcards)); // Replace local storage with up-to-date versions
        page++;
      }

      // Save all loaded flashcards to local storage
      localStorage.setItem(STORAGE_KEY, JSON.stringify(allFlashcards));
    };

    loadFlashcards();
  }, [setFlashcards]);
}
