import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Side } from "../../types";

export const StyledFlashcard = styled.div<{
  side: Side;
  isPlaying: boolean;
  isHighlighted: boolean;
  menuStatus: "none" | "active" | "inactive";
  toVerify: boolean;
  blurry?: boolean;
}>`
  position: relative;
  border: 2px solid gray;
  background: ${({ side }) => {
    switch (side) {
      case "eng":
      case "description":
        return "#E0E0E0";
      case "pol":
      case "dual":
        return "white";
      case "grammar":
        return "#f8efc5";
    }
  }};

  ${(props) =>
    props.blurry &&
    css`
      opacity: 0.3;
    `}

  ${(props) => {
    switch (props.menuStatus) {
      case "none":
        return "";
      case "active":
        return "";
      case "inactive":
        return css`
          opacity: 0.3;
        `;
    }
  }}

    min-height: 12em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;

  padding: 10px;
  padding-top: 0;

  p {
    margin-top: 0;
    text-align: center;
  }

  transition: transform 0.05s, background 0.05s, border 0.1s;
  ${(props) =>
    props.isHighlighted &&
    css`
      border: 2px solid #b3ff00;
    `}
  ${(props) =>
    props.isPlaying &&
    css`
      border: 2px solid blue;
    `}

    ${(props) =>
    props.toVerify &&
    css`
      border: 2px solid #dcff83;
    `}


    ${(props) =>
    props.side === "grammar" &&
    css`
      grid-row: span 2;
      min-height: 18em;
    `}
`;

export const Mark = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
  opacity: 0.6;
  font-size: 0.7em;
  color: red;
`;

export const Toolbar = styled.div`
  position: absolute;
  display: flex;
  justify-items: center;
  align-items: center;
`;

export const TopToolbar = styled(Toolbar)`
  left: 20px;
  justify-content: flex-end;
  right: 20px;
  top: 20px;
`;

export const BottomToolbar = styled(Toolbar)`
  right: 20px;
  left: 20px;
  bottom: 20px;
  justify-content: space-between;
`;

export const ToolbarElem = styled.div<{ text?: boolean; top?: number }>`
  width: 20px;
  height: 20px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.text &&
    css`
      font-size: 10px;
    `}
  ${(props) =>
    props.top &&
    css`
      transform: translateY(${props.top}px);
    `}
`;

export const EditButton = styled(Link)`
  color: black;
  text-decoration: none;
  margin-left: 20px;
`;

export const Description = styled.p<{ size: "big" | "small" }>`
  white-space: pre-wrap;
  line-height: 1.5em;

  ${(props) =>
    props.size === "big"
      ? css`
          font-size: 0.9em;
        `
      : css`
          font-size: 0.75em;
        `}
`;

export const DescriptionCounter = styled.span`
  margin-left: 10px;
  color: blue;
  font-size: 0.8em;
  display: inline-block;
  cursor: pointer;
`;

export const MoreMenu = styled(ToolbarElem)`
  cursor: pointer;
  position: absolute;
  left: 0;
`;

export const CollectionButton = styled(ToolbarElem)<{ absolute?: boolean }>`
  cursor: pointer;

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      left: 40px;
    `}
  span {
  }
`;

export const GrammarBox = styled.div<{ scrollable: boolean }>`
  font-size: 0.75em;
  white-space: pre-wrap;
  width: 100%;
  height: 70%;
  box-sizing: border-box;
  line-height: 1.2em;
  padding: 10px;

  overflow-y: hidden;

  ${(props) =>
    props.scrollable &&
    css`
      overflow-y: auto;
    `}
  b {
    display: block;
    margin-bottom: 4px;
  }

  .title2 {
    color: blue;
  }

  div {
    margin-top: 20px;
  }
`;

export const InsightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;
