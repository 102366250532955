import React, { FC } from "react";
import { TopicConfig } from "../components/Speak/TopicConfig";
import { ControlsNavigation } from "../components/ControlsNavigation";
import { ConfigNavigation } from "../components/ConfigNavigation";
import { Container } from "../components/Style";

export const TopicConfigPage: FC = () => {
  localStorage.setItem("last-seen-config", "topic");

  return (
    <Container>
      <ConfigNavigation />
      <TopicConfig />
      <ControlsNavigation active="config" />
    </Container>
  );
};
